/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
// import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import _ from "lodash";
import { useCounterpart } from "../../context/language.context";
import { Chat } from "./style";
import ChatMessageItem from "../ChatMessageItem";
import { addValueMediationChatMessage } from "../../actions/mediationChatMessage.actions";
import { sendConnection } from "../../helpers/utils";
import ChatMessageNotification from "../ChatMessageNotification";
import { getCookie } from "../../helpers/cookie";
import moment from "../../helpers/moment";

const ListChatMessages = () => {
  const { mediationChatGroupId } = useParams();
  const { counterpart, locale } = useCounterpart();
  const dispatch = useDispatch();
  const allMessages = useSelector((state) => state.mediationChatMessage);
  const connection = useSelector((state) => state.connection);
  const token = getCookie("token");
  const refMessage = useRef(null);
  const [messages, setMessages] = useState();

  const handleScrollToBottom = () => {
    refMessage?.current?.scrollIntoView({ block: "end" });
  };

  const handleConnection = async () => {
    sendConnection("JoinChatGroup", mediationChatGroupId, connection);
    await connection.on("ReceiveMessage", (message) => {
      if (message.token !== token) {
        dispatch(
          addValueMediationChatMessage({
            ...message,
            creationDate: new Date(),
          })
        );
      }
    });
  };

  useEffect(async () => {
    try {
      if (connection && connection._connectionStarted) {
        await handleConnection();
      }
    } catch (error) {
      console.log(error);
    }
  }, [connection._connectionStarted]);

  useEffect(() => {
    if (allMessages?.length > 0) {
      setMessages(
        _.groupBy(allMessages, (message) =>
          moment(message.creationDate).locale(locale).format("DD MMMM YYYY")
        )
      );
    }
    handleScrollToBottom();
  }, [allMessages]);

  // useEffect(() => {

  // }, []);

  const renderNoChat = () => (
    <Typography variant="subtitle1" color="gray">
      {counterpart("mediation.comments.no-comments")}
    </Typography>
  );

  const renderChat = (messageList) => (
    <Chat ref={refMessage}>
      {messageList &&
        Object.keys(messageList).map((item) => (
          <div key={item}>
            <Typography textAlign="center" mt={2} mb={2} fontSize="0.9rem">
              {item}
            </Typography>
            {messageList[item]?.map((message, index) =>
              message?.type?.includes("notification") ? (
                <ChatMessageNotification key={index} message={message} />
              ) : (
                <ChatMessageItem key={message.id} chatMessage={message} />
              )
            )}
          </div>
        ))}
    </Chat>
  );

  return (
    <div>{allMessages?.length > 0 ? renderChat(messages) : renderNoChat()}</div>
  );
};

export default ListChatMessages;
