const formats = [
  {
    formats: ["zip", "rar", "7zip", "application/x-zip-compressed"],
    icon: "-archive",
  },
  {
    formats: ["txt", "text/plain"],
    icon: "-alt",
  },
  {
    formats: [
      "doc",
      "docx",
      "application/vnd.ms-word.document",
      "application/ms-word",
    ],
    icon: "-word",
  },
  {
    formats: ["xls, xlsx", "application/vnd.ms-excel"],
    icon: "-excel",
  },
  {
    formats: [
      "pdf",
      "pptx",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
    icon: "-pdf",
  },
  {
    formats: ["image/bmp, image/jpg", "image/jpeg", "image/png"],
    icon: "-image",
  },
  {
    formats: ["mp3"],
    icon: "-audio",
  },
];

export const getFileIcon = (file) => {
  const result = formats.find((f) => f.formats.includes(file.format));
  return result?.icon ?? "";
};
