/* eslint-disable default-param-last */
import { MEDIATION } from "../constants/actions";

const initialState = {
  mediations: {
    hits: null,
    error: false,
  },
  mediation: null,
};

export function mediation(state = initialState, action) {
  switch (action.type) {
    case MEDIATION.GET_ALL: {
      return {
        ...state,
        mediations: {
          ...action.payload,
          hits: action.payload.hits,
          error: false,
        },
      };
    }
    case MEDIATION.GET:
    case MEDIATION.CLOSE:
    case MEDIATION.UPDATE: {
      return {
        ...state,
        mediation: {
          ...state.mediation,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
}
