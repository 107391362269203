import styled from "styled-components";

export const MediationDescription = styled.div`
  margin-bottom: 20px;
  white-space: pre-line;

  table {
    border-collapse: collapse;
  }

  table,
  td {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
`;
