import { SNACKBAR } from "../constants/actions";

export function action(
  callback,
  actionValue,
  messageSnackbar = "",
  loading = null
) {
  return async (dispatch) => {
    if (loading) {
      await dispatch({
        type: loading,
        payload: { isLoading: true },
      });
    }

    const response = callback ? await callback() : null;
    if (loading) {
      await dispatch({
        type: loading,
        payload: { isLoading: false },
      });
    }

    if (response && response.statusText && response.statusText === "OK") {
      await dispatch({
        type: actionValue,
        payload: response.responseData,
      });

      if (messageSnackbar && messageSnackbar.length > 0) {
        await dispatch({
          type: SNACKBAR.UPDATE,
          payload: {
            snackbar: { isOpen: true, message: messageSnackbar },
          },
        });
      }
      return response.responseData;
    }

    if (!response && actionValue) {
      await dispatch({
        type: actionValue,
        payload: null,
      });
    }

    return null;
  };
}

export const simpleAction = (values, actionValue) => async (dispatch) => {
  await dispatch({
    type: actionValue,
    payload: values,
  });
};
