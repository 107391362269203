/* eslint-disable no-underscore-dangle */
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useParams, useHistory } from "react-router-dom";
import { HubConnectionBuilder } from "@microsoft/signalr";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  addValueChatGroup,
  getMediationChatGroups,
  updateValueChatGroup,
} from "../../actions/mediationChat.actions";
import { getMediationInvitations } from "../../actions/mediationInvitations.actions";
import { getMediation } from "../../actions/mediation.actions";
import { getMediationMeetings } from "../../actions/mediationMeetings.actions";
import { getMediationPayments } from "../../actions/mediationPayments.actions";
import { getMediationReports } from "../../actions/mediationReports.actions";
import { getMediationTeams } from "../../actions/mediationTeams.actions";
import { getMediationTimesheets } from "../../actions/mediationTimesheets.actions";
import { getMediationUsers } from "../../actions/mediationUsers.actions";
import CustomMenu from "../../components/CustomMenu";
import LoadingMediation from "../../components/LoadingMediation";
import MediationMenu from "../../components/MediationMenu";
import MediationTool from "../../components/MediationTool";
import { ENDPOINTS } from "../../constants/endpoints";
import { ROUTES, SUBROUTES } from "../../constants/routes";
import { useCounterpart } from "../../context/language.context";
import { CG } from "./cg";
import { ChatGroup } from "./chatGroup";
import Dashboard from "./dashboard";
import { Documents } from "./documents";
import { Meetings } from "./meetings";
import Members from "./members";
import { Payments } from "./payments";
import { Reports } from "./reports";
import {
  MediationContainer,
  MediationContent,
  MediationMain,
  MenuButton,
} from "./style";
import { Timesheet } from "./timesheet";
import { addConnection } from "../../actions/connection.action";
import {
  sendConnection,
  handleReplaceChatGroupsTitle,
  isMediationAdmin,
} from "../../helpers/utils";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import useMediationUserTerms from "../../hooks/useMediationUserTerms";
import ContentMenuTabs from "../../components/ContentMenuTabs";
import Transition from "../../components/Transition";

const MediationPage = () => {
  const history = useHistory();
  const { counterpart } = useCounterpart();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { mediation } = useSelector((state) => state.mediation);
  const { reports } = useSelector((state) => state.mediationReports);
  const { meetings } = useSelector((state) => state.mediationMeetings);
  const { payments } = useSelector((state) => state.mediationPayments) || [];
  const { chatGroups } = useSelector((state) => state.mediationChat) || [];
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const { muCountTerms, muCountTotal } = useMediationUserTerms();
  const [isLoading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState();
  const [connection, setConnection] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { mediationId } = useParams();

  if (!mediationId) {
    history.push(ROUTES.MEDIATIONS);
  }

  useEffect(async () => {
    // dispatch(getMediationHistory({ mediationId })),
    if (mediationId) {
      const mediationGets = [
        dispatch(getMediation({ mediationId })),
        dispatch(getMediationTeams({ mediationId })),
        dispatch(getMediationUsers({ mediationId })),
        dispatch(getMediationInvitations({ mediationId })),
        dispatch(getMediationMeetings({ mediationId })),
        dispatch(getMediationPayments({ mediationId })),
        dispatch(getMediationChatGroups({ mediationId })),
        dispatch(getMediationTimesheets({ mediationId })),
        dispatch(getMediationReports({ mediationId })),
      ];
      await Promise.all(mediationGets)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            history.push(ROUTES.NO_ACCESS_PAGE);
            // const newWindow = window.open(process.env.REACT_APP_AUTH, "_self");
            // if (newWindow) newWindow.opener = null;
          }
        });
    } else {
      history.push(ROUTES.MEDIATIONS);
      setLoading(false);
    }
  }, []);

  useEffect(async () => {
    try {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}${ENDPOINTS.HUBS_MEDIATION}`)
        .withAutomaticReconnect()
        .build();

      newConnection.on("ReceiveChatGroup", (value) => {
        dispatch(addValueChatGroup(value));
      });

      newConnection.on("ReceiveUpdateChatGroup", (value) => {
        dispatch(updateValueChatGroup(value));
      });

      await newConnection.start();
      await sendConnection("JoinChatGroup", mediationId, newConnection);
      await dispatch(addConnection(newConnection));
      setConnection(newConnection);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setMenuItems([
      {
        id: 0,
        label: (mediation && mediation.title) || counterpart("mediation.title"),
        url: `/${mediationId}`,
      },
      {
        id: 1,
        label: counterpart("mediation.cg.title"),
        description: `${muCountTerms}/${muCountTotal}`,
        url: `/${mediationId}${SUBROUTES.TERMOFUSE}`,
      },
      {
        id: 7,
        label: counterpart("mediation.members.title"),
        url: `/${mediationId}${SUBROUTES.MEMBERS}`,
      },
      {
        id: 8,
        label: counterpart("mediation.documents.title"),
        url: `/${mediationId}${SUBROUTES.DOCUMENTS}`,
      },
      {
        id: 2,
        label: counterpart("mediation.timesheets.title"),
        url: `/${mediationId}${SUBROUTES.TIMESHEET}`,
      },
      {
        id: 3,
        label: counterpart("mediation.reports.title"),
        url: `/${mediationId}${SUBROUTES.REPORT}`,
        hasChildren: true,
        childrens: reports,
        addIcon: true,
      },
      {
        id: 4,
        label: counterpart("mediation.meetings.title"),
        url: `/${mediationId}${SUBROUTES.MEETINGS}`,
        hasChildren: true,
        childrens: meetings,
        addIcon: true,
      },
      {
        id: 5,
        label: counterpart("mediation.payments.title"),
        url: `/${mediationId}${SUBROUTES.PAYMENTS}`,
        hasChildren: true,
        childrens: payments,
        addIcon: true,
      },
      {
        id: 6,
        label: counterpart("mediation.comments.title"),
        url: `/${mediationId}${SUBROUTES.CHAT}`,
        hasChildren: true,
        childrens: handleReplaceChatGroupsTitle(chatGroups, mediationUsers),
        addIcon: true,
        isForAllUsers: true,
      },
    ]);
  }, [chatGroups, reports, meetings, payments, muCountTerms, muCountTotal]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return isLoading ? (
    <LoadingMediation mediation={history?.location?.state?.mediation} />
  ) : (
    <Box>
      <CustomMenu
        items={menuItems}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
      />
      <MediationContainer>
        {isMd ? (
          <MediationMenu
            items={menuItems}
            isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
            disabled={Boolean(mediation.endDate)}
          />
        ) : (
          <MenuButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </MenuButton>
        )}
        <MediationMain>
          <MediationContent>
            <Switch>
              <Route exact path={ROUTES.MEDIATION_ROOT} component={Dashboard} />
              <Route
                path={ROUTES.MEDIATION_REPORT_DETAIL}
                component={Reports}
              />
              <Route exact path={ROUTES.MEDIATION_REPORT} component={Reports} />
              <Route path={ROUTES.MEDIATION_TERMSOFUSE} component={CG} />
              <Route path={ROUTES.MEDIATION_MEMBERS} component={Members} />
              <Route path={ROUTES.MEDIATION_DOCUMENTS} component={Documents} />
              <Route path={ROUTES.MEDIATION_TIMESHEET} component={Timesheet} />
              <Route
                path={ROUTES.MEDIATION_MEETINGS_DETAIL}
                component={Meetings}
              />
              <Route
                exact
                path={ROUTES.MEDIATION_MEETINGS}
                component={Meetings}
              />
              <Route
                path={ROUTES.MEDIATION_PAYMENTS_DETAIL}
                component={Payments}
              />
              <Route
                exact
                path={ROUTES.MEDIATION_PAYMENTS}
                component={Payments}
              />
              <Route
                path={ROUTES.MEDIATION_CHAT_DETAIL}
                component={ChatGroup}
              />
              <Route exact path={ROUTES.MEDIATION_CHAT} component={ChatGroup} />
              <Route component={Dashboard} />
            </Switch>
          </MediationContent>
        </MediationMain>
        {connection && isMd && <MediationTool />}
        {isDrawerOpen && !isMd && (
          <Dialog
            fullScreen
            open={isDrawerOpen}
            onClose={toggleDrawer}
            TransitionComponent={Transition}
          >
            <Box display="flex" justifyContent="end">
              <IconButton
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0.5,
                }}
                onClick={toggleDrawer}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <ContentMenuTabs
              menuItems={menuItems}
              toggleDrawer={toggleDrawer}
              isSmallScreen
            />
          </Dialog>
        )}
      </MediationContainer>
    </Box>
  );
};

export default MediationPage;
