import { SUBENDPOINTS, ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationDatesService {
  async getMediationDates({ mediationMeetingId }) {
    const parameters = new URLSearchParams({
      mediationMeetingId,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION_DATE}?${parameters}`)
      .then(handleResponse);
  }

  async setMediationDateAnswer({
    mediationId,
    answer,
    id,
    mediationMeetingId,
  }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_DATE}/${id}${SUBENDPOINTS.ANSWER}`, {
        mediationId,
        answer,
        mediationMeetingId,
      })
      .then(handleResponse);
  }

  async addMediationDate({ mediationId, mediationMeetingId, date }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_DATE}`, {
        mediationId,
        date,
        mediationMeetingId,
      })
      .then(handleResponse);
  }
}

export const mediationDatesService = new MediationDatesService();
