/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { getCookie, setCookie } from "../helpers/cookie";
import counterpartInstance from "../helpers/counterpart";
import { useSession } from "./session.context";

const LanguageContext = createContext({
  locale: PropTypes.string.isRequired,
  counterpart: PropTypes.func.isRequired,
  handleSetLocale: PropTypes.func.isRequired,
});

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState();
  const { user } = useSession();

  const handleSetLocale = useCallback((value) => {
    counterpartInstance.setLocale(value);
    setLocale(value);
    setCookie("lang", value, 365);
  }, []);

  const counterpart = useCallback(
    (value, object) => counterpartInstance(value, object),
    []
  );

  useEffect(() => {
    const languages = ["fr", "en"];
    const currentLanguage = navigator.language.split("-")[0];
    const cookieLanguage = getCookie("lang");

    if (user && user.language) {
      handleSetLocale(user.language);
    } else if (cookieLanguage) {
      handleSetLocale(cookieLanguage);
    } else if (languages.includes(currentLanguage)) {
      handleSetLocale(currentLanguage);
    } else {
      handleSetLocale("fr");
    }
  }, [handleSetLocale, user]);

  return (
    <LanguageContext.Provider value={{ locale, counterpart, handleSetLocale }}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCounterpart = () => useContext(LanguageContext);
