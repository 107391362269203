export const SUBENDPOINTS = {
  DATES: "/dates",
  ANSWER: "/answer",
};

export const ENDPOINTS = {
  USER: "/User",
  MEDIATION: "/Mediation",
  MEDIATOR: "/Mediator",
  MEDIATION_DOCUMENT: "/MediationDocument",
  MEDIATION_TIMESHEET: "/MediationTimesheet",
  MEDIATION_INVITATION: "/MediationInvitation",
  MEDIATION_DATE: "/MediationDate",
  MEDIATION_USER: "/MediationUser",
  MEDIATION_CHAT: "/MediationChat",
  MEDIATION_MEETING: "/MediationMeeting",
  MEDIATION_PAYMENT: "/MediationPayment",
  MEDIATION_REPORT: "/MediationReport",
  MEDIATION_TEAM: "/MediationTeam",
  MEDIATION_HISTORY: "/MediationHistory",
  HUBS_MEDIATION: "/hubs/mediation",
};
