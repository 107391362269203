import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationService {
  async getMediations({ name, filter, page = 0, pageSize = 50 }) {
    const parameters = new URLSearchParams({
      name,
      ...filter,
      page,
      pageSize,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION}?${parameters}`)
      .then(handleResponse);
  }

  async getMediation({ mediationId, token }) {
    return axios
      .get(
        `${ENDPOINTS.MEDIATION}/${mediationId}${token ? `?token=${token}` : ""}`
      )
      .then(handleResponse);
  }

  async createMediation(parameters) {
    return axios.post(ENDPOINTS.MEDIATION, parameters).then(handleResponse);
  }

  async updateMediation({ mediationId, mediation }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION}/${mediationId}`, mediation)
      .then(handleResponse);
  }

  async closeMediation({ mediationId, close }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION}/${mediationId}/close`, { close })
      .then(handleResponse);
  }
}

export const mediationService = new MediationService();
