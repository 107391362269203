import Badge from "@mui/material/Badge";
import styled from "styled-components";
import ListItemText from "@mui/material/ListItemText";

export const CustomBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    position: "relative",
    transform: "none",
    backgroundColor: "#377dff",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    color: "white",
  },
});

export const ContentName = styled(ListItemText)`
  margin-right: 10px;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
