import { MEDIATION_PAYMENTS } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationPaymentsService } from "../services/mediationPayments.service";

export const getMediationPayments = (parameters) =>
  action(
    () => mediationPaymentsService.getMediationPayments(parameters),
    MEDIATION_PAYMENTS.GET_ALL
  );

export const getMediationPayment = (parameters) =>
  action(
    () => mediationPaymentsService.getMediationPayment(parameters),
    MEDIATION_PAYMENTS.GET
  );

export const addMediationPayment = (parameters, history) =>
  action(
    () => mediationPaymentsService.addMediationPayment(parameters, history),
    MEDIATION_PAYMENTS.ADD,
    counterpart("message.add-success")
  );

export const updateMediationPayment = (parameters) =>
  action(
    () => mediationPaymentsService.updateMediationPayment(parameters),
    MEDIATION_PAYMENTS.UPDATE,
    counterpart("message.edit-success")
  );

export const deleteMediationPayment = (parameters) =>
  action(
    () => mediationPaymentsService.deleteMediationPayment(parameters),
    MEDIATION_PAYMENTS.DELETE,
    counterpart("message.delete-success")
  );

export const initMediationPayment = () =>
  action(null, MEDIATION_PAYMENTS.INIT, null);
