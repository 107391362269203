import React from "react";
import PropTypes from "prop-types";
import { MediationDescription } from "../../pages/MediationPage/style";
import MediationEdit from "../MediationEdit";

const DashboardEdit = ({
  formik,
  counterpart,
  mediation,
  handleInitValue,
  showEdit,
}) => {
  const params = [
    {
      name: "description",
      type: "ckeditor",
    },
  ];
  return (
    (showEdit && (
      <MediationEdit
        params={params}
        formik={formik}
        item={mediation}
        handleInitValue={handleInitValue}
        counterpart={counterpart}
      />
    )) ||
    (mediation.description ? (
      <MediationDescription
        dangerouslySetInnerHTML={{ __html: mediation.description }}
      />
    ) : (
      <MediationDescription>
        {counterpart("common.no-description")}
      </MediationDescription>
    ))
  );
};

DashboardEdit.propTypes = {
  formik: PropTypes.shape(),
  counterpart: PropTypes.func,
  mediation: PropTypes.shape(),
  handleInitValue: PropTypes.func,
  showEdit: PropTypes.bool,
};

DashboardEdit.defaultProps = {
  formik: null,
  counterpart: null,
  mediation: null,
  handleInitValue: null,
  showEdit: false,
};
export default DashboardEdit;
