import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useMaxAvatar from "../../hooks/useMaxAvatar";
import { MediationDescription } from "../../pages/MediationPage/style";
import CreateComponent from "../CreateComponent";
import CustomAvatarGroup from "../CustomAvatarGroup";
import moment from "../../helpers/moment";
import { useCounterpart } from "../../context/language.context";

const MeetingDetail = ({
  meeting,
  mediationDatesLength,
  mediationUsers,
  showEdit,
}) => {
  const { counterpart, locale } = useCounterpart();
  const [users, setUsers] = useState(null);
  const ref = useRef(null);
  const maxAvatar = useMaxAvatar(ref);
  const mediationUser = mediationUsers.find(
    (item) => item.id === meeting.mediationUserId
  );
  const selectedMediationDateFormatted =
    meeting.selectedMediationDate &&
    moment(new Date(meeting.selectedMediationDate))
      .locale(locale)
      .format("DD MMM YYYY");

  const getAllInfoMeetingUsers = useCallback(
    () =>
      meeting.mediationMeetingUserIds
        .map((id) => mediationUsers.find((item) => item.id === id))
        .filter((mu) => !mu?.deletionDate),
    [meeting, mediationUsers]
  );

  useEffect(() => {
    setUsers(getAllInfoMeetingUsers());
  }, [getAllInfoMeetingUsers]);

  const renderAddress = () =>
    meeting.address?.includes("http") ||
    meeting.address?.includes("www.") ||
    meeting.address?.includes(".com/") ? (
      <a href={meeting.address} target="_blank" rel="noreferrer">
        {meeting.address}
      </a>
    ) : (
      meeting.address
    );

  return (
    <>
      <CreateComponent
        user={mediationUser && mediationUser.user ? mediationUser.user : null}
        item={meeting}
      />
      <Box mb={2} mt={2} ref={ref}>
        {maxAvatar && users && (
          <CustomAvatarGroup avatarList={users} max={maxAvatar} />
        )}
      </Box>
      {!showEdit && (
        <>
          <MediationDescription
            dangerouslySetInnerHTML={{ __html: meeting.description }}
          />
          <Typography variant="body2" fontWeight={800} mt={2}>
            {counterpart("mediation.meetings.address")}
            {": "}
            {renderAddress()}
          </Typography>
          <Typography variant="body2" fontWeight={800} mt={2}>
            {counterpart("mediation.meetings.date")}
            {": "}
            {selectedMediationDateFormatted ??
              counterpart(
                mediationDatesLength
                  ? "mediation.meetings.choose-date"
                  : "mediation.meetings.no-date"
              )}
          </Typography>
        </>
      )}
    </>
  );
};

MeetingDetail.propTypes = {
  meeting: PropTypes.shape().isRequired,
  mediationUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mediationDatesLength: PropTypes.number,
  showEdit: PropTypes.bool,
};

MeetingDetail.defaultProps = {
  mediationDatesLength: null,
  showEdit: false,
};

export default MeetingDetail;
