import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import CustomAvatar from "../CustomAvatar";
import { getUserName } from "../../helpers/utils";
import { ContentName, CustomBadge } from "./style";

const useStyles = makeStyles(() => ({
  avatar: {
    width: "35px",
    height: "35px",
    fontSize: "0.9rem",
  },
}));

const TotalMediatorTimeSheet = ({ idMediationUser, hours, mediationUsers }) => {
  const classes = useStyles();
  const userInfo = mediationUsers.find((item) => item.id === idMediationUser);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton sx={{ cursor: "default", overflow: "hidden" }}>
            <ListItemIcon>
              <CustomAvatar className={classes.avatar} user={userInfo?.user} />
            </ListItemIcon>
            <ContentName>
              <Typography variant="subtitle2">
                {getUserName(userInfo.user)}
              </Typography>
            </ContentName>
            <ListItemText sx={{ display: "flex", justifyContent: "end" }}>
              <CustomBadge badgeContent={`${hours}h`} />
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

TotalMediatorTimeSheet.propTypes = {
  idMediationUser: PropTypes.string.isRequired,
  hours: PropTypes.number.isRequired,
  mediationUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default TotalMediatorTimeSheet;
