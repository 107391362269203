import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  inviteUser,
  removeInvitationUser,
} from "../../actions/mediationInvitations.actions";
import { useCounterpart } from "../../context/language.context";
import moment from "../../helpers/moment";
import CustomAvatar from "../CustomAvatar";
import CustomMenu from "../CustomMenu";
import ListDialog from "../ListDialog";
import { CustomCard } from "./style";

const UserInvitationItem = (props) => {
  const { counterpart, locale } = useCounterpart();
  const {
    mediationInvitation,
    mediationId,
    disabled,
    isCurrentUserMediationAdmin,
  } = props;
  const { id, email, invitationState, invitationStateDate, refusalComment } =
    mediationInvitation;
  const dispatch = useDispatch();

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRefusalCommentModalOpen, setIsRefusalCommentModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showIcon, setShowIcon] = useState(false);

  const toggleRemoveModal = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
    setAnchorEl(null);
  };

  const toggleRefusalCommentModal = () => {
    setIsRefusalCommentModalOpen(!isRefusalCommentModalOpen);
    setAnchorEl(null);
  };

  const getState = () => {
    switch (invitationState) {
      case "INVITED":
        return counterpart("mediation.invitations.invited");
      case "REFUSED":
        return counterpart("mediation.invitations.refused");
      default:
        return counterpart("mediation.invitations.invited");
    }
  };

  const remove = async () => {
    await dispatch(
      removeInvitationUser({
        mediationId,
        id,
      })
    );
    setIsRemoveModalOpen(false);
  };

  const reinvite = async () => {
    setAnchorEl(null);
    await dispatch(
      inviteUser({
        mediationId,
        email,
      })
    );
  };

  const renderRemoveModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {counterpart("mediation.invitations.confirm-delete-invitation", {
          email,
        })}
      </Typography>
    </Grid>
  );

  const renderRefusalCommentModal = () => (
    <Grid container>
      <Typography variant="subtitle1">{refusalComment}</Typography>
    </Grid>
  );

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomCard
        sx={{
          boxShadow: "none",
        }}
        onMouseOver={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <CardHeader
          avatar={<CustomAvatar />}
          action={
            isCurrentUserMediationAdmin &&
            (showIcon || Boolean(anchorEl)) && (
              <IconButton aria-label="settings" onClick={handleShowMenu}>
                <MoreVertIcon />
              </IconButton>
            )
          }
          title={
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              {email}
            </Typography>
          }
          subheader={
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.75rem",
                lineHeight: "1.25",
                color: "#677788",
              }}
            >
              {getState()}{" "}
              {moment(new Date(invitationStateDate))
                .locale(locale)
                .format("DD MMM YYYY hh:mm")}
            </Typography>
          }
          sx={{
            width: "100%",
            padding: "6px",
            "&:hover": {
              background: "rgb(240,240,240,0.5)",
            },
            "& .MuiCardHeader-action": {
              margin: "0px",
            },
          }}
        />
      </CustomCard>
      <CustomMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        width="auto"
      >
        {mediationInvitation.invitationState === "REFUSED" && (
          <MenuItem onClick={toggleRefusalCommentModal} disabled={disabled}>
            {counterpart("mediation.invitations.show-refusal-comment")}
          </MenuItem>
        )}
        <MenuItem onClick={reinvite} disabled={disabled}>
          {counterpart("mediation.invitations.reinvit-invitation")}
        </MenuItem>
        <MenuItem onClick={toggleRemoveModal} disabled={disabled}>
          {counterpart("mediation.invitations.delete-invitation")}
        </MenuItem>
      </CustomMenu>
      <ListDialog
        dialogs={[
          {
            title: counterpart("mediation.invitations.delete-invitation"),
            isOpen: isRemoveModalOpen,
            handleClose: toggleRemoveModal,
            acceptLabel: counterpart("common.remove"),
            handleAccept: remove,
            width: "md",
            children: renderRemoveModal(),
          },
          {
            title: counterpart("mediation.invitations.refusal-comment"),
            isOpen: isRefusalCommentModalOpen,
            handleClose: toggleRefusalCommentModal,
            width: "md",
            children: renderRefusalCommentModal(),
          },
        ]}
      />
    </>
  );
};

UserInvitationItem.propTypes = {
  mediationInvitation: PropTypes.shape().isRequired,
  mediationId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isCurrentUserMediationAdmin: PropTypes.bool,
};

UserInvitationItem.defaultProps = {
  disabled: false,
  isCurrentUserMediationAdmin: false,
};

export default UserInvitationItem;
