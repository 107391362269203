import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationInvitationsService {
  async inviteUser({ mediationId, email }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_INVITATION}`, { email, mediationId })
      .then(handleResponse);
  }

  async answerInvitation({ mediationId, token, answer, refusalComment }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_INVITATION}/${mediationId}`, {
        token,
        answer,
        refusalComment,
      })
      .then(handleResponse);
  }

  async removeInvitationUser({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_INVITATION}/${id}`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }

  async getMediationInvitations({ mediationId }) {
    const parameters = new URLSearchParams({
      mediationId,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION_INVITATION}?${parameters}`)
      .then(handleResponse);
  }
}

export const mediationInvitationsService = new MediationInvitationsService();
