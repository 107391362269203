/* eslint-disable no-useless-escape */
import cookieCutter from "cookie-cutter";

export const getCookie = (name) => (cookieCutter.get(name) || null);

export const setCookie = (name, value, days) => {
  const domain = document?.domain?.match(/[^\.]*\.[^.]*$/)?.[0] || "localhost";
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  cookieCutter.set(name, value, { expires: d, domain, path: "/" });
}

export const deleteCookie = (name) => {
  setCookie(name, "", -1);
}