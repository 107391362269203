import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { updateUserMediation } from "../../actions/mediationUsers.actions";
import UserMediationItem from "../UserMediationItem";
import { CustomTitle } from "./style";

const UserTeamCard = (props) => {
  const {
    team,
    mediationUsers,
    mediationTeams,
    handleClickMenu,
    setTeamSelectioned,
    showMenu,
    dispatch,
    userSelectioned,
    setUserSelectioned,
    user,
    disabled,
    isMenuOpen,
    isCurrentUserMediationAdmin,
  } = props;

  const [visible, setVisible] = useState(true);
  const [isOnDrag, setOnDrag] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const hasChildren = mediationUsers && mediationUsers.length > 0;

  const handleShowMenu = (event) => {
    setAnchorEl(event);
    handleClickMenu(event);
    setTeamSelectioned(team);
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleDrag = (event, value) => {
    if (isCurrentUserMediationAdmin) {
      event.preventDefault();
      setUserSelectioned(value);
    }
  };

  const handleDrop = async () => {
    if (isCurrentUserMediationAdmin) {
      setOnDrag(false);
      if (
        userSelectioned.mediationTeamId !== team.id &&
        !(!userSelectioned.mediationTeamId && !team.id)
      ) {
        await dispatch(
          updateUserMediation({
            id: userSelectioned.id,
            description: userSelectioned.description,
            mediationTeamId: team.id,
          })
        );
      }
    }
  };

  const handleDragOver = (event) => {
    if (isCurrentUserMediationAdmin) {
      event.preventDefault();
      setOnDrag(true);
    }
  };

  const handleDragLeave = (event) => {
    if (isCurrentUserMediationAdmin) {
      event.preventDefault();
      setOnDrag(false);
    }
  };

  useEffect(() => {
    if (!isMenuOpen) {
      setAnchorEl(null);
    }
  }, [isMenuOpen]);

  return (
    <>
      <Card
        sx={{
          background: isOnDrag
            ? "rgb(128 128 128 / 15%)"
            : "rgb(240,240,240,0.5)",
          paddingLeft: hasChildren ? "0px" : "20px",
          margin: "14px auto 8px auto",
          boxShadow: "none",
          height: "40px",
          display: "flex",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onMouseOver={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <CustomTitle
            onClick={hasChildren && handleClick}
            sx={{ cursor: hasChildren ? "pointer" : "default" }}
            title={team.description || team.title}
          >
            {hasChildren && (
              <IconButton>
                {visible ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
            >
              {team.title} {hasChildren && `(${mediationUsers.length})`}
            </Typography>
          </CustomTitle>
          {showMenu && isCurrentUserMediationAdmin && (showIcon || anchorEl) && (
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleShowMenu(event)}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Card>
      <Collapse in={visible} timeout="auto" unmountOnExit>
        <Grid
          container
          spacing={0}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          {/* {loading && <Loading />} */}
          {mediationUsers &&
            mediationUsers.map((mediationUser) => (
              <Grid
                key={mediationUser.id}
                item
                xs={12}
                onDrag={(event) => handleDrag(event, mediationUser)}
                sx={{ cursor: "pointer" }}
                draggable
              >
                <UserMediationItem
                  isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
                  mediationUser={mediationUser}
                  mediationTeams={mediationTeams}
                  sessionUser={user}
                  disabled={disabled}
                />
              </Grid>
            ))}
        </Grid>
      </Collapse>
    </>
  );
};

UserTeamCard.propTypes = {
  user: PropTypes.shape().isRequired,
  team: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  mediationUsers: PropTypes.arrayOf(PropTypes.shape()),
  mediationTeams: PropTypes.arrayOf(PropTypes.shape()),
  handleClickMenu: PropTypes.func,
  setTeamSelectioned: PropTypes.func,
  showMenu: PropTypes.bool,
  userSelectioned: PropTypes.shape(),
  setUserSelectioned: PropTypes.func,
  disabled: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  isCurrentUserMediationAdmin: PropTypes.bool,
};

UserTeamCard.defaultProps = {
  mediationUsers: null,
  mediationTeams: null,
  handleClickMenu: null,
  setTeamSelectioned: null,
  showMenu: false,
  userSelectioned: null,
  setUserSelectioned: null,
  disabled: false,
  isMenuOpen: false,
  isCurrentUserMediationAdmin: false,
};

export default UserTeamCard;
