import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const CustomContainer = ({ children, ...rest }) => (
  <Box
    maxWidth={{ sm: 720, md: 1236, xl: 1400 }}
    width="-webkit-fill-available"
    margin="0 auto"
    paddingX={2}
    paddingY={{ xs: 2, sm: 2, md: 2, xl: 2 }}
    {...rest}
  >
    {children}
  </Box>
);

CustomContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomContainer;
