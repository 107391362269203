import styled from "styled-components";

export const ConnectionState = styled.div`
  position: absolute;
  margin-top: 30px;
  margin-left: 29px;
  background: #65cb65;
  border-radius: 50%;
  width: 10px;
  height: 10px;
`;
