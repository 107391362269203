import { ENDPOINTS } from "../constants/endpoints";
// import { ROUTES } from "../constants/routes";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class SessionService {
  async get() {
    return axios
      .get(`${ENDPOINTS.USER}/Current`)
      .then(handleResponse)
      .then((response) => response)
      .catch((error) => error);
  }
}

export const sessionService = new SessionService();
