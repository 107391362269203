import React, { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import UserInvitationItem from "../UserInvitationItem";

const InvitationTeamCard = ({
  team,
  mediationInvitations,
  mediationId,
  disabled,
  isCurrentUserMediationAdmin,
}) => {
  const [visible, setVisible] = useState(true);
  const hasChildren = mediationInvitations && mediationInvitations.length > 0;

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Card
        sx={{
          background: "rgb(240,240,240,0.5)",
          paddingLeft: hasChildren ? "0px" : "20px",
          margin: "14px auto 8px auto",
          boxShadow: "none",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            onClick={hasChildren && handleClick}
            sx={{ cursor: hasChildren ? "pointer" : "default" }}
          >
            {hasChildren && (
              <IconButton>
                {visible ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
            <Tooltip title={team.description ? team.description : ""} arrow>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: 600,
                }}
              >
                {team.title} {hasChildren && `(${mediationInvitations.length})`}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Card>
      <Collapse in={visible} timeout="auto" unmountOnExit>
        <Grid container spacing={0}>
          {mediationInvitations &&
            mediationInvitations.map((mediationInvitation) => (
              <Grid key={mediationInvitation.id} item xs={12}>
                <UserInvitationItem
                  mediationInvitation={mediationInvitation}
                  mediationId={mediationId}
                  disabled={disabled}
                  isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
                />
              </Grid>
            ))}
        </Grid>
      </Collapse>
    </>
  );
};

InvitationTeamCard.propTypes = {
  team: PropTypes.shape().isRequired,
  mediationId: PropTypes.string.isRequired,
  mediationInvitations: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  isCurrentUserMediationAdmin: PropTypes.bool,
};

InvitationTeamCard.defaultProps = {
  mediationInvitations: null,
  disabled: false,
  isCurrentUserMediationAdmin: false,
};

export default InvitationTeamCard;
