import { styled } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { theme } from "../../helpers/theme";

export const MenuContent = styled(Menu)((props) => ({
  "& .MuiPaper-root": {
    width: props.width || "100%",
  },
}));

export const CustomMenuItem = styled(MenuItem)({
  "& a": {
    textDecoration: "none",
    color: theme.blackPearl,
  },
});
