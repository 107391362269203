import Box from "@mui/material/Box";
import styled from "styled-components";

export const CustomTitle = styled(Box)({
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  "& p": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});
