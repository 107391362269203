import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MediationEdit from "../MediationEdit";

const ReportDescription = ({
  formik,
  counterpart,
  handleInitValue,
  disabled,
}) => {
  const { report } = useSelector((state) => state.mediationReports);
  const params = [
    {
      name: "description",
      type: "ckeditor",
    },
  ];
  return (
    <MediationEdit
      params={params}
      formik={formik}
      item={report}
      handleInitValue={handleInitValue}
      counterpart={counterpart}
      disabled={disabled}
    />
  );
};

ReportDescription.propTypes = {
  formik: PropTypes.shape().isRequired,
  counterpart: PropTypes.func.isRequired,
  handleInitValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ReportDescription.defaultProps = {
  disabled: false,
};

export default ReportDescription;
