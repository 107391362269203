/* eslint-disable default-param-last */
import { MEDIATION_REPORTS } from "../constants/actions";

const initialState = {
  reports: null,
  report: null,
};

export function mediationReports(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_REPORTS.GET_ALL: {
      return {
        ...state,
        reports: action.payload,
      };
    }
    case MEDIATION_REPORTS.GET: {
      return {
        ...state,
        report: action.payload,
      };
    }
    case MEDIATION_REPORTS.DELETE: {
      const items = state.reports.filter((mi) => mi.id !== action.payload.id);
      return {
        ...state,
        reports: items,
        report: null,
      };
    }
    case MEDIATION_REPORTS.ADD: {
      return {
        ...state,
        reports: [...state.reports, action.payload],
        report: action.payload,
      };
    }
    case MEDIATION_REPORTS.UPDATE: {
      const items = state.reports;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      items[index] = action.payload;
      return {
        ...state,
        reports: items,
        report: action.payload,
      };
    }
    case MEDIATION_REPORTS.INIT: {
      return {
        ...state,
        report: null,
      };
    }
    default:
      return state;
  }
}
