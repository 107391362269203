import React from "react";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import CustomMenu from "../CustomMenu";

const ListMenu = ({ menus, anchorEl, handleCloseMenu }) => (
  <CustomMenu
    anchorEl={anchorEl}
    handleCloseMenu={handleCloseMenu}
    width="auto"
  >
    {menus
      // .filter((m) => !m.disabled)
      .map((menu) => {
        if (menu.type === "menu") {
          return (
            <MenuItem
              key={menu.label}
              onClick={menu.handleClick}
              disabled={menu.disabled}
            >
              {menu.label}
            </MenuItem>
          );
        }
        return <Divider key={menu.label} />;
      })}
  </CustomMenu>
);

ListMenu.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape(),
};

ListMenu.defaultProps = {
  anchorEl: null,
};

export default ListMenu;
