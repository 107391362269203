/* eslint-disable arrow-body-style */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import { history } from "./helpers/history";
import MediationPage from "./pages/MediationPage";
import Layout from "./components/Layout";
import ListMediationPage from "./pages/ListMediationPage";
import NonAccessPage from "./pages/NonAccessPage";
import NoFoundPage from "./pages/NoFoundPage";

const App = () => {
  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <Route path={ROUTES.ROOT} component={ListMediationPage} exact />
          <Route path={ROUTES.NO_ACCESS_PAGE} component={NonAccessPage} />
          <Route path={ROUTES.MEDIATION_ROOT} component={MediationPage} />
          <Route component={NoFoundPage} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
