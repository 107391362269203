/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  addMediationDate,
  getMediationDates,
  setMediationDateAnswer,
} from "../../actions/mediationDates.actions";
import {
  addMediationMeeting,
  deleteMediationMeeting,
  getMediationMeeting,
  initMediationMeeting,
  selectDate,
  updateMediationMeeting,
} from "../../actions/mediationMeetings.actions";
import CustomCard from "../../components/CustomCard";
import DateComponent from "../../components/DateComponent";
import ListDialog from "../../components/ListDialog";
import ListMenu from "../../components/ListMenu";
import { Mandatory } from "../../components/Mandatory";
import MeetingDetail from "../../components/MeetingDetail";
import MeetingEdit from "../../components/MeetingEdit";
import AssignMembers from "../../components/AssignMembers";
import { ROUTES } from "../../constants/routes";
import { useCounterpart } from "../../context/language.context";
import moment from "../../helpers/moment";
import { isMediationAdmin } from "../../helpers/utils";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import LoadingComponent from "../../components/LoadingComponent";
import { ContentLoading, useStyles } from "./style";

const validationSchema = yup.object({});

export const Meetings = () => {
  const { counterpart } = useCounterpart();
  const history = useHistory();
  const { mediationId, mediationMeetingId } = useParams();
  const { mediation } = useSelector((state) => state.mediation);
  const mediationDates = useSelector((state) => state.mediationDates);
  const mediationUsers = useSelector((state) =>
    state.mediationUsers.filter(
      (mu) => !mu.deletionDate && !mu.user.deletionDate
    )
  );
  const { meeting } = useSelector((state) => state.mediationMeetings);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [newDate, setNewDate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [monthYears, setMonthYears] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [isRemoveDateModalOpen, setIsRemoveDateModalOpen] = useState(false);
  const [isModalSelectDateOpen, setIsModalSelectDateOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [dateSelectioned, setDateSelectioned] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mediationMeetingUserIds, setMediationMeetingUserIds] = useState(
    meeting?.mediationMeetingUserIds || []
  );
  const isEditingOrAdding = showEdit || !meeting;
  const isMediationDisabled = Boolean(mediation.endDate);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);

  const initialValues = {
    title: "",
    description: "",
    address: "",
  };

  const handleAddOrUpdate = async (values) => {
    if (meeting) {
      await dispatch(
        updateMediationMeeting({
          ...values,
          id: mediationMeetingId,
          mediationId,
          mediationMeetingUserIds: meeting.mediationMeetingUserIds,
        })
      );
      handleInitValue();
    } else {
      await dispatch(addMediationMeeting({ ...values, mediationId }, history));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleAddOrUpdate,
  });

  const handleInitValue = () => {
    setShowEdit(false);
    formik.setFieldValue("title", "");
    formik.setFieldValue("address", "");
    formik.setFieldValue("description", "<p>Description de la réunion</p>");
    formik.setFieldTouched("title", false);
    formik.setFieldTouched("address", false);
    formik.setFieldTouched("description", false);
  };

  const handleRemoveMeeting = async () => {
    await dispatch(deleteMediationMeeting({ id: mediationMeetingId }));
    setIsRemoveModalOpen(false);
    history.push(`${ROUTES.MEDIATION}${mediationId}`);
  };

  const handleSelectDate = async () => {
    await dispatch(
      selectDate({
        id: mediationMeetingId,
        mediationDateId: meeting.selectedMediationDate ? null : dateSelected.id,
      })
    );
    setIsModalSelectDateOpen(false);
  };

  const handleUpdateUsersSelected = async () => {
    await dispatch(
      updateMediationMeeting({
        ...meeting,
        id: mediationMeetingId,
        mediationId,
        mediationMeetingUserIds,
      })
    );
    setIsGuestModalOpen(false);
  };

  const toggleModal = (type) => {
    if (type === "date") {
      setIsModalOpen(!isModalOpen);
    } else if (type === "meeting") {
      setIsRemoveModalOpen(!isRemoveModalOpen);
    } else if (type === "selectDate") {
      setIsModalSelectDateOpen(!isModalSelectDateOpen);
    } else if (type === "deleteDate") {
      setIsRemoveDateModalOpen(isRemoveDateModalOpen);
    } else {
      setIsGuestModalOpen(!isGuestModalOpen);
    }
    setAnchorEl(null);
    setAnchorElDate(null);
  };

  const checkDate = async (date, value) => {
    if (!date) return;

    await dispatch(
      setMediationDateAnswer({
        mediationId,
        answer: !value,
        id: date.id,
        mediationMeetingId,
      })
    );
  };

  const handleAddDate = async () => {
    await dispatch(
      addMediationDate({ mediationId, date: newDate, mediationMeetingId })
    );
    setIsModalOpen(false);
  };

  const handleRemoveDate = async () => {
    setIsRemoveDateModalOpen(false);
  };

  const handleRemoveDateSelected = () => {
    toggleModal("selectDate");
    setDateSelectioned(null);
    setAnchorEl(null);
  };

  const renderAddDateModal = () => (
    <Grid container>
      <Typography marginBottom={1}>
        {counterpart("mediation.date")} <Mandatory />
      </Typography>
      <TextField
        id="newDate"
        type="datetime-local"
        variant="outlined"
        placeholder="Email"
        value={newDate}
        onChange={(e) => setNewDate(e.target.value)}
        fullWidth
      />
    </Grid>
  );

  const extractYearMonth = (mediationDateList) => {
    const result = [];
    mediationDateList.forEach((item) => {
      const date = moment(new Date(item.date));
      if (
        result.length === 0 ||
        !result.includes(`${date.format("MMMM")} ${date.format("YYYY")}`)
      ) {
        result.push(`${date.format("MMMM")} ${date.format("YYYY")}`);
      }
    });
    return result;
  };

  useEffect(() => {
    if (mediationDates) {
      setMonthYears(extractYearMonth(mediationDates));
    }
  }, [mediationDates]);

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleShowMenuDate = (event) => {
    setAnchorElDate(event.currentTarget);
  };

  const handleCloseMenuDate = () => {
    setAnchorElDate(null);
  };

  const handleShowEdit = () => {
    handleCloseMenu();
    setShowEdit(true);
    formik.setFieldValue("title", meeting.title);
    formik.setFieldValue("description", meeting.description);
    formik.setFieldValue("address", meeting.address);
  };

  const handleChangeTitle = (event) => {
    formik.setFieldValue("title", event.target.value);
  };

  const renderModal = (content) => (
    <Grid container>
      <Typography variant="subtitle1">{content}</Typography>
    </Grid>
  );

  useEffect(async () => {
    if (mediationMeetingId) {
      const mediationGets = [
        dispatch(getMediationMeeting({ id: mediationMeetingId })),
        dispatch(getMediationDates({ mediationMeetingId })),
      ];
      await Promise.all(mediationGets)
        .then((result) => {
          setMediationMeetingUserIds(result[0]?.mediationMeetingUserIds);
          setDateSelectioned(result[1]?.find((item) => item.isSelected));
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            history.push(ROUTES.LOGIN);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await dispatch(initMediationMeeting());
    }
    handleInitValue();
    setIsLoading(false);
  }, [mediationMeetingId]);

  return isLoading ? (
    <ContentLoading>
      <LoadingComponent />
    </ContentLoading>
  ) : (
    <CustomCard
      title={
        isEditingOrAdding
          ? formik.values.title
          : meeting?.title || counterpart("mediation.meetings.title")
      }
      handleOnClickIcon={handleShowMenu}
      showMoreIcon={isCurrentUserMediationAdmin && meeting && !showEdit}
      showEdit={isEditingOrAdding}
      labelTextField="Titre de la réunion"
      handleChangeTitle={handleChangeTitle}
      formik={formik}
      counterpart={counterpart}
      className={classes.header}
      shadow
    >
      {meeting && (
        <MeetingDetail
          meeting={meeting}
          mediationDatesLength={mediationDates?.length || 0}
          mediationUsers={mediationUsers}
          showEdit={showEdit}
          dateSelectioned={dateSelectioned}
        />
      )}
      {isEditingOrAdding && (
        <MeetingEdit
          formik={formik}
          counterpart={counterpart}
          handleInitValue={handleInitValue}
          disabled={isMediationDisabled}
        />
      )}
      {!meeting?.selectedMediationDate &&
        !isEditingOrAdding &&
        !isLoading &&
        monthYears.map((item) => {
          const mediationDateFiltered = mediationDates
            .filter((mediationDate) => {
              const date = moment(new Date(mediationDate.date));
              return `${date.format("MMMM")} ${date.format("YYYY")}` === item;
            })
            .sort((mediationDate) => mediationDate.date);
          return (
            <DateComponent
              key={item}
              monthYear={item}
              mediationDateFiltered={mediationDateFiltered}
              checkDate={checkDate}
              currentMediationUser={currentMediationUser}
              mediationUsers={mediationUsers}
              mediationMeetingUserIds={mediationMeetingUserIds}
              setDateSelected={setDateSelected}
              handleShowMenuDate={handleShowMenuDate}
              isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
            />
          );
        })}
      <ListMenu
        menus={[
          {
            type: "menu",
            handleClick: handleShowEdit,
            label: counterpart("mediation.meetings.edit"),
            disabled: isMediationDisabled,
          },
          {
            type: "menu",
            handleClick: () => toggleModal("meeting"),
            label: counterpart("mediation.meetings.delete"),
            disabled: isMediationDisabled,
          },
          {
            type: "divider",
            label: "divider1",
          },
          {
            type: "menu",
            handleClick: () => toggleModal(),
            label: counterpart("mediation.meetings.add-guest"),
            disabled: isMediationDisabled,
          },
          {
            type: "divider",
            label: "divider2",
          },
          {
            type: "menu",
            handleClick: () => toggleModal("date"),
            label: counterpart("mediation.meetings.add-date"),
            disabled: isMediationDisabled,
          },
          {
            type: "menu",
            handleClick: handleRemoveDateSelected,
            label: counterpart("mediation.meetings.delete-selected-date"),
            disabled: !meeting?.selectedMediationDate || isMediationDisabled,
          },
        ]}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
      />
      <ListMenu
        menus={[
          {
            type: "menu",
            handleClick: () => toggleModal("selectDate"),
            label: counterpart("mediation.meetings.select-date"),
            disabled: isMediationDisabled,
          },
          {
            type: "menu",
            handleClick: () => toggleModal("deleteDate"),
            label: counterpart("common.delete"),
            disabled: isMediationDisabled,
          },
        ]}
        anchorEl={anchorElDate}
        handleCloseMenu={handleCloseMenuDate}
      />
      <ListDialog
        dialogs={[
          {
            title: counterpart("mediation.add-date"),
            isOpen: isModalOpen,
            handleClose: () => toggleModal("date"),
            acceptLabel: counterpart("mediation.add"),
            handleAccept: handleAddDate,
            children: renderAddDateModal(),
          },
          {
            title: counterpart(
              meeting?.selectedMediationDate
                ? "mediation.meetings.unselect-date"
                : "mediation.meetings.select-date"
            ),
            isOpen: isModalSelectDateOpen,
            handleClose: () => toggleModal("selectDate"),
            acceptLabel: counterpart(
              meeting?.selectedMediationDate
                ? "mediation.meetings.unselect-date"
                : "mediation.meetings.select-date"
            ),
            handleAccept: handleSelectDate,
            children: renderModal(
              counterpart(
                meeting?.selectedMediationDate
                  ? "mediation.meetings.confirm-delete-selected-date"
                  : "mediation.meetings.confirm-select"
              )
            ),
          },
          {
            title: counterpart("mediation.meetings.delete"),
            isOpen: isRemoveModalOpen,
            handleClose: () => toggleModal("meeting"),
            acceptLabel: counterpart("common.delete"),
            handleAccept: handleRemoveMeeting,
            children: renderModal(
              counterpart("mediation.meetings.confirm-delete")
            ),
          },
          {
            title: "Participants",
            isOpen: isGuestModalOpen,
            handleClose: () => toggleModal("users"),
            acceptLabel: counterpart("common.save"),
            handleAccept: handleUpdateUsersSelected,
            children: (
              <AssignMembers
                item={meeting}
                mediationUsers={mediationUsers}
                mediationItemUserIds={mediationMeetingUserIds}
                handleSetMediationItemUserIds={setMediationMeetingUserIds}
              />
            ),
          },
          {
            title: counterpart("mediation.dates.delete"),
            isOpen: isRemoveDateModalOpen,
            handleClose: () => toggleModal("deleteDate"),
            acceptLabel: counterpart("common.delete"),
            handleAccept: handleRemoveDate,
            children: renderModal(
              counterpart("mediation.dates.confirm-delete")
            ),
          },
        ]}
      />
    </CustomCard>
  );
};
