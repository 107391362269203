/* eslint-disable no-unused-vars */
import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";
import { handleGetAllMessages } from "../helpers/utils";

class MediationChatMessageService {
  async getAllChatMessages({ id, mediationChatGroupUsers }) {
    return axios
      .get(`${ENDPOINTS.MEDIATION_CHAT}/${id}/messages`)
      .then((response) => ({
        responseData: handleGetAllMessages(
          response.data,
          mediationChatGroupUsers
        ),
        statusText: "OK",
      }));
  }

  async addMediationChatMessage({ id, mediationId, message }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_CHAT}/${id}/message`, {
        mediationId,
        mediationChatGroupId: id,
        message,
      })
      .then(handleResponse);
  }
}

export const mediationChatMessageService = new MediationChatMessageService();
