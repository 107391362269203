import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationTimesheetsService {
  async getMediationTimesheets({ mediationId, page = 0 }) {
    const parameters = new URLSearchParams({
      mediationId,
      page,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION_TIMESHEET}?${parameters}`)
      .then(handleResponse);
  }

  async addMediationTimesheet({ mediationId, date, description, hour }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_TIMESHEET}`, {
        mediationId,
        date,
        description,
        hour,
      })
      .then(handleResponse);
  }

  async updateMediationTimesheet({ mediationId, id, date, description, hour }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_TIMESHEET}/${id}`, {
        id,
        mediationId,
        date,
        description,
        hour,
      })
      .then(handleResponse);
  }

  async deleteMediationTimesheet({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_TIMESHEET}/${id}`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }
}

export const mediationTimesheetsService = new MediationTimesheetsService();
