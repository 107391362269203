import Box from "@mui/material/Box";
import styled from "styled-components";

export const ChatBarContainer = styled.form`
  padding: 8px;
  background: white;
  display: flex;
  align-items: end;
`;

export const ChatInput = styled.input`
  width: calc(100% - 10px - 40px);
  display: inline-block;
  height: 45px;
  vertical-align: top;
  background-color: #f0f0f0;
  padding-left: 15px;
  border: 0px solid;
  border-radius: 0.375rem;
  outline: none;
  margin-right: 10px;
  font-size: 15px;
  color: rgb(44, 44, 44);
`;

export const ChatButton = styled.button`
  display: inline-block;
  border-radius: 50%;
  background-color: #335eea;
  width: 40px;
  height: 40px;
  color: white;
  opacity: 0.9;
  cursor: pointer;
  border: solid 1px transparent;
  transition: opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-left: 6px;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #b0b0b0;
    cursor: default;
  }
`;

export const ContentEditor = styled(Box)`
  position: relative;
  width: calc(100% - 10px - 40px);
  .ck-content {
    background: #f0f0f0 !important;
    border-radius: 5px !important;
    border-color: white !important;
    max-height: 180px;
    p {
      width: calc(100% - 30px);
    }
  }
  .ck-sticky-panel {
    display: ${(props) => (!props.showToolbar ? "none" : "")};
  }
  .icon-toolbar {
    position: absolute;
    right: 2px;
    top: ${(props) => (!props.showToolbar ? "4px" : "44px")};
    z-index: 2;
    svg {
      color: ${(props) => (props.showToolbar ? "#377dff" : "")};
    }
  }
  .icon-toolbar:hover {
    background-color: transparent;
  }
`;
