/* eslint-disable no-use-before-define */

/* eslint-disable react/no-array-index-key */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  addMediationPayment,
  deleteMediationPayment,
  getMediationPayment,
  initMediationPayment,
  updateMediationPayment,
} from "../../actions/mediationPayments.actions";
import CustomCard from "../../components/CustomCard";
import ListDialog from "../../components/ListDialog";
import ListMenu from "../../components/ListMenu";
import PaymentDetail from "../../components/PaymentDetail";
import PaymentEdit from "../../components/PaymentEdit";
import AssignMembers from "../../components/AssignMembers";
import { ROUTES } from "../../constants/routes";
import { useCounterpart } from "../../context/language.context";
import { isMediationAdmin } from "../../helpers/utils";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import { ContentLoading, useStyles } from "./style";
import LoadingComponent from "../../components/LoadingComponent";

const validationSchema = yup.object({});

export const Payments = () => {
  const { counterpart } = useCounterpart();
  const history = useHistory();
  const { mediationId, mediationPaymentId } = useParams();
  const { mediation } = useSelector((state) => state.mediation);
  const mediationUsers = useSelector((state) =>
    state.mediationUsers.filter(
      (mu) => !mu.deletionDate && !mu.user.deletionDate
    )
  );
  const { payment } = useSelector((state) => state.mediationPayments);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mediationPaymentUserIds, setMediationPaymentUserIds] = useState(
    payment?.mediationPaymentUserIds || []
  );
  const isEditingOrAdding = showEdit || !payment;
  const isMediationDisabled = Boolean(mediation.endDate);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);

  const initialValues = {
    title: "",
    description: "",
    amount: 0,
    currency: payment?.currency || "EUR",
    limitDate: null,
    paymentDate: null,
  };

  const handleAddOrUpdate = async (values) => {
    if (payment) {
      await dispatch(
        updateMediationPayment({
          ...values,
          id: mediationPaymentId,
          mediationId,
          mediationPaymentUserIds: payment.mediationPaymentUserIds,
        })
      );
      handleInitValue();
    } else {
      await dispatch(addMediationPayment({ ...values, mediationId }, history));
    }
  };

  const handleRemoveReport = async () => {
    await dispatch(deleteMediationPayment({ id: mediationPaymentId }));
    setIsRemoveModalOpen(false);
    history.push(`${ROUTES.MEDIATION}${mediationId}`);
  };

  const handleUpdateUsersSelected = async () => {
    await dispatch(
      updateMediationPayment({
        ...payment,
        id: mediationPaymentId,
        mediationId,
        mediationPaymentUserIds,
      })
    );
    setIsGuestModalOpen(false);
  };

  const handleInitValue = () => {
    setShowEdit(false);
    formik.setFieldValue("title", "");
    formik.setFieldValue("amount", 0);
    formik.setFieldValue("currency", payment?.currency || "EUR");
    formik.setFieldValue("limitDate", null);
    formik.setFieldValue("paymentDate", null);
    formik.setFieldValue("description", "<p>Description du paiement</p>");
    formik.setFieldTouched("title", false);
    formik.setFieldTouched("amount", false);
    formik.setFieldTouched("currency", false);
    formik.setFieldTouched("description", false);
    formik.setFieldTouched("limitDate", false);
    formik.setFieldTouched("paymentDate", false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleAddOrUpdate,
  });

  const toggleModal = (type) => {
    if (type === "payment") {
      setIsRemoveModalOpen(!isRemoveModalOpen);
    } else {
      setIsGuestModalOpen(!isGuestModalOpen);
    }
    setAnchorEl(null);
  };

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleShowEdit = () => {
    handleCloseMenu();
    setShowEdit(true);
    formik.setFieldValue("title", payment.title);
    formik.setFieldValue("description", payment.description);
    formik.setFieldValue("amount", payment.amount);
    formik.setFieldValue("limitDate", payment.limitDate);
    formik.setFieldValue("paymentDate", payment.paymentDate);
  };

  const handleChangeTitle = (event) => {
    formik.setFieldValue("title", event.target.value);
  };

  const renderRemoveModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {counterpart("mediation.payments.confirm-delete")}
      </Typography>
    </Grid>
  );

  useEffect(async () => {
    if (mediationPaymentId) {
      await Promise.all([
        dispatch(getMediationPayment({ id: mediationPaymentId })),
      ])
        .then((result) => {
          setMediationPaymentUserIds(result[0].mediationPaymentUserIds);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            history.push(ROUTES.LOGIN);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await dispatch(initMediationPayment());
    }
    handleInitValue();
    setIsLoading(false);
  }, [mediationPaymentId]);

  return isLoading ? (
    <ContentLoading>
      <LoadingComponent />
    </ContentLoading>
  ) : (
    <CustomCard
      title={
        isEditingOrAdding
          ? formik.values.title
          : payment?.title || counterpart("mediation.payments.title")
      }
      handleOnClickIcon={handleShowMenu}
      showMoreIcon={isCurrentUserMediationAdmin && payment && !showEdit}
      showEdit={isEditingOrAdding}
      labelTextField="Titre du paiement"
      handleChangeTitle={handleChangeTitle}
      formik={formik}
      counterpart={counterpart}
      className={classes.header}
      shadow
    >
      {payment && (
        <PaymentDetail
          payment={payment}
          mediationUsers={mediationUsers}
          showEdit={showEdit}
        />
      )}
      {isEditingOrAdding && (
        <PaymentEdit
          formik={formik}
          counterpart={counterpart}
          handleInitValue={handleInitValue}
          disabled={isMediationDisabled}
        />
      )}
      <ListMenu
        menus={[
          {
            type: "menu",
            handleClick: handleShowEdit,
            label: counterpart("mediation.payments.edit"),
            disabled: isMediationDisabled,
          },
          {
            type: "menu",
            handleClick: () => toggleModal("payment"),
            label: counterpart("mediation.payments.delete"),
            disabled: isMediationDisabled,
          },
          {
            type: "divider",
            label: "divider1",
          },
          {
            type: "menu",
            handleClick: () => toggleModal(),
            label: counterpart("mediation.payments.add-guest"),
            disabled: isMediationDisabled,
          },
        ]}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
      />
      <ListDialog
        dialogs={[
          {
            title: counterpart("mediation.payments.delete"),
            isOpen: isRemoveModalOpen,
            handleClose: () => toggleModal("payment"),
            acceptLabel: counterpart("common.delete"),
            handleAccept: handleRemoveReport,
            children: renderRemoveModal(),
          },
          {
            title: "Participants",
            isOpen: isGuestModalOpen,
            handleClose: () => toggleModal(),
            acceptLabel: counterpart("common.save"),
            handleAccept: handleUpdateUsersSelected,
            children: (
              <AssignMembers
                item={payment}
                mediationUsers={mediationUsers.filter((mu) => !mu.deletionDate)}
                mediationItemUserIds={mediationPaymentUserIds}
                handleSetMediationItemUserIds={setMediationPaymentUserIds}
              />
            ),
          },
        ]}
      />
    </CustomCard>
  );
};
