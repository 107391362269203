/* eslint-disable no-use-before-define */
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  addMediationReport,
  deleteMediationReport,
  getMediationReport,
  initMediationReport,
  updateMediationReport,
} from "../../actions/mediationReports.actions";
import CustomCard from "../../components/CustomCard";
import CustomMenu from "../../components/CustomMenu";
import Dialog from "../../components/Dialog";
import LoadingComponent from "../../components/LoadingComponent";
import ReportEditDescription from "../../components/ReportDescription";
import ReportDetail from "../../components/ReportDetail";
import { ROUTES } from "../../constants/routes";
import { useCounterpart } from "../../context/language.context";
import { isMediationAdmin } from "../../helpers/utils";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import { ContentLoading, useStyles } from "./style";

const validationSchema = yup.object({
  title: yup.string().trim().required("mediation.reports.title-required"),
  description: yup.string().trim().required("mediation.description-required"),
});

export const Reports = () => {
  const { counterpart } = useCounterpart();
  const history = useHistory();
  const classes = useStyles();
  const { mediationId, mediationReportId } = useParams();
  const dispatch = useDispatch();
  const { mediation } = useSelector((state) => state.mediation);
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const { report } = useSelector((state) => state.mediationReports);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isEditingOrAdding = showEdit || !report;
  const isMediationDisabled = Boolean(mediation.endDate);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);

  const initialValues = {
    title: "",
    description: "<p>Description du rapport</p>",
  };

  const handleAddOrUpdateReport = async (values) => {
    if (report) {
      await dispatch(
        updateMediationReport({ ...values, mediationId, id: mediationReportId })
      );
      handleInitValue();
    } else {
      await dispatch(addMediationReport({ ...values, mediationId }, history));
    }
  };

  const handleRemoveReport = async () => {
    await dispatch(deleteMediationReport({ id: mediationReportId }));
    setIsRemoveModalOpen(false);
    history.push(`${ROUTES.MEDIATION}${mediationId}`);
  };

  const handleInitValue = () => {
    setShowEdit(false);
    formik.setFieldValue("title", "");
    formik.setFieldValue("description", "<p>Description du rapport</p>");
    formik.setFieldTouched("title", false);
    formik.setFieldTouched("description", false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleAddOrUpdateReport,
  });

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleShowEdit = () => {
    handleCloseMenu();
    setShowEdit(true);
    formik.setFieldValue("title", report.title);
    formik.setFieldValue("description", report.description);
  };

  const handleChangeTitle = (event) => {
    formik.setFieldValue("title", event.target.value);
  };

  const renderRemoveModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {counterpart("mediation.reports.confirm-delete")}
      </Typography>
    </Grid>
  );

  const toggleRemoveModal = () => {
    setAnchorEl(null);
    setIsRemoveModalOpen(!isRemoveModalOpen);
  };

  useEffect(async () => {
    if (mediationReportId) {
      await dispatch(getMediationReport({ id: mediationReportId }));
    } else {
      await dispatch(initMediationReport());
    }
    handleInitValue();
    setIsLoading(false);
  }, [mediationReportId]);

  return (
    <>
      {isLoading ? (
        <ContentLoading>
          <LoadingComponent />
        </ContentLoading>
      ) : (
        <CustomCard
          title={
            isEditingOrAdding
              ? formik.values.title
              : (report && report.title) ||
                counterpart("mediation.reports.title")
          }
          handleOnClickIcon={handleShowMenu}
          showMoreIcon={isCurrentUserMediationAdmin && report && !showEdit}
          showEdit={isEditingOrAdding}
          labelTextField="Titre du rapport"
          handleChangeTitle={handleChangeTitle}
          formik={formik}
          counterpart={counterpart}
          className={classes.header}
          shadow
        >
          {report && (
            <ReportDetail
              report={report}
              mediationUsers={mediationUsers}
              showEdit={showEdit}
            />
          )}
          {isEditingOrAdding && (
            <ReportEditDescription
              formik={formik}
              counterpart={counterpart}
              handleInitValue={handleInitValue}
              disabled={isMediationDisabled}
            />
          )}
        </CustomCard>
      )}
      {anchorEl && (
        <CustomMenu
          anchorEl={anchorEl}
          handleCloseMenu={handleCloseMenu}
          width="auto"
        >
          <MenuItem onClick={handleShowEdit} disabled={isMediationDisabled}>
            {counterpart("mediation.edit")}
          </MenuItem>
          <MenuItem onClick={toggleRemoveModal} disabled={isMediationDisabled}>
            {counterpart("common.delete")}
          </MenuItem>
        </CustomMenu>
      )}
      {isRemoveModalOpen && (
        <Dialog
          title={counterpart("mediation.reports.title-delete")}
          open={isRemoveModalOpen}
          handleClose={toggleRemoveModal}
          acceptLabel={counterpart("common.delete")}
          onAccept={handleRemoveReport}
          width="md"
        >
          {renderRemoveModal()}
        </Dialog>
      )}
    </>
  );
};
