import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Dialog from "../Dialog";
import AssignMembers from "../AssignMembers";
import { updateMediationChatGroup } from "../../actions/mediationChat.actions";
import { extractOnlyIds, sendConnection } from "../../helpers/utils";
import { addValueMediationChatMessage } from "../../actions/mediationChatMessage.actions";
import { getCookie } from "../../helpers/cookie";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";

const AssignMembersChat = ({
  isModalOpen,
  toggleModal,
  counterpart,
  usersSelected,
  setUsersSelected,
  setTitle,
  history,
  isEdit,
  disabled,
}) => {
  const { mediationId } = useParams();
  const [listUserSelected, setListUserSelected] = useState();
  const connection = useSelector((state) => state.connection);
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const mediationUser = useCurrentMediationUser();
  const { chatGroup } = useSelector((state) => state.mediationChat);
  const dispatch = useDispatch();
  const token = getCookie("token");

  const handleAddMessageNewUsers = async (oldIds) => {
    const listUserSelectedClone = [...listUserSelected];
    return listUserSelectedClone
      .filter((u) => !oldIds.includes(u.id))
      .forEach(async (item) => {
        await dispatch(
          addValueMediationChatMessage({
            id: item.id,
            creationDate: new Date(),
            deletionDate: null,
            mediationUserId: item.id,
            type: "notification-enter",
          })
        );
        await sendConnection(
          "SendMessage",
          {
            id: item.id,
            mediationGroupId: chatGroup.id,
            mediationUserId: item.id,
            type: "notification-enter",
            token,
          },
          connection
        );
      });
  };

  const handleAddMessageRemoveUsers = async (newIds) => {
    const usersSelectedClone = [...usersSelected];
    return usersSelectedClone
      .filter((u) => !newIds.includes(u.id))
      .forEach(async (item) => {
        await dispatch(
          addValueMediationChatMessage({
            id: item.id,
            creationDate: new Date(),
            deletionDate: new Date(),
            mediationUserId: item.id,
            type: "notification-leave",
          })
        );
        await sendConnection(
          "SendMessage",
          {
            id: item.id,
            mediationGroupId: chatGroup.id,
            mediationUserId: item.id,
            type: "notification-leave",
            token,
          },
          connection
        );
      });
  };

  const handleAddUsers = async () => {
    if (isEdit) {
      const ids = extractOnlyIds(listUserSelected);
      const oldIds = extractOnlyIds(usersSelected);
      const result = await dispatch(
        updateMediationChatGroup({
          ...chatGroup,
          mediationId,
          mediationChatUserIds: ids,
        })
      );
      await sendConnection(
        "UpdateChatGroup",
        {
          id: chatGroup.id,
          title: chatGroup.title,
          mediationChatGroupUsers: result.mediationChatGroupUsers,
          mediationId,
        },
        connection
      );
      await handleAddMessageNewUsers(oldIds);
      await handleAddMessageRemoveUsers(ids);
    } else {
      setTitle(
        listUserSelected?.map((item) => item.user.firstName).join(", ") ||
          counterpart("mediation.comments.title")
      );
    }
    setUsersSelected(listUserSelected);
    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
    if (!isEdit) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (usersSelected?.length > 0) {
      setListUserSelected(usersSelected);
    } else {
      setListUserSelected([mediationUser, ...usersSelected]);
    }
  }, [usersSelected]);

  return (
    <Dialog
      title={counterpart("mediation.members.title")}
      open={isModalOpen}
      handleClose={isEdit ? toggleModal : () => null}
      handleCancel={handleCancel}
      acceptLabel={counterpart("common.save")}
      onAccept={handleAddUsers}
      width="md"
      disabled={disabled}
    >
      <AssignMembers
        item={chatGroup}
        mediationUsers={mediationUsers}
        mediationItemUserIds={listUserSelected}
        handleSetMediationItemUserIds={setListUserSelected}
        idCreator={chatGroup?.mediationUserId || mediationUser?.id}
        isCreatorToSelect={false}
        objectAdded
      />
    </Dialog>
  );
};

AssignMembersChat.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  counterpart: PropTypes.func.isRequired,
  usersSelected: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setUsersSelected: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  isEdit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

AssignMembersChat.defaultProps = {
  disabled: false,
};

export default AssignMembersChat;
