import React from "react";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import moment from "../../helpers/moment";
import { useCounterpart } from "../../context/language.context";

const CloseComponent = ({ closeDate }) => {
  const { counterpart, locale } = useCounterpart();
  const closeDateFormatted = moment(new Date(closeDate))
    .locale(locale)
    .format("DD MMM YYYY");
  return (
    <Card
      sx={{
        background: "rgb(240,240,240,0.5)",
        padding: "8px",
        boxShadow: "none",
        width: "fit-content",
        marginBottom: "8px",
      }}
    >
      <Typography variant="body2" fontStyle="italic" color="grey">
        {`${counterpart("mediation.closed-on")} ${closeDateFormatted}`}
      </Typography>
    </Card>
  );
};

CloseComponent.propTypes = {
  closeDate: PropTypes.string.isRequired,
};

export default CloseComponent;
