/* eslint-disable default-param-last */
import { MEDIATION_PAYMENTS } from "../constants/actions";

const initialState = {
  payments: null,
  payment: null,
};

export function mediationPayments(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_PAYMENTS.GET_ALL: {
      return {
        ...state,
        payments: action.payload,
      };
    }
    case MEDIATION_PAYMENTS.GET: {
      return {
        ...state,
        payment: action.payload,
      };
    }
    case MEDIATION_PAYMENTS.DELETE: {
      const items = state.payments.filter((mi) => mi.id !== action.payload.id);
      return {
        ...state,
        payments: items,
      };
    }
    case MEDIATION_PAYMENTS.ADD: {
      return {
        ...state,
        payments: [...state.payments, action.payload],
      };
    }
    case MEDIATION_PAYMENTS.UPDATE: {
      const items = state.payments;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      items[index] = action.payload;
      return {
        ...state,
        payments: items,
        payment: action.payload,
      };
    }
    case MEDIATION_PAYMENTS.INIT: {
      return {
        ...state,
        payment: null,
      };
    }
    default:
      return state;
  }
}
