/* eslint-disable arrow-body-style */
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { ROUTES } from "../constants/routes";
import CustomContainer from "../components/CustomContainer";
import img from "../assets/404.png";
import { useCounterpart } from "../context/language.context";

const NoFoundPage = () => {
  const { counterpart } = useCounterpart();
  return (
    <CustomContainer>
      <Grid container height="calc(100vh - 160px)">
        <Grid
          item
          md={6}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height={1}
            width={0.9}
          >
            <Typography variant="h2">Oops!</Typography>
            <Typography variant="h4" mt={2}>
              {counterpart("notFound.desc")}
            </Typography>
            <Box mt={5}>
              <Link href={ROUTES.ROOT}>
                <Button variant="contained">
                  {counterpart("notFound.back")}
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            height={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={4}
          >
            <img
              src={img}
              alt="403 error"
              width="100%"
              // layout="fill"
              // objectFit="contain"
            />
          </Box>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};
export default NoFoundPage;
