/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import _ from "lodash";
import { theme } from "./theme";
import axios from "./axios";

export const isMediationAdmin = (mediationUser) =>
  mediationUser?.isMediationAdmin;

export const extractOnlyIds = (objects, props = null) => {
  const result = [];
  objects.forEach((object) => {
    if (props) {
      result.push(object[props]);
    } else {
      result.push(object.id);
    }
  });
  return result;
};

export const isMobile = () => window.innerWidth <= theme.breakpoint.sm;

export const sendConnection = async (message, params, connection) => {
  if (connection?._connectionStarted) {
    try {
      await connection.send(message, params);
    } catch (e) {
      console.log(e);
    }
  }
};

export const handleGetAllMessages = (allMessages, mediationChatGroupUsers) => {
  const result = [...allMessages];
  mediationChatGroupUsers?.forEach((item) => {
    result.push({
      ...item,
      type: "notification-enter",
    });
    if (item.deletionDate) {
      result.push({
        ...item,
        creationDate: item.deletionDate,
        type: "notification-leave",
      });
    }
  });
  return _.sortBy(result, (o) => o.creationDate);
};

export const extractChatUserIds = (mediationChatUsers) => {
  const result = [];
  mediationChatUsers.forEach((item) => {
    if (!item.deletionDate) {
      result.push(item.mediationUserId);
    }
  });
  return result;
};

export const extractUsersInfo = (users, mediationUsers) => {
  const idUsers = extractChatUserIds(users);
  return mediationUsers.filter((item) => idUsers.includes(item.id));
};

export const getDefaultTitleChat = (users, meditionUsers) =>
  extractUsersInfo(users, meditionUsers)
    ?.map((item) => item.user.firstName)
    .join(", ");

export const getInfoUser = (mediation, mediationUsers) =>
  mediationUsers?.find((item) => item.user.id === mediation?.userId);

export const getMediationInfo = (mediationUsers, userId) =>
  mediationUsers.find((mediation) => mediation.user.id === userId);

export const getExtensionFile = (type) => {
  const a = type.split(".");
  if (a.length <= 1) return "";
  return `.${a.slice(-1)[0]}`;
};

export const getNameFile = (fileName) => {
  const fileSplited = fileName.split(".");
  return fileSplited.slice(0, fileSplited.length - 1).join(".") || fileName;
};

export const capitalizeFirstLetter = (string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const getTotalMediatorTimeSheet = (mediationTimeSheets) =>
  mediationTimeSheets.reduce((obj, el) => {
    obj[el.mediationUserId] = (obj[el.mediationUserId] || 0) + el.hour;
    return obj;
  }, {});

export const getUserName = (user) =>
  `${user?.firstName || ""} ${user?.lastName || ""}`;

export const handleReplaceChatGroupsTitle = (chatGroups, mediationUsers) =>
  chatGroups.map((chatGroup) => ({
    ...chatGroup,
    title:
      chatGroup.title?.length > 0
        ? chatGroup.title
        : getDefaultTitleChat(
            chatGroup.mediationChatGroupUsers,
            mediationUsers
          ),
  }));

export const setToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};
