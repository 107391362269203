import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import AssignMembersItem from "./AssignMembersItem";
import { useCounterpart } from "../../context/language.context";

const AssignMembers = (props) => {
  const {
    item,
    mediationUsers,
    mediationItemUserIds,
    handleSetMediationItemUserIds,
    objectAdded,
    idCreator,
    isCreatorToSelect,
  } = props;

  const mediationTeams = useSelector((state) => state.mediationTeams);
  const { counterpart } = useCounterpart();

  const mediationUsersGrouped = _.groupBy(
    mediationUsers.filter((mu) => !mu.deletionDate && !mu.user.deletionDate),
    (mediationUser) => mediationUser.mediationTeamId
  );

  const isUserSelected = (idUser) => {
    if (objectAdded) {
      return mediationItemUserIds.some((i) => i.id === idUser);
    }
    return mediationItemUserIds.includes(idUser);
  };

  const handleSetUserSelected = (idUser, user) => {
    if (isUserSelected(idUser)) {
      handleSetMediationItemUserIds(
        objectAdded
          ? mediationItemUserIds.filter((i) => i.id !== idUser)
          : mediationItemUserIds.filter((i) => i !== idUser)
      );
    } else {
      handleSetMediationItemUserIds([
        ...mediationItemUserIds,
        objectAdded ? user : idUser,
      ]);
    }
  };

  return Object.keys(mediationUsersGrouped).map((i) => {
    const mediationTeam =
      mediationTeams.find((mt) => mt.id === i) ||
      counterpart("mediation.members.invited");
    return (
      <AssignMembersItem
        key={i}
        id={i}
        title={mediationTeam.title}
        mediationUsersGrouped={mediationUsersGrouped}
        item={item}
        isUserSelected={isUserSelected}
        handleSetUserSelected={handleSetUserSelected}
        idCreator={idCreator}
        isCreatorToSelect={isCreatorToSelect}
      />
    );
  });
};
export default AssignMembers;
