import React from "react";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import moment from "../../helpers/moment";
import { useCounterpart } from "../../context/language.context";

const CreateComponent = ({ user, item }) => {
  const { creationDate, modificationDate } = item;
  const { counterpart, locale } = useCounterpart();
  const userFormatted = user
    ? `${user.firstName} ${user.lastName}`
    : "User Inconnu";
  const creationDateFormatted = moment(new Date(creationDate))
    .locale(locale)
    .format("DD MMM YYYY hh:mm");
  const modificationDateFormatted = moment(new Date(modificationDate))
    .locale(locale)
    .format("DD MMM YYYY hh:mm");

  return (
    <Card
      sx={{
        boxShadow: "none",
        marginBottom: "8px",
      }}
    >
      <Typography
        variant="body2"
        fontStyle="italic"
        color="grey"
        sx={{ fontSize: "0.8rem" }}
      >
        {counterpart("mediation.documents.created-by", {
          user: userFormatted,
          date: creationDateFormatted,
        })}
      </Typography>
      {modificationDate && (
        <Typography
          variant="body2"
          fontStyle="italic"
          color="grey"
          sx={{ fontSize: "0.8rem" }}
        >
          {counterpart("common.last-modified", {
            value: modificationDateFormatted,
          })}
        </Typography>
      )}
    </Card>
  );
};

CreateComponent.propTypes = {
  user: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
};

export default CreateComponent;
