import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "./style";

const LoadingComponent = ({ className }) => (
  <Spinner className={className}>
    <div />
    <div />
    <div />
    <div />
  </Spinner>
);

LoadingComponent.propTypes = {
  className: PropTypes.string,
};

LoadingComponent.defaultProps = {
  className: "",
};

export default LoadingComponent;
