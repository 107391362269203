import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import { leaveMediation } from "../../actions/mediationUsers.actions";
import {
  closeMediation,
  updateMediation,
} from "../../actions/mediation.actions";
import CloseComponent from "../../components/CloseComponent";
import CreateComponent from "../../components/CreateComponent";
import CustomCard from "../../components/CustomCard";
import DashboardEdit from "../../components/DashboardEdit";
import NextMeetingComponent from "../../components/NextMeetingComponent";
import NextPaymentComponent from "../../components/NextPaymentComponent";
import { ROUTES } from "../../constants/routes";
import { useCounterpart } from "../../context/language.context";
import { getInfoUser, isMediationAdmin } from "../../helpers/utils";
import ConfirmDialog from "../../components/ConfirmDialog";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import { useStyles } from "./style";

const validationSchema = yup.object({
  title: yup.string().trim().required("common.title-required"),
});

const Dashboard = () => {
  const { counterpart } = useCounterpart();
  const { mediationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { mediation } = useSelector((state) => state.mediation);
  const { meetings } = useSelector((state) => state.mediationMeetings);
  const { payments } = useSelector((state) => state.mediationPayments);
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const userInfo = getInfoUser(mediation, mediationUsers);
  const isMediationDisabled = Boolean(mediation.endDate);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);

  const initialValues = {
    title: mediation && mediation.title ? mediation.title : "",
    description:
      mediation && mediation.description ? mediation.description : "",
  };

  const handleEditMediation = async (values) => {
    await dispatch(
      updateMediation({ mediation: { ...mediation, ...values }, mediationId })
    );
    setShowEdit(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleEditMediation,
  });

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toggleCloseModal = () => {
    setAnchorEl(null);
    setIsCloseModalOpen(!isCloseModalOpen);
  };

  const toggleLeaveModal = () => {
    setAnchorEl(null);
    setIsLeaveModalOpen(!isLeaveModalOpen);
  };

  const handleCloseReport = async () => {
    await dispatch(
      closeMediation({ mediationId: mediation.id, close: !mediation.endDate })
    );
    setIsCloseModalOpen(false);
  };

  const handleLeaveReport = async () => {
    const result = await dispatch(
      leaveMediation({ mediationId: mediation.id })
    );
    setIsLeaveModalOpen(false);

    if (result) {
      history.push(ROUTES.MEDIATIONS);
    }
  };

  const handleChangeTitle = (event) => {
    formik.setFieldValue("title", event.target.value);
  };

  const handleShowEdit = () => {
    setAnchorEl(null);
    setShowEdit(true);
  };

  const handleInitValue = () => {
    formik.setFieldValue("title", mediation.title);
    formik.setFieldValue("description", mediation.description);
    formik.setFieldTouched("title", false);
    setShowEdit(false);
  };

  return (
    <>
      <CustomCard
        title={formik.values.title}
        showMoreIcon
        handleOnClickIcon={handleShowMenu}
        showEdit={showEdit}
        labelTextField="Titre de la médiation"
        handleChangeTitle={handleChangeTitle}
        formik={formik}
        counterpart={counterpart}
        className={classes.header}
        shadow
      >
        {mediation && (
          <>
            <CreateComponent user={userInfo?.user} item={mediation} />
            {mediation.endDate && (
              <CloseComponent
                user={userInfo?.user}
                closeDate={mediation.endDate}
              />
            )}
          </>
        )}
        <DashboardEdit
          showEdit={showEdit}
          formik={formik}
          counterpart={counterpart}
          mediation={mediation}
          handleInitValue={handleInitValue}
        />
        <Box sx={{ display: "flex", columnGap: "1rem", marginTop: "1rem" }}>
          <Grid item md={6}>
            <NextMeetingComponent
              meetings={meetings
                ?.filter((m) => m.creationDate <= moment().format())
                ?.slice(0, 3)}
            />
          </Grid>
          <Grid item md={6}>
            <NextPaymentComponent
              payments={payments
                ?.filter((m) => m.creationDate <= moment().format())
                ?.slice(0, 3)}
            />
          </Grid>
        </Box>
      </CustomCard>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={handleShowEdit}
            disabled={!isCurrentUserMediationAdmin || isMediationDisabled}
          >
            {counterpart("mediation.edit")}
          </MenuItem>
          <MenuItem
            onClick={toggleCloseModal}
            disabled={!isCurrentUserMediationAdmin}
          >
            {counterpart(
              mediation.endDate
                ? "mediation.dashboard.open-mediation"
                : "mediation.dashboard.close-mediation"
            )}
          </MenuItem>
          <MenuItem onClick={toggleLeaveModal} disabled={isMediationDisabled}>
            {counterpart("mediation.dashboard.leave-mediation")}
          </MenuItem>
        </Menu>
      )}
      {isCloseModalOpen && (
        <ConfirmDialog
          title={counterpart(
            mediation.endDate
              ? "mediation.dashboard.open-mediation"
              : "mediation.dashboard.close-mediation"
          )}
          confirmText={counterpart(
            mediation.endDate
              ? "mediation.dashboard.confirm-open"
              : "mediation.dashboard.confirm-close"
          )}
          toggleRemoveModal={toggleCloseModal}
          isOpen={isCloseModalOpen}
          handleRemove={handleCloseReport}
          acceptLabel={counterpart(
            mediation.endDate
              ? "mediation.dashboard.open"
              : "mediation.dashboard.close"
          )}
        />
      )}
      {isLeaveModalOpen && (
        <ConfirmDialog
          title={counterpart("mediation.dashboard.leave-mediation")}
          confirmText={counterpart("mediation.dashboard.confirm-leave")}
          toggleRemoveModal={toggleLeaveModal}
          isOpen={isLeaveModalOpen}
          handleRemove={handleLeaveReport}
          acceptLabel={counterpart("mediation.dashboard.leave")}
        />
      )}
    </>
  );
};

export default Dashboard;
