import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationTeamsService {
  async getMediationTeams({ mediationId }) {
    const parameters = new URLSearchParams({
      mediationId,
    });
    return axios
      .get(`${ENDPOINTS.MEDIATION_TEAM}?${parameters}`)
      .then(handleResponse);
  }

  async addMediationTeam({ mediationId, title, description }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_TEAM}`, {
        mediationId,
        title,
        description,
      })
      .then(handleResponse);
  }

  async updateMediationTeam({ mediationId, id, title, description }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_TEAM}/${id}`, {
        mediationId,
        title,
        description,
      })
      .then(handleResponse);
  }

  async deleteMediationTeam({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_TEAM}/${id}`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }
}

export const mediationTeamsService = new MediationTeamsService();
