import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { inviteUser } from "../../actions/mediationInvitations.actions";
import {
  addMediationTeam,
  deleteMediationTeam,
  updateMediationTeam,
} from "../../actions/mediationTeams.actions";
import { getMediationUsers } from "../../actions/mediationUsers.actions";
import CustomCard from "../../components/CustomCard";
import ListDialog from "../../components/ListDialog";
import ListMenu from "../../components/ListMenu";
import UserTeamCard from "../../components/UserTeamCard";
import InvitationTeamCard from "../../components/InvitationTeamCard";
import { useCounterpart } from "../../context/language.context";
import { isMediationAdmin } from "../../helpers/utils";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import { useStyles } from "./style";
import { useSession } from "../../context/session.context";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("connection.email.invalid")
    .required("connection.email.required"),
});

const validationTeamSchema = yup.object({
  title: yup.string().trim().required("common.title-required"),
});

const Members = () => {
  const { counterpart } = useCounterpart();
  const { mediationId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { mediation } = useSelector((state) => state.mediation);
  const { user } = useSession();
  const mediationUsers = useSelector((state) =>
    state.mediationUsers?.filter(
      (mu) => !mu.deletionDate && !mu.user.deletionDate
    )
  );
  const mediationInvitations = useSelector(
    (state) => state.mediationInvitations
  );
  const mediationTeams = useSelector((state) => state.mediationTeams);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTeamOpen, setIsModalTeamOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTeam, setAnchorElTeam] = useState(null);
  const [teamSelectioned, setTeamSelectioned] = useState(null);
  const [userSelectioned, setUserSelectioned] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMediationDisabled = Boolean(mediation?.endDate);
  const mediationUsersGrouped =
    mediationUsers &&
    _.groupBy(mediationUsers, (mediationUser) => mediationUser.mediationTeamId);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);

  const initialValues = {
    email: "",
  };
  const initialTeamValues = {
    title: "",
    description: "",
  };

  const inviteUserAction = async (values) => {
    setIsLoading(true);
    const handleInviteUser = dispatch(inviteUser({ ...values, mediationId }));
    await Promise.all([handleInviteUser]).finally(() => setIsLoading(false));
    setIsModalOpen(false);
  };

  const handleAddTeam = async (values) => {
    if (teamSelectioned) {
      await dispatch(
        updateMediationTeam({ ...values, id: teamSelectioned.id, mediationId })
      );
      setTeamSelectioned(null);
    } else {
      await dispatch(addMediationTeam({ ...values, mediationId }));
    }
    setIsModalTeamOpen(false);
  };

  const handleRemoveTeam = async () => {
    await dispatch(deleteMediationTeam({ id: teamSelectioned.id }));
    setTeamSelectioned(null);
    setIsRemoveModalOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: inviteUserAction,
  });

  const formikTeam = useFormik({
    initialValues: initialTeamValues,
    validationSchema: validationTeamSchema,
    onSubmit: handleAddTeam,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setAnchorEl(null);
    formik.setFieldValue("email", "");
    formik.setFieldTouched("email", false);
  };

  const toggleModalTeam = () => {
    setIsModalTeamOpen(!isModalTeamOpen);
    setAnchorEl(null);
    setAnchorElTeam(null);
    formikTeam.setFieldValue("title", teamSelectioned?.title || "");
    formikTeam.setFieldTouched("title", false);
    formikTeam.setFieldValue("description", teamSelectioned?.description || "");
  };

  const toggleRemoveModal = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
    setAnchorElTeam(null);
  };

  const renderModal = (formikValue, values) => (
    <Grid container spacing={2} mt={1}>
      {values.map((item) => (
        <Grid key={item.title} item md={12}>
          <TextField
            id={item.title}
            name={item.value}
            label={item.title}
            type={item.type}
            variant="outlined"
            placeholder={item.title}
            value={formikValue.values[item.value]}
            onChange={formikValue.handleChange}
            error={
              formikValue.touched[item.value] &&
              Boolean(formikValue.errors[item.value])
            }
            helperText={
              formikValue.touched[item.value] &&
              formikValue.errors[item.value] &&
              counterpart(formikValue.errors[item.value])
            }
            fullWidth
          />
        </Grid>
      ))}
    </Grid>
  );

  const renderRemoveModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {counterpart("mediation.confirm-delete-team")}
      </Typography>
    </Grid>
  );

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShowMenuTeam = (event) => {
    setAnchorElTeam(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorElTeam(null);
    setTeamSelectioned(null);
  };

  useEffect(async () => {
    await dispatch(getMediationUsers({ mediationId }));
  }, [mediationTeams]);

  return (
    <>
      <>
        <CustomCard
          title={`${mediationUsers?.length || 0} ${counterpart(
            "mediation.members.title"
          )}`}
          handleOnClickIcon={handleShowMenu}
          showMoreIcon={isCurrentUserMediationAdmin}
          className={classes.header}
          paddingTop
        >
          {mediationTeams?.map((item) => (
            <UserTeamCard
              team={item}
              key={item.id}
              mediationUsers={mediationUsersGrouped[item.id]}
              mediationTeams={mediationTeams}
              handleClickMenu={handleShowMenuTeam}
              setTeamSelectioned={setTeamSelectioned}
              userSelectioned={userSelectioned}
              setUserSelectioned={setUserSelectioned}
              dispatch={dispatch}
              disabled={isMediationDisabled}
              showMenu
              isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
              user={user}
              isMenuOpen={Boolean(anchorElTeam)}
            />
          ))}
          {mediationUsersGrouped?.null?.length > 0 && (
            <UserTeamCard
              team={{ title: counterpart("mediation.members.invited") }}
              mediationUsers={mediationUsersGrouped.null}
              mediationTeams={mediationTeams}
              showMenu={false}
              userSelectioned={userSelectioned}
              setUserSelectioned={setUserSelectioned}
              dispatch={dispatch}
              isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
              disabled={isMediationDisabled}
              user={user}
            />
          )}
          {mediationInvitations?.length > 0 && (
            <InvitationTeamCard
              team={{ title: counterpart("mediation.invitations.invitations") }}
              mediationInvitations={mediationInvitations}
              showMenu={false}
              mediationId={mediationId}
              isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
              disabled={isMediationDisabled}
            />
          )}
        </CustomCard>
        {anchorEl && isCurrentUserMediationAdmin && (
          <ListMenu
            menus={[
              {
                type: "menu",
                handleClick: toggleModal,
                label: counterpart("mediation.members.invit-participant"),
                disabled: isMediationDisabled,
              },
              {
                type: "menu",
                handleClick: toggleModalTeam,
                label: counterpart("mediation.members.add-team"),
                disabled: isMediationDisabled,
              },
            ]}
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
          />
        )}
        {anchorElTeam && (
          <ListMenu
            menus={[
              {
                type: "menu",
                handleClick: toggleModalTeam,
                label: counterpart("common.edit"),
                disabled: isMediationDisabled,
              },
              {
                type: "menu",
                handleClick: toggleRemoveModal,
                label: counterpart("common.delete"),
                disabled:
                  teamSelectioned?.isMediatorTeam || isMediationDisabled,
              },
            ]}
            anchorEl={anchorElTeam}
            handleCloseMenu={handleCloseMenu}
          />
        )}
      </>
      {(isModalOpen || isModalTeamOpen || isRemoveModalOpen) && (
        <ListDialog
          dialogs={[
            {
              title: counterpart("mediation.members.invit-participant"),
              isOpen: isModalOpen,
              handleClose: toggleModal,
              acceptLabel: counterpart("mediation.members.invite"),
              handleAccept: formik.handleSubmit,
              isLoading,
              children: renderModal(formik, [
                {
                  title: "Email",
                  value: "email",
                  type: "email",
                },
              ]),
            },
            {
              title: counterpart("mediation.members.add-team"),
              isOpen: isModalTeamOpen,
              handleClose: toggleModalTeam,
              acceptLabel: counterpart("common.save"),
              handleAccept: formikTeam.handleSubmit,
              children: renderModal(formikTeam, [
                {
                  title: counterpart("common.title"),
                  value: "title",
                  type: "text",
                },
                {
                  title: counterpart("common.description"),
                  value: "description",
                  type: "text",
                },
              ]),
            },
            {
              title: counterpart("mediation.members.delete-team"),
              isOpen: isRemoveModalOpen,
              handleClose: toggleRemoveModal,
              acceptLabel: counterpart("common.delete"),
              handleAccept: handleRemoveTeam,
              children: renderRemoveModal(),
            },
          ]}
        />
      )}
    </>
  );
};

export default Members;
