import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { CustomSelect } from "../../pages/MediationPage/style";
import { ContentCkeditor, Error } from "./style";
import { editorConfiguration } from "../../helpers/ckEditorConfiguration";

const MediationEdit = ({
  params,
  formik,
  item,
  handleInitValue,
  counterpart,
  disabled,
}) => (
  <Grid container spacing={2}>
    {params.map((param) => {
      if (param.type === "ckeditor") {
        return (
          <ContentCkeditor
            key={param.name}
            item
            xs={12}
            error={Boolean(
              formik.touched[param.name] && Boolean(formik.errors[param.name])
            ).toString()}
          >
            <CKEditor
              editor={ClassicEditor}
              config={editorConfiguration}
              data={formik.values[param.name]}
              onChange={(event, editor) => {
                formik.setFieldValue(param.name, editor.getData());
              }}
            />
            {formik.touched[param.name] && formik.errors[param.name] && (
              <Error>{counterpart(formik.errors[param.name])}</Error>
            )}
          </ContentCkeditor>
        );
      }
      if (param.type === "select") {
        return (
          <Grid key={param.name} item xs={12}>
            <CustomSelect
              labelId={param.name}
              id={param.name}
              name={param.name}
              value={formik.values[param.name]}
              label={counterpart("mediation.payments.currency")}
              onChange={formik.handleChange}
            >
              {param.items.map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </CustomSelect>
          </Grid>
        );
      }
      return (
        <Grid key={param.name} item xs={12}>
          <TextField
            id={param.name}
            name={param.name}
            label={param.label}
            type={param.type || "text"}
            value={formik.values[param.name] || ""}
            onChange={formik.handleChange}
            error={
              formik.touched[param.name] && Boolean(formik.errors[param.name])
            }
            helperText={
              formik.touched[param.name] &&
              formik.errors[param.name] &&
              counterpart(formik.errors[param.name])
            }
            InputLabelProps={
              param.type === "date"
                ? {
                    shrink: true,
                  }
                : null
            }
            fullWidth
          />
        </Grid>
      );
    })}
    <Grid item xs={12}>
      <Button
        type="submit"
        variant="contained"
        onClick={formik.handleSubmit}
        sx={{ textTransform: "none" }}
        disabled={disabled}
      >
        {counterpart("common.save")}
      </Button>
      {item && (
        <Button
          variant="contained"
          sx={{ textTransform: "none", marginLeft: 1 }}
          onClick={handleInitValue}
        >
          {counterpart("common.cancel")}
        </Button>
      )}
    </Grid>
  </Grid>
);

MediationEdit.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formik: PropTypes.shape().isRequired,
  handleInitValue: PropTypes.func.isRequired,
  counterpart: PropTypes.func.isRequired,
  item: PropTypes.shape(),
  disabled: PropTypes.bool,
};

MediationEdit.defaultProps = {
  item: null,
  disabled: false,
};
export default MediationEdit;
