import Grid from "@mui/material/Grid";
import styled from "styled-components";

export const ContentCkeditor = styled(Grid)`
  .ck-content {
    min-height: 200px;
    border-color: ${({ error }) => error === "true" && "red !important"};
  }
`;

export const Error = styled.p`
  color: red;
  font-size: 0.75rem;
  padding-left: 12px;
`;
