import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationDocumentsService {
  async getMediationDocuments({ mediationId }) {
    const parameters = new URLSearchParams({
      mediationId,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION_DOCUMENT}?${parameters}`)
      .then(handleResponse);
  }

  async getMediationFile({ id }) {
    return axios
      .get(`${ENDPOINTS.MEDIATION_DOCUMENT}/${id}/file`)
      .then(handleResponse);
  }

  async addMediationFolder({ mediationId, name, parentId }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_DOCUMENT}/folder`, {
        mediationId,
        name,
        parentId,
      })
      .then(handleResponse);
  }

  async addMediationFile({
    mediationId,
    name,
    parentId,
    file,
    format,
    extension,
    size,
  }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_DOCUMENT}/file`, {
        mediationId,
        name,
        parentId,
        file,
        size,
        format,
        extension,
      })
      .then(handleResponse);
  }

  async updateMediationFolder({ id, name, parentId }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_DOCUMENT}/${id}/folder`, { name, parentId })
      .then(handleResponse);
  }

  async updateMediationFile({ id, name, parentId }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_DOCUMENT}/${id}/file`, { name, parentId })
      .then(handleResponse);
  }

  async deleteMediationFolder({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_DOCUMENT}/${id}/folder`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }

  async deleteMediationFile({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_DOCUMENT}/${id}/file`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }
}

export const mediationDocumentsService = new MediationDocumentsService();
