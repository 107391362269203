import React from "react";
import PropTypes from "prop-types";
import MediationEdit from "../MediationEdit";

const CGEdit = ({ formik, counterpart, mediation, handleInitValue }) => {
  const params = [
    {
      name: "specificTerms",
      type: "ckeditor",
    },
  ];
  return (
    <MediationEdit
      params={params}
      formik={formik}
      item={mediation}
      handleInitValue={handleInitValue}
      counterpart={counterpart}
    />
  );
};

CGEdit.propTypes = {
  formik: PropTypes.shape().isRequired,
  counterpart: PropTypes.func.isRequired,
  mediation: PropTypes.shape().isRequired,
  handleInitValue: PropTypes.func.isRequired,
};

export default CGEdit;
