import Typography from "@mui/material/Typography";
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { ContentLoading } from "./style";

const LoadingMediation = ({ mediation }) => (
  <ContentLoading>
    <Typography variant="h6" color="success">
      {mediation || "Mediation"}
    </Typography>
    <CircularProgress color="success" />
  </ContentLoading>
);

LoadingMediation.propTypes = {
  mediation: PropTypes.string,
};

LoadingMediation.defaultProps = {
  mediation: null,
};

export default LoadingMediation;
