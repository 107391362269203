/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ContentMenuTabs from "../ContentMenuTabs";
import { sendConnection } from "../../helpers/utils";
import { updateValueUserMediation } from "../../actions/mediationUsers.actions";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";

const MediationTool = () => {
  const { mediationId } = useParams();
  const dispatch = useDispatch();
  const mediationUser = useCurrentMediationUser();
  const connection = useSelector((state) => state.connection);

  // const [visible, setVisible] = useState(false);

  // const toggleMenu = () => {
  //   setVisible(!visible);
  // };

  useEffect(() => {
    try {
      if (connection) {
        sendConnection(
          "JoinMediation",
          {
            mediationId,
            mediationUserId: mediationUser.id,
          },
          connection
        );

        connection.on("JoinMediation", (value) => {
          if (value !== mediationUser.id) {
            sendConnection(
              "AddUserConnected",
              { mediationId, mediationUserId: mediationUser.id },
              connection
            );
          }
          dispatch(
            updateValueUserMediation({
              id: value,
              isConnected: true,
            })
          );
        });

        connection.on("LeaveMediation", (value) => {
          dispatch(
            updateValueUserMediation({
              id: value,
              isConnected: false,
            })
          );
        });

        connection.on("ReceiveAddUserConnected", (value) => {
          dispatch(
            updateValueUserMediation({
              id: value,
              isConnected: true,
            })
          );
        });
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        if (connection) {
          sendConnection("LeaveChatGroup", mediationId, connection);
          sendConnection(
            "LeaveMediation",
            { mediationId, mediationUserId: mediationUser.id },
            connection
          );
          connection.stop();
        }
      } catch (error) {
        console.log(error);
      }
    };
  }, [connection]);

  return (
    <Box sx={{ typography: "body1", width: "400px" }}>
      <ContentMenuTabs />
    </Box>
  );
};

export default MediationTool;
