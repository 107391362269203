/* eslint-disable */
const instance = require("counterpart");

const languages = ["fr", "en"];

languages.forEach((language) =>
  instance.registerTranslations(
    language,
    require(`../locales/${language}.json`)
  )
);

export default instance;
