import { createTheme } from "@mui/material/styles";
import { responsiveFontSizes } from "@mui/material";
import shadows from "./shadows";
import { light } from "./palette";

const fontFamily = [
  "'Outfit', sans-serif",
  "-apple-system",
  "BlinkMacSystemFont",
  "'Segoe UI'",
  "Roboto",
  "'Helvetica Neue'",
  "Arial",
  "'Noto Sans'",
  "sans-serif",
  "'Apple Color Emoji'",
  "'Segoe UI Emoji'",
  "'Segoe UI Symbol'",
  "'Noto Color Emoji'",
];

const theme = responsiveFontSizes(
  createTheme({
    palette: light,
    shadows: shadows(),
    typography: {
      fontFamily: `${fontFamily.join(",")}`,
      button: {
        textTransform: "none",
        fontWeight: "medium",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            "& th": {
              fontWeight: 600,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          label: {
            color: "white",
            fontWeight: 500,
          },
        },
      },
    },
  })
);
export default theme;
