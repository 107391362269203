import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import moment from "../../helpers/moment";
import CustomAvatar from "../CustomAvatar";
import { ContentDesc, ContentOptions, CustomBadge, CustomCard } from "./style";
import { useCounterpart } from "../../context/language.context";

const useStyles = makeStyles(() => ({
  avatar: {
    width: "30px",
    height: "30px",
    fontSize: "0.9rem",
  },
}));

const TimesheetComponent = ({
  timesheet,
  mediator,
  handleShowMenuItem,
  isCurrentUserMediationAdmin,
}) => {
  const classes = useStyles();
  const { locale } = useCounterpart();
  const { description, hour, date } = timesheet;
  const customDate = moment(new Date(date))
    .locale(locale)
    .format("DD MMM YYYY");
  return (
    <Box width={1} mb={1}>
      <CustomCard>
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContentDesc>
              <CustomAvatar
                aria-label="recipe"
                className={classes.avatar}
                user={mediator.user}
              />
              <Tooltip title={description} arrow>
                <Typography variant="body2">{description}</Typography>
              </Tooltip>
            </ContentDesc>
            <ContentOptions>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
                sx={{ width: "110px" }}
              >
                {customDate}
              </Typography>
              <CustomBadge badgeContent={`${hour}h`} />
              {isCurrentUserMediationAdmin && (
                <IconButton
                  sx={{ marginLeft: 1 }}
                  onClick={(event) => handleShowMenuItem(event, timesheet)}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </ContentOptions>
          </Box>
        </CardContent>
      </CustomCard>
    </Box>
  );
};

TimesheetComponent.propTypes = {
  timesheet: PropTypes.shape().isRequired,
  mediator: PropTypes.shape().isRequired,
  handleShowMenuItem: PropTypes.func.isRequired,
  isCurrentUserMediationAdmin: PropTypes.bool,
};

TimesheetComponent.defaultProps = {
  isCurrentUserMediationAdmin: false,
};

export default TimesheetComponent;
