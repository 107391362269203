/* eslint-disable default-param-last */
import { MEDIATION_TIMESHEETS } from "../constants/actions";

const initialState = null;

export function mediationTimesheets(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_TIMESHEETS.GET_ALL: {
      return action.payload;
    }
    case MEDIATION_TIMESHEETS.DELETE: {
      const items = state.filter((mi) => mi.id !== action.payload.id);
      return items;
    }
    case MEDIATION_TIMESHEETS.ADD: {
      return [...state, action.payload];
    }
    case MEDIATION_TIMESHEETS.UPDATE: {
      const items = state;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      items[index] = action.payload;
      return items;
    }
    default:
      return state;
  }
}
