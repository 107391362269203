/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import clsx from "clsx";
import {
  addMediationTimesheet,
  deleteMediationTimesheet,
  getMediationTimesheets,
  updateMediationTimesheet,
} from "../../actions/mediationTimesheets.actions";
import CustomCardComponent from "../../components/CustomCard";
import CustomMenu from "../../components/CustomMenu";
import Dialog from "../../components/Dialog";
import { Mandatory } from "../../components/Mandatory";
import TimesheetComponent from "../../components/TimesheetComponent";
import { useCounterpart } from "../../context/language.context";
import moment from "../../helpers/moment";
import {
  getTotalMediatorTimeSheet,
  isMediationAdmin,
} from "../../helpers/utils";
import { CustomBox, useStyles, CustomCard } from "./style";
import TotalMediatorTimeSheet from "../../components/TotalMediatorTimeSheet";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import { useSession } from "../../context/session.context";

const validationSchema = yup.object({
  date: yup.date().required("mediation.date-required"),
  description: yup.string().required("mediation.description-required"),
  hour: yup
    .number()
    .max(99, ("mediation.value-max", { value: 99 }))
    .required("mediation.time-required"),
});

export const Timesheet = () => {
  const { counterpart } = useCounterpart();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { mediationId } = useParams();
  const { mediation } = useSelector((state) => state.mediation);
  const user = useSession();
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const mediationTimesheets = useSelector((state) => state.mediationTimesheets);
  const isTimesheetEmpty =
    !mediationTimesheets || mediationTimesheets?.length === 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorItemEl, setAnchorItemEl] = useState(null);
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [titleModal, setTitleModal] = useState(
    counterpart("mediation.timesheets.add")
  );
  const isMediationDisabled = Boolean(mediation.endDate);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);

  const totalTimesheetMediators =
    getTotalMediatorTimeSheet(mediationTimesheets);

  const initialValues = {
    mediationId,
    date: new Date(),
    hour: 0,
    description: "",
  };

  const fetchMediationPayment = useCallback(
    async ({ mediationId: mediationIdArg }) => {
      await dispatch(getMediationTimesheets({ mediationId: mediationIdArg }));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchMediationPayment({ mediationId });
  }, [fetchMediationPayment, mediationId]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setAnchorItemEl(null);
    setAnchorEl(null);
  };

  const toggleRemoveModal = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
    setAnchorItemEl(null);
    setAnchorEl(null);
  };

  const handleShowMenu = (event) => {
    setPaymentSelected(null);
    setTitleModal(counterpart("mediation.timesheets.add"));
    formik.setFieldValue("date", new Date());
    formik.setFieldValue("hour", 0);
    formik.setFieldValue("description", "");
    setAnchorEl(event.currentTarget);
  };

  const handleShowMenuItem = (event, paymentValueSelected) => {
    setPaymentSelected(paymentValueSelected);
    setTitleModal(counterpart("mediation.timesheets.edit"));
    formik.setFieldValue("date", paymentValueSelected.date);
    formik.setFieldValue("hour", paymentValueSelected.hour);
    formik.setFieldValue("description", paymentValueSelected.description);
    setAnchorItemEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorItemEl(null);
  };

  const handleAdd = async (values) => {
    if (paymentSelected) {
      await dispatch(
        updateMediationTimesheet({
          mediationId,
          id: paymentSelected.id,
          ...values,
        })
      );
    } else {
      await dispatch(addMediationTimesheet(values));
    }
    toggleModal();
  };

  const handleRemove = async () => {
    await dispatch(deleteMediationTimesheet({ id: paymentSelected.id }));
    toggleRemoveModal();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleAdd,
  });

  const renderModal = () => (
    <Grid container>
      <Box width={1} marginBottom={1}>
        <Typography marginBottom={1}>
          Date <Mandatory />
        </Typography>
        <TextField
          id="date"
          type="date"
          variant="outlined"
          placeholder="Date"
          value={moment(formik.values.date).format("YYYY-MM-DD")}
          onChange={formik.handleChange}
          error={formik.touched.date && Boolean(formik.errors.date)}
          helperText={
            formik.touched.date &&
            formik.errors.date &&
            counterpart(formik.errors.date)
          }
          fullWidth
        />
      </Box>
      <Box width={1} marginBottom={1}>
        <Typography marginBottom={1}>
          {counterpart("mediation.timesheets.hour")} <Mandatory />
        </Typography>
        <TextField
          id="hour"
          type="number"
          variant="outlined"
          placeholder="Temps"
          value={formik.values.hour}
          onChange={formik.handleChange}
          error={formik.touched.hour && Boolean(formik.errors.hour)}
          helperText={
            formik.touched.hour &&
            formik.errors.hour &&
            counterpart(formik.errors.hour)
          }
          fullWidth
        />
      </Box>
      <Box width={1} marginBottom={1}>
        <Typography marginBottom={1}>
          {counterpart("common.description")} <Mandatory />
        </Typography>
        <TextField
          id="description"
          type="text"
          variant="outlined"
          placeholder={counterpart("common.description")}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={
            formik.touched.description &&
            formik.errors.description &&
            counterpart(formik.errors.description)
          }
          fullWidth
        />
      </Box>
    </Grid>
  );

  const renderRemoveModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {counterpart("mediation.timesheets.confirm-delete")}
      </Typography>
    </Grid>
  );

  return (
    <>
      <CustomCardComponent
        title={counterpart("mediation.timesheets.title")}
        handleOnClickIcon={handleShowMenu}
        showMoreIcon={isCurrentUserMediationAdmin}
        shadow
        className={clsx(
          classes.customContent,
          isTimesheetEmpty && classes.customCard,
          classes.header
        )}
      >
        <CustomBox
          height={!isTimesheetEmpty && 1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {!isTimesheetEmpty && (
            <Box mb={2}>
              <CustomCard>
                <Typography variant="subtitle1" pl={2} pt={1}>
                  Total :
                </Typography>
                {Object.keys(totalTimesheetMediators).map((timeSheet) => (
                  <TotalMediatorTimeSheet
                    key={timeSheet}
                    idMediationUser={timeSheet}
                    hours={totalTimesheetMediators[timeSheet]}
                    mediationUsers={mediationUsers}
                  />
                ))}
              </CustomCard>
            </Box>
          )}
          <Box>
            {!isTimesheetEmpty ? (
              mediationTimesheets?.map((timesheet) => {
                const mediator = mediationUsers.find(
                  (userItem) => userItem.id === timesheet.mediationUserId
                );
                return (
                  <TimesheetComponent
                    key={timesheet.id}
                    timesheet={timesheet}
                    handleShowMenuItem={handleShowMenuItem}
                    mediator={mediator}
                    isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
                  />
                );
              })
            ) : (
              <Typography variant="subtitle1" color="gray">
                {counterpart("mediation.timesheets.empty")}
              </Typography>
            )}
          </Box>
          <CustomMenu
            anchorEl={anchorItemEl}
            handleCloseMenu={handleCloseMenu}
            width="auto"
          >
            <MenuItem onClick={toggleModal} disabled={isMediationDisabled}>
              {counterpart("common.edit")}
            </MenuItem>
            <MenuItem
              onClick={toggleRemoveModal}
              disabled={isMediationDisabled}
            >
              {counterpart("common.delete")}
            </MenuItem>
          </CustomMenu>
        </CustomBox>
      </CustomCardComponent>
      <CustomMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        width="auto"
      >
        <MenuItem onClick={toggleModal} disabled={isMediationDisabled}>
          {counterpart("mediation.timesheets.add")}
        </MenuItem>
      </CustomMenu>
      <Dialog
        title={titleModal}
        open={isModalOpen}
        handleClose={toggleModal}
        acceptLabel={counterpart("common.save")}
        onAccept={formik.handleSubmit}
        width="md"
      >
        {renderModal()}
      </Dialog>
      <Dialog
        title={counterpart("mediation.timesheets.title-delete")}
        open={isRemoveModalOpen}
        handleClose={toggleRemoveModal}
        acceptLabel={counterpart("common.delete")}
        onAccept={handleRemove}
        width="md"
      >
        {renderRemoveModal()}
      </Dialog>
    </>
  );
};
