import { ENDPOINTS } from "../constants/endpoints";
import { ROUTES, SUBROUTES } from "../constants/routes";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationPaymentsService {
  async getMediationPayments({ mediationId }) {
    const parameters = new URLSearchParams({
      mediationId,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION_PAYMENT}?${parameters}`)
      .then(handleResponse);
  }

  async getMediationPayment({ id }) {
    return axios
      .get(`${ENDPOINTS.MEDIATION_PAYMENT}/${id}`)
      .then(handleResponse);
  }

  async addMediationPayment(
    {
      mediationId,
      title,
      description,
      amount,
      currency,
      limitDate,
      paymentDate,
    },
    history
  ) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_PAYMENT}`, {
        mediationId,
        title,
        description,
        amount,
        currency,
        limitDate,
        paymentDate,
      })
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        history.push(
          `${ROUTES.MEDIATION}${mediationId}${SUBROUTES.PAYMENTS}/${responseData.id}`
        );
        return response;
      });
  }

  async updateMediationPayment({
    mediationId,
    id,
    title,
    description,
    amount,
    currency,
    limitDate,
    paymentDate,
    mediationPaymentUserIds,
  }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_PAYMENT}/${id}`, {
        mediationId,
        title,
        description,
        amount,
        currency,
        limitDate,
        paymentDate,
        mediationUserIds: mediationPaymentUserIds,
      })
      .then(handleResponse);
  }

  async deleteMediationPayment({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_PAYMENT}/${id}`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }
}

export const mediationPaymentsService = new MediationPaymentsService();
