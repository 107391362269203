import { Card } from "@mui/material";
import styled from "styled-components";

export const MenuCard = styled(Card)`
  overflow: auto !important;

  word-wrap: break-word;
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  min-width: 0;
  border-right: 1px solid rgb(229, 234, 242);
  background: #f8f8f8 !important;
  border-radius: 0px !important;
  width: 325px;
  min-width: 325px;
  z-index: 5;
  height: 100%;

  @media (max-width: 576px) {
    margin-right: 0px;
    width: auto;
  }
`;

export const MenuTitle = styled.div`
  padding: 0.8rem;
  letter-spacing: 0.08em;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  line-height: 1.55;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  color: #506690;
  a {
    text-decoration: none;
    color: #506690;
  }

  a:hover,
  a:visited {
    color: #506690;
  }
`;

export const MenuItems = styled.ul`
  padding: 1rem;
  margin: 0px;
  color: #506690 !important;
  list-style-type: none;
  word-wrap: break-word;
  margin-bottom: 0px;
`;
export const MenuLabel = styled.span``;

export const Chevron = styled.i`
  display: none;
  @media (max-width: 576px) {
    display: ${({ visible }) => (visible ? "none" : "block")};
  }
`;

export const MenuItem = styled.li`
  margin-top: 0.5rem;
  color: #5396bc;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1rem;
  justify-content: space-between;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  a:hover,
  a:visited {
    color: #5396bc;
  }
`;
