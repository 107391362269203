import { MEDIATION_TEAMS } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationTeamsService } from "../services/mediationTeams.service";

export const getMediationTeams = (parameters) =>
  action(
    () => mediationTeamsService.getMediationTeams(parameters),
    MEDIATION_TEAMS.GET_ALL
  );

export const getMediationTeam = (parameters) =>
  action(
    () => mediationTeamsService.getMediationTeam(parameters),
    MEDIATION_TEAMS.GET
  );

export const addMediationTeam = (parameters) =>
  action(
    () => mediationTeamsService.addMediationTeam(parameters),
    MEDIATION_TEAMS.ADD,
    counterpart("message.add-success")
  );

export const updateMediationTeam = (parameters) =>
  action(
    () => mediationTeamsService.updateMediationTeam(parameters),
    MEDIATION_TEAMS.UPDATE,
    counterpart("message.edit-success")
  );

export const deleteMediationTeam = (parameters) =>
  action(
    () => mediationTeamsService.deleteMediationTeam(parameters),
    MEDIATION_TEAMS.DELETE,
    counterpart("message.delete-success")
  );
