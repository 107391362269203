import React from "react";
import AvatarGroup from "@mui/material/AvatarGroup";
import PropTypes from "prop-types";
import CustomAvatar from "../CustomAvatar";
import { Content } from "./style";

const CustomAvatarGroup = ({ avatarList, max, sx }) => (
  <Content component={AvatarGroup} max={max} justifyContent="flex-end" sx={sx}>
    {avatarList &&
      avatarList.map((item) => {
        if (!item) return null;

        const { user } = item;
        return <CustomAvatar user={user} key={user.id} />;
      })}
  </Content>
);

CustomAvatarGroup.propTypes = {
  avatarList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  max: PropTypes.number.isRequired,
  sx: PropTypes.shape(),
};

CustomAvatarGroup.defaultProps = {
  sx: null,
};

export default CustomAvatarGroup;
