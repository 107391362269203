/* eslint-disable default-param-last */
import { MEDIATION_CHAT } from "../constants/actions";

const initialState = {
  chatGroups: [],
  chatGroup: null,
};

export function mediationChat(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_CHAT.GET_GROUP: {
      return { ...state, chatGroup: action.payload };
    }
    case MEDIATION_CHAT.GET_ALL_GROUPS: {
      return { ...state, chatGroups: action.payload };
    }
    case MEDIATION_CHAT.ADD_GROUP: {
      const chatGroupsClone = [...state.chatGroups];
      const chatGroups = chatGroupsClone.some(
        (chat) => chat.id === action.payload.id
      )
        ? chatGroupsClone
        : [...chatGroupsClone, action.payload];
      const chatGroup =
        state.chatGroup?.id === action.payload.id
          ? action.payload
          : state.chatGroup;
      return { chatGroup, chatGroups };
    }
    case MEDIATION_CHAT.UPDATE_GROUP: {
      const chatGroups = [...state.chatGroups];
      const index = chatGroups.findIndex(
        (chat) => chat.id === action.payload.id
      );
      chatGroups[index] =
        state.chatGroup?.id === action.payload.id
          ? { ...chatGroups[index], ...action.payload, isUnread: false }
          : action.payload;
      const chatGroup =
        state.chatGroup?.id === action.payload.id
          ? { ...state.chatGroup, ...action.payload }
          : state.chatGroup;
      return { chatGroup, chatGroups };
    }
    case MEDIATION_CHAT.REMOVE_GROUP: {
      const chatGroupsClone = [...state.chatGroups];
      const chatGroups = chatGroupsClone.filter(
        (chatGroup) => chatGroup.id !== action.payload.id
      );
      return { ...state, chatGroups };
    }
    case MEDIATION_CHAT.INIT: {
      return { ...state, chatGroup: null };
    }
    case MEDIATION_CHAT.UPDATE_READ: {
      const chatGroups = [...state.chatGroups];
      const index = chatGroups.findIndex(
        (chat) => chat.id === action.payload.id
      );
      chatGroups[index] = { ...chatGroups[index], isUnread: false };
      return { ...state, chatGroups };
    }
    case MEDIATION_CHAT.LEAVE: {
      const chatGroupsClone = [...state.chatGroups];
      const chatGroups = chatGroupsClone.filter(
        (chat) => chat.id !== action.payload.id
      );
      return {
        chatGroups,
        chatGroup: {
          ...state.chatGroup,
          mediationChatGroupUsers: action.payload.mediationChatGroupUsers,
        },
      };
    }
    default:
      return state;
  }
}
