import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import { useCounterpart } from "../../context/language.context";

const ConfirmDialog = ({
  title,
  confirmText,
  toggleRemoveModal,
  isOpen,
  handleRemove,
  acceptLabel,
}) => {
  const { counterpart } = useCounterpart();

  return (
    <Dialog
      title={title}
      open={isOpen}
      handleClose={toggleRemoveModal}
      acceptLabel={acceptLabel || counterpart("common.delete")}
      onAccept={handleRemove}
      width="md"
    >
      <Grid container>
        <Typography variant="subtitle1">{confirmText}</Typography>
      </Grid>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  toggleRemoveModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
  acceptLabel: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  acceptLabel: null,
};
export default ConfirmDialog;
