export const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "styles",
    "bold",
    "italic",
    "Link",
    "BulletedList",
    "NumberedList",
    "|",
    "outdent",
    "indent",
    "|",
    "blockquote",
    "insertTable",
    "undo",
    "redo",
  ],
};

export const editorConfigurationWithoutParagraph = {
  toolbar: [
    "styles",
    "bold",
    "italic",
    "Link",
    "BulletedList",
    "NumberedList",
    "|",
    "outdent",
    "indent",
    "|",
    "blockquote",
    "insertTable",
    "undo",
    "redo",
  ],
};
