import { MEDIATION_DATES } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationDatesService } from "../services/mediationDates.service";

export const getMediationDates = (parameters) =>
  action(
    () => mediationDatesService.getMediationDates(parameters),
    MEDIATION_DATES.GET_ALL
  );

export const setMediationDateAnswer = (parameters) =>
  action(
    () => mediationDatesService.setMediationDateAnswer(parameters),
    MEDIATION_DATES.SET_ANSWER
  );

export const addMediationDate = (parameters) =>
  action(
    () => mediationDatesService.addMediationDate(parameters),
    MEDIATION_DATES.ADD,
    counterpart("message.success")
  );
