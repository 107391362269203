/* eslint-disable default-param-last */
import { MEDIATION_DATES } from "../constants/actions";

const initialState = null;

export function mediationDates(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_DATES.GET_ALL: {
      return action.payload;
    }
    case MEDIATION_DATES.ADD: {
      return [...state, action.payload];
    }
    case MEDIATION_DATES.REMOVE: {
      const items = state.filter((mi) => mi.id !== action.payload.id);
      return items;
    }
    case MEDIATION_DATES.SET_ANSWER: {
      const items = state;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      items[index] = action.payload;
      return [...items];
    }
    default:
      return state;
  }
}
