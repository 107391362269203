import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  updateMediationFile,
  updateMediationFolder,
} from "../../actions/mediationDocuments.actions";
import { getMediationInfo } from "../../helpers/utils";
import { ContentDocument, ContentFlex, ContentText, CustomCard } from "./style";

const MediationDocumentItem = ({
  item,
  user,
  mediationUsers,
  onClick,
  icon,
  documentSelected,
  setDocumentSelected,
  isFile,
  handleShowMenuDocument,
  isMyDocument,
  setIsMyDocument,
  dispatch,
  isMenuOpen,
  isCurrentUserMediationAdmin,
}) => {
  const theme = useTheme();
  const [isOnDrag, setOnDrag] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const { name, mediationUserId } = item;

  const handleDrag = (event, value) => {
    event.preventDefault();
    setDocumentSelected(value);
    setIsMyDocument(
      isCurrentUserMediationAdmin ||
        getMediationInfo(mediationUsers, user.id)?.id === value.mediationUserId
    );
  };

  const handleDrop = async (event, value) => {
    if (isMyDocument && !value.isFile) {
      setOnDrag(false);
      if (documentSelected.isFile) {
        await dispatch(
          updateMediationFile({
            id: documentSelected.id,
            name: documentSelected.name,
            parentId: value.id,
          })
        );
      } else {
        await dispatch(
          updateMediationFolder({
            id: documentSelected.id,
            name: documentSelected.name,
            parentId: value.id,
          })
        );
      }
    }
  };

  const handleDragOver = (event) => {
    if (isMyDocument) {
      event.preventDefault();
      setOnDrag(true);
    }
  };

  const handleDragLeave = (event) => {
    if (isMyDocument) {
      event.preventDefault();
      setOnDrag(false);
    }
  };

  const handleShowMenu = (event) => {
    setAnchorEl(event);
    handleShowMenuDocument(event);
    setDocumentSelected({
      ...item,
      isFile,
      user: getMediationInfo(mediationUsers, mediationUserId)?.user,
    });
    setIsMyDocument(
      isCurrentUserMediationAdmin ||
        getMediationInfo(mediationUsers, user.id)?.id === mediationUserId
    );
  };

  useEffect(() => {
    if (!isMenuOpen) {
      setAnchorEl(null);
    }
  }, [isMenuOpen]);

  return (
    <CustomCard
      theme={theme}
      sx={{ background: isOnDrag && "rgb(128 128 128 / 15%)" }}
      onMouseOver={() => {
        setShowIcon(true);
      }}
      onMouseLeave={() => {
        setShowIcon(false);
      }}
    >
      <CardContent sx={{ padding: "6px" }}>
        <ContentFlex justifyContent="space-between">
          <ContentDocument
            onClick={onClick || undefined}
            onDrag={(event) => handleDrag(event, { ...item, isFile })}
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, { ...item, isFile })}
            onDragLeave={handleDragLeave}
            sx={{ height: "40px", width: "100%" }}
            draggable
          >
            {icon}
            <ContentText
              sx={{ marginLeft: "12px", fontSize: "0.8rem" }}
              variant="body2"
            >
              {name}
            </ContentText>
          </ContentDocument>
          {(showIcon || anchorEl) && (
            <ContentFlex>
              <IconButton sx={{ marginLeft: 1 }} onClick={handleShowMenu}>
                <MoreVertIcon />
              </IconButton>
            </ContentFlex>
          )}
        </ContentFlex>
      </CardContent>
    </CustomCard>
  );
};

MediationDocumentItem.propTypes = {
  item: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  mediationUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setDocumentSelected: PropTypes.func.isRequired,
  handleShowMenuDocument: PropTypes.func.isRequired,
  documentSelected: PropTypes.shape(),
  onClick: PropTypes.func,
  icon: PropTypes.node,
  isFile: PropTypes.bool,
  isMyDocument: PropTypes.bool,
  setIsMyDocument: PropTypes.func,
  dispatch: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  isCurrentUserMediationAdmin: PropTypes.bool,
};

MediationDocumentItem.defaultProps = {
  documentSelected: null,
  onClick: undefined,
  icon: null,
  isFile: false,
  isMyDocument: false,
  setIsMyDocument: null,
  dispatch: null,
  isMenuOpen: false,
  isCurrentUserMediationAdmin: false,
};

export default MediationDocumentItem;
