/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import { useHistory } from "react-router";
import { ROUTES } from "../../constants/routes";
import CustomAvatar from "../CustomAvatar";
import { useCounterpart } from "../../context/language.context";
import { sessionService } from "../../services/session.service";
import { getCookie } from "../../helpers/cookie";

const AvatarMenu = ({ user }) => {
  const { counterpart } = useCounterpart();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const routes = useMemo(
    () => [
      {
        title: "menus.home",
        to: `${process.env.REACT_APP_FRONT}/${getCookie("NEXT_LOCALE")}`,
        isOtherUrl: true,
      },
      {
        title: "menus.logout",
        to: ROUTES.LOGOUT,
      },
    ],
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickRoute = (route, isOtherUrl) => {
    if (isOtherUrl) {
      window.location.href = route;
      return null;
    }

    sessionService.logout().then(() => {
      history.push(ROUTES.LOGIN);
    });
    setAnchorEl(null);
  };
  return (
    user && (
      <>
        <IconButton
          onClick={(e) => {
            handleClick(e);
          }}
        >
          <CustomAvatar user={user} size="xs" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {routes.map((menu) => (
            <MenuItem
              key={menu.title}
              onClick={() => {
                handleClickRoute(menu.to, menu?.isOtherUrl);
                setAnchorEl(null);
              }}
              sx={{
                fontSize: "0.95rem",
                fontWeight: "500",
              }}
            >
              {counterpart(menu.title)}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
};

AvatarMenu.propTypes = {
  user: PropTypes.shape(),
};

AvatarMenu.defaultProps = {
  user: null,
};

export default AvatarMenu;
