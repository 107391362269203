import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import { useCounterpart } from "../../context/language.context";
import useMaxAvatar from "../../hooks/useMaxAvatar";
import { MediationDescription } from "../../pages/MediationPage/style";
import CreateComponent from "../CreateComponent";
import CustomAvatarGroup from "../CustomAvatarGroup";

const PaymentDetail = ({ payment, mediationUsers, showEdit }) => {
  const { counterpart, locale } = useCounterpart();
  const [users, setUsers] = useState(null);
  const ref = useRef(null);
  const maxAvatar = useMaxAvatar(ref);
  const mediationUser = mediationUsers.find(
    (item) => item.id === payment.mediationUserId
  );

  const getAllInfoPaymentUsers = useCallback(
    () =>
      payment.mediationPaymentUserIds
        .map((id) => mediationUsers.find((item) => item.id === id))
        .filter((mu) => !mu?.deletionDate),
    [payment, mediationUsers]
  );

  useEffect(() => {
    setUsers(getAllInfoPaymentUsers());
  }, [getAllInfoPaymentUsers]);

  return (
    <>
      <CreateComponent
        user={mediationUser && mediationUser.user ? mediationUser.user : null}
        item={payment}
      />
      <Box mb={2} mt={2} ref={ref}>
        {maxAvatar && users && (
          <CustomAvatarGroup avatarList={users} max={maxAvatar} />
        )}
      </Box>
      {!showEdit && (
        <>
          <MediationDescription
            dangerouslySetInnerHTML={{ __html: payment.description }}
          />
          <Typography variant="body2" fontWeight={800} mt={2}>
            {counterpart("mediation.payments.amount")}
            {": "}
            {payment.amount} {payment.currency}
          </Typography>
          <Typography variant="body2" fontWeight={800} mt={2}>
            {counterpart("mediation.payments.limit-date")}
            {": "}
            {moment(new Date(payment.limitDate))
              .locale(locale)
              .format("DD MMM YYYY")}
          </Typography>
          <Typography variant="body2" fontWeight={800} mt={2}>
            {counterpart("mediation.payments.payment-date")}
            {": "}
            {payment.paymentDate ? (
              <Typography variant="body2" as="span" color="green">
                {moment(new Date(payment.paymentDate))
                  .locale(locale)
                  .format("DD MMM YYYY")}
              </Typography>
            ) : (
              <Typography variant="body2" as="span" color="red">
                {counterpart("mediation.payments.no-paid")}
              </Typography>
            )}
          </Typography>
        </>
      )}
    </>
  );
};

PaymentDetail.propTypes = {
  payment: PropTypes.shape().isRequired,
  mediationUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showEdit: PropTypes.bool,
};

PaymentDetail.defaultProps = {
  showEdit: false,
};

export default PaymentDetail;
