import React from "react";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ContentMessage } from "./style";
import { useCounterpart } from "../../context/language.context";
import moment from "../../helpers/moment";

const ChatMessageNotification = ({ message }) => {
  const { counterpart, locale } = useCounterpart();
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const mediationUser = mediationUsers.find(
    (mu) => mu.id === message.mediationUserId
  );
  const { user } = mediationUser;

  return (
    <ContentMessage>
      <Typography>
        {message.type === "notification-enter"
          ? counterpart("chat.joined", {
              value: user.firstName,
              date: locale
                ? moment(message.creationDate)
                    .locale(locale)
                    .format("DD MMM YYYY hh:mm")
                : "",
            })
          : counterpart("chat.leave-desc", {
              value: user.firstName,
              date: locale
                ? moment(message.deletionDat)
                    .locale(locale)
                    .format("DD MMM YYYY hh:mm")
                : "",
            })}
      </Typography>
    </ContentMessage>
  );
};

ChatMessageNotification.propTypes = {
  message: PropTypes.shape().isRequired,
};

export default ChatMessageNotification;
