import styled from "@emotion/styled";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { theme } from "../../../helpers/theme";

export const ContentLogo = styled(Box)`
  flex-grow: 1;
  cursor: default;
  display: flex;
  align-items: center;
  h5 {
    font-weight: 600;
    margin-left: 4px;
  }
  a {
    align-items: center;
    display: flex;
    text-decoration: none;
    color: ${theme.blue};
    width: fit-content;
  }
`;

export const CustomAppBar = styled(AppBar)`
  background-color: ${theme.blackPearl} !important;
  color: ${theme.white} !important;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1000 !important;
  .burger {
    font-size: 38px;
  }
  box-shadow: none;
`;

export const CustomToolbar = styled(Toolbar)`
  padding-left: 10px !important;
  padding-right: 10px !important;

  @media (max-width: 576px) {
    padding-left: 10px !important;
  }
`;

export const CustomSelect = styled(Select)`
  margin-top: 5px;
  fieldset {
    border: 0;
  }
  img {
    height: 13px !important;
    width: 20px !important;
  }
  svg {
    color: white !important;
  }
  .MuiSelect-select {
    padding: 0px 30px 0px 0px !important;
  }
  .MuiPaper-root {
    background-color: ${theme.blackPearl} !important;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  img {
    height: 13px !important;
    width: 20px !important;
  }
`;

export const ContentMenu = styled(Box)`
  display: flex;
  margin-left: 16px;
  flex-grow: 1;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const CustomMenu = styled(Menu)``;
