import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const CustomCard = styled(Card)((props) => ({
  textAlign: "center",
  "&.MuiPaper-root": {
    boxShadow: "none",
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: props.theme.palette.background.lightGrey,
    },
  },
  "& .MuiCardContent-root": {
    paddingTop: "4px",
    paddingBottom: "4px !important",
    "& i": {
      fontSize: "30px",
      color: props.theme.palette.primary.main,
    },
  },
}));

export const ContentText = styled(Typography)({
  textOverflow: "ellipsis",
  overflow: "hidden",
});

export const ContentFlex = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const ContentDocument = styled(Box)({
  display: "flex",
  alignItems: "center",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});
