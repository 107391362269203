import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardHeader from "@mui/material/CardHeader";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  removeMediationUser,
  updateUserMediation,
} from "../../actions/mediationUsers.actions";
import { useCounterpart } from "../../context/language.context";
import CustomAvatar from "../CustomAvatar";
import CustomMenu from "../CustomMenu";
import Dialog from "../Dialog";
import { Crown, CustomCard } from "./style";

const UserMediationItem = (props) => {
  const { counterpart } = useCounterpart();
  const {
    mediationUser,
    mediationTeams,
    sessionUser,
    disabled,
    isCurrentUserMediationAdmin,
  } = props;
  const { id, user, description, mediationTeamId, isMediationAdmin } =
    mediationUser;
  const dispatch = useDispatch();

  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [newDescription, setNewDescription] = useState(description ?? "");
  const [newIsMediationAdmin, setNewIsMediationAdmin] =
    useState(isMediationAdmin);
  const [team, setTeam] = useState(!mediationTeamId ? 0 : mediationTeamId);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showIcon, setShowIcon] = useState(false);

  const isCurrentUser = mediationUser.user.id === sessionUser.id;

  const toggleModifyModal = () => {
    setIsModifyModalOpen(!isModifyModalOpen);
    setAnchorEl(null);
  };
  const toggleRemoveModal = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
    setAnchorEl(null);
  };

  const updateDescription = async () => {
    await dispatch(
      updateUserMediation({
        id,
        description: newDescription,
        isMediationAdmin: newIsMediationAdmin,
        mediationTeamId: team === 0 ? null : team,
      })
    );
    setIsModifyModalOpen(false);
  };

  const remove = async () => {
    await dispatch(
      removeMediationUser({
        id,
      })
    );
    setIsRemoveModalOpen(false);
  };

  const renderModifyModal = () => (
    <Grid container spacing={2} mt={1}>
      <Grid item md={12}>
        <TextField
          id="description"
          label={counterpart("common.description")}
          type="text"
          variant="outlined"
          placeholder={counterpart("common.description")}
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          fullWidth
        />
      </Grid>
      {mediationTeams && (
        <Grid item md={12}>
          <FormControl fullWidth>
            <InputLabel id="label-team">
              {counterpart("mediation.members.team")}
            </InputLabel>
            <Select
              labelId="label-team"
              id="team"
              value={team}
              label={counterpart("mediation.members.team")}
              onChange={(e) => setTeam(e.target.value)}
            >
              <MenuItem value={0}>
                {counterpart("mediation.members.invited")}
              </MenuItem>
              {mediationTeams.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {mediationUser.isMediationAdmin !== null && isCurrentUserMediationAdmin && (
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isCurrentUser}
                checked={newIsMediationAdmin}
                onChange={(e) => setNewIsMediationAdmin(e.target.checked)}
              />
            }
            label={counterpart("mediation.members.mediationAdmin")}
          />
        </Grid>
      )}
    </Grid>
  );

  const renderRemoveModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {counterpart("mediation.members.confirm-delete-participant", {
          user: `${user.firstName} ${user.lastName}`,
        })}
      </Typography>
    </Grid>
  );

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(
    () => () => {
      setNewDescription("");
      setTeam(null);
    },
    []
  );

  const title = !user.deletionDate
    ? `${user.firstName} ${user.lastName}`
    : counterpart("common.deleted-user");
  const desc = !user.deletionDate ? mediationUser.description : "";

  return (
    <>
      <CustomCard
        sx={{
          boxShadow: "none",
          display: "flex",
        }}
        onMouseOver={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <CardHeader
          avatar={
            <CustomAvatar
              user={user}
              showConnectionState={mediationUser?.isConnected || isCurrentUser}
            />
          }
          action={
            isCurrentUserMediationAdmin &&
            (showIcon || Boolean(anchorEl)) && (
              <IconButton aria-label="settings" onClick={handleShowMenu}>
                <MoreVertIcon />
              </IconButton>
            )
          }
          title={
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              {title}
              {isMediationAdmin && <Crown className="fas fa-crown" />}
            </Typography>
          }
          subheader={
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "0.75rem",
                lineHeight: "1.25",
                color: "#677788",
              }}
            >
              {desc}
            </Typography>
          }
          sx={{
            width: "100%",
            padding: "6px",
            "&:hover": {
              background: "rgb(240,240,240,0.5)",
            },
            "& .MuiCardHeader-action": {
              margin: "0px",
            },
          }}
        />
      </CustomCard>
      <CustomMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        width="auto"
      >
        <MenuItem onClick={toggleModifyModal} disabled={disabled}>
          {counterpart("mediation.members.user-edit")}
        </MenuItem>
        {!isCurrentUser && (
          <MenuItem onClick={toggleRemoveModal} disabled={disabled}>
            {counterpart("mediation.members.delete-participant")}
          </MenuItem>
        )}
      </CustomMenu>
      <Dialog
        title={counterpart("mediation.members.user-edit")}
        open={isModifyModalOpen}
        handleClose={toggleModifyModal}
        acceptLabel={counterpart("common.save")}
        onAccept={updateDescription}
        width="md"
      >
        {renderModifyModal()}
      </Dialog>
      <Dialog
        title={counterpart("mediation.members.delete-participant")}
        open={isRemoveModalOpen}
        handleClose={toggleRemoveModal}
        acceptLabel={counterpart("common.remove")}
        onAccept={remove}
        width="md"
      >
        {renderRemoveModal()}
      </Dialog>
    </>
  );
};

UserMediationItem.propTypes = {
  mediationUser: PropTypes.shape().isRequired,
  mediationTeams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sessionUser: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  isCurrentUserMediationAdmin: PropTypes.bool,
};

UserMediationItem.defaultProps = {
  disabled: false,
  isCurrentUserMediationAdmin: false,
};

export default UserMediationItem;
