/* eslint-disable no-nested-ternary */
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../helpers/theme";

export const MenuItems = styled.ul`
  color: #061a2a !important;
  list-style-type: none;
  word-wrap: break-word;
  padding-left: ${({ submenus }) => (submenus ? "10px" : "0px")};
`;

export const MenuItem = styled.li`
  margin-top: ${({ submenus }) => (submenus ? "0px" : "0.5rem")};
  display: flex;
  flex-wrap: nowrap;
  font-size: 1rem;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ active }) => (active ? theme.green : "transparent")};
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ submenus, active }) =>
    active ? "white" : submenus ? "#93a7bd" : "#5396bc"};
  svg {
    color: #061a2a;
  }

  a {
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #061a2a !important;
  }

  &:hover {
    background-color: ${({ submenus, active }) =>
      submenus ? (active ? theme.green : "rgba(0,0,0,.2)") : theme.green};
    color: ${({ submenus, active }) =>
      active ? "white" : submenus ? "#93a7bd" : "#73b6dc"} !important;
    svg {
      color: ${theme.green};
    }
  }

  a:visited {
    color: inherit;
  }
`;

export const CustomIconButton = styled(IconButton)`
  padding: 1px !important;
  &.Mui-disabled svg {
    color: rgba(0, 0, 0, 0.26);
  }
`;

export const MenuCount = styled(Typography)`
  margin-left: 6px;
  font-size: 0.75rem;
  border-radius: 50%;
  color: inherit;
  opacity: 50%;
`;

export const CustomLinkChildren = styled(Link)`
  font-size: 0.9rem;
  color: ${({ unread }) => unread && "white !important"};
  font-weight: ${({ unread }) => unread && 800};
  padding: 8px;

  a:visited {
    color: inherit;
  }
`;

export const TitleMenu = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 5px;
  button {
    width: 16px;
    margin-right: 4px;
  }
  a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
