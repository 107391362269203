import { useEffect, useState } from "react";

const handleMaxAvatar = (width) => Math.round((width * 29) / 1083);

export default function useMaxAvatar(ref) {
  const [max, setMax] = useState(null);

  useEffect(() => {
    function handleResize() {
      setMax(handleMaxAvatar(ref.current.offsetWidth));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  useEffect(() => {
    if (ref && ref.current && ref.current.offsetWidth) {
      setMax(handleMaxAvatar(ref.current.offsetWidth));
    }
  }, [ref]);

  return max;
}
