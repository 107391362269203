import React from "react";
import { Box, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ROUTES } from "../../constants/routes";
import { useCounterpart } from "../../context/language.context";
import moment from "../../helpers/moment";

const NextPaymentComponent = ({ payments }) => {
  const { counterpart, locale } = useCounterpart();

  return (
    <Card
      sx={{
        background: "rgb(240,240,240,0.5)",
        padding: "16px",
        boxShadow: "none",
        width: "auto",
        marginBottom: "8px",
        minHeight: "120px",
      }}
    >
      <Box sx={{ marginBottom: "8px" }}>
        <Typography variant="body" fontWeight="bold" color="#377dff">
          {counterpart("mediation.dashboard.next-payment")}
        </Typography>
      </Box>
      <Box>
        {payments?.length > 0 ? (
          payments.map((m) => {
            const dateFormatted = moment(new Date(m.creationDate))
              .locale(locale)
              .format("DD MMM YYYY");
            return (
              <Typography variant="body2" key={m.id}>
                <Link to={`${ROUTES.MEDIATION_PAYMENTS}/${m.id}`}>
                  {m.title}
                </Link>{" "}
                : {dateFormatted}
              </Typography>
            );
          })
        ) : (
          <Typography variant="body2">
            {counterpart("mediation.dashboard.no-next-payment")}
          </Typography>
        )}
      </Box>
    </Card>
  );
};

NextPaymentComponent.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default NextPaymentComponent;
