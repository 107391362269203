/* eslint-disable default-param-last */
import { MEDIATION_INVITATIONS } from "../constants/actions";

const initialState = null;

export function mediationInvitations(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_INVITATIONS.GET_ALL: {
      return action.payload;
    }
    case MEDIATION_INVITATIONS.INVITE: {
      const items = state;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      if (index === -1) {
        return [...state, action.payload];
      }

      items[index] = action.payload;
      return [...items];
    }
    case MEDIATION_INVITATIONS.REMOVE: {
      return state.filter((mi) => mi.id !== action.payload.id);
    }
    default:
      return state;
  }
}
