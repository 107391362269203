export const MEDIATION = {
  GET_ALL: "mediation.getAll",
  GET: "mediation.get",
  CREATE: "mediation.create",
  UPDATE: "mediation.update",
  CLOSE: "mediation.close",
};

export const SNACKBAR = {
  INIT: "snackbar.init",
  UPDATE: "snackbar.update",
};

export const MEDIATION_CHAT = {
  GET_ALL_GROUPS: "mediationChat.getAllGroup",
  GET_GROUP: "mediationChat.getGroup",
  ADD_GROUP: "mediationChat.addGroup",
  UPDATE_GROUP: "mediationChat.updateGroup",
  REMOVE_GROUP: "mediationChat.removeGroup",
  INIT: "mediationChat.init",
  UPDATE_READ: "mediationChat.updateRead",
  LEAVE: "mediationChat.leave",
};

export const MEDIATION_INVITATIONS = {
  INVITE: "mediationInvitations.invite",
  ANSWER: "mediationInvitations.answer",
  GET_ALL: "mediationInvitations.getAll",
  REMOVE: "mediationInvitations.remove",
};

export const MEDIATION_MEETINGS = {
  GET_ALL: "mediationMeetings.getAll",
  GET: "mediationMeetings.get",
  ADD: "mediationMeetings.add",
  UPDATE: "mediationMeetings.update",
  DELETE: "mediationMeetings.delete",
  INIT: "mediationMeetings.init",
  SELECT_DATE: "mediationMeetings.selectDate",
};

export const MEDIATION_PAYMENTS = {
  GET_ALL: "mediationPayments.getAll",
  GET: "mediationPayments.get",
  ADD: "mediationPayments.add",
  UPDATE: "mediationPayments.update",
  DELETE: "mediationPayments.delete",
  INIT: "mediationPayments.init",
};

export const MEDIATION_REPORTS = {
  GET_ALL: "mediationReports.getAll",
  GET: "mediationReports.get",
  ADD: "mediationReports.add",
  UPDATE: "mediationReports.update",
  DELETE: "mediationReports.delete",
  INIT: "mediationReports.init",
};

export const MEDIATION_TEAMS = {
  GET_ALL: "mediationTeams.getAll",
  GET: "mediationTeams.get",
  ADD: "mediationTeams.add",
  UPDATE: "mediationTeams.update",
  DELETE: "mediationTeams.delete",
};

export const MEDIATION_TIMESHEETS = {
  GET_ALL: "mediationTimesheets.getAll",
  ADD: "mediationTimesheets.add",
  UPDATE: "mediationTimesheets.update",
  DELETE: "mediationTimesheets.delete",
};

export const MEDIATION_USERS = {
  GET_ALL: "mediationUsers.getAll",
  ACCEPT_CG: "mediationUsers.acceptCg",
  REMOVE: "mediationUsers.remove",
  UPDATE: "mediationUsers.update",
  LEAVE: "mediationUsers.leave",
  INIT: "mediationUsers.init",
};

export const MEDIATION_CHAT_MESSAGE = {
  GET_ALL: "mediationChatMessage.getAll",
  ADD: "mediationChatMessage.add",
  INIT: "mediationChatMessage.init",
};

export const MEDIATION_DOCUMENTS = {
  GET_ALL: "mediationDocuments.getAll",
  BREADCRUMB_FOLDER: "mediationDocuments.breadcrumbFolder",
  DOWN_FOLDER: "mediationDocuments.downFolder",
  UP_FOLDER: "mediationDocuments.upFolder",
  ADD_FOLDER: "mediationDocuments.addFolder",
  REMOVE_FOLDER: "mediationDocuments.removeFolder",
  ADD_FILE: "mediationDocuments.addFile",
  GET_FILE: "mediationDocuments.addFile",
  REMOVE_FILE: "mediationDocuments.removeFile",
  UPDATE_FOLDER: "mediationDocuments.updateFolder",
  UPDATE_FILE: "mediationDocuments.updateFile",
};

export const MEDIATION_DATES = {
  GET_ALL: "mediationDates.getAll",
  SET_ANSWER: "mediationDates.setAnswer",
  ADD: "mediationDates.add",
  REMOVE: "mediationDates.remove",
};

export const CONNECTION = {
  ADD: "connection.add",
};

export const SESSION = {
  GET: "session.get",
};
