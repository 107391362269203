import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MediationEdit from "../MediationEdit";
import { CURRENCIES } from "../../constants/constants";

const PaymentEdit = ({ formik, counterpart, handleInitValue, disabled }) => {
  const { payment } = useSelector((state) => state.mediationPayments);
  const params = [
    {
      name: "description",
      type: "ckeditor",
    },
    {
      name: "amount",
      label: "Montant",
      type: "number",
    },
    {
      name: "currency",
      label: "Devise",
      type: "select",
      items: CURRENCIES,
    },
    {
      name: "limitDate",
      label: "Date limite de paiement",
      type: "date",
    },
    {
      name: "paymentDate",
      label: "Date du paiement",
      type: "date",
    },
  ];
  return (
    <MediationEdit
      params={params}
      formik={formik}
      item={payment}
      handleInitValue={handleInitValue}
      counterpart={counterpart}
      disabled={disabled}
    />
  );
};

PaymentEdit.propTypes = {
  formik: PropTypes.shape().isRequired,
  counterpart: PropTypes.func.isRequired,
  handleInitValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PaymentEdit.defaultProps = {
  disabled: false,
};

export default PaymentEdit;
