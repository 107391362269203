import { MEDIATION_TIMESHEETS } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationTimesheetsService } from "../services/mediationTimesheets.service";

export const getMediationTimesheets = (parameters) =>
  action(
    () => mediationTimesheetsService.getMediationTimesheets(parameters),
    MEDIATION_TIMESHEETS.GET_ALL
  );

export const addMediationTimesheet = (parameters) =>
  action(
    () => mediationTimesheetsService.addMediationTimesheet(parameters),
    MEDIATION_TIMESHEETS.ADD,
    counterpart("message.add-success")
  );

export const updateMediationTimesheet = (parameters) =>
  action(
    () => mediationTimesheetsService.updateMediationTimesheet(parameters),
    MEDIATION_TIMESHEETS.UPDATE,
    counterpart("message.edit-success")
  );

export const deleteMediationTimesheet = (parameters) =>
  action(
    () => mediationTimesheetsService.deleteMediationTimesheet(parameters),
    MEDIATION_TIMESHEETS.DELETE,
    counterpart("message.delete-success")
  );
