import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import styled from "styled-components";

export const CustomBox = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const CustomListItem = styled(ListItem)`
  .MuiListItemButton-root {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Crown = styled.i`
  margin-left: 5px;
  color: gold;
  font-size: 0.8rem;
`;
