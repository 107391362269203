/* eslint-disable default-param-last */
import { SNACKBAR } from "../constants/actions";

const initialState = {
  snackbar: { isOpen: false, variant: "success", message: "" },
};

export function snackbar(state = initialState, action) {
  switch (action.type) {
    case SNACKBAR.UPDATE: {
      return {
        ...state,
        snackbar: action.payload.snackbar,
      };
    }
    case SNACKBAR.INIT: {
      return {
        ...state,
        snackbar: initialState.snackbar,
      };
    }
    default:
      return state;
  }
}
