import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { CustomMenuItem, MenuContent } from "./style";

const CustomMenu = ({ items, anchorEl, handleCloseMenu, width, children }) => {
  const history = useHistory();

  const goToUrl = (url) => {
    history.push(url);
    handleCloseMenu();
  };

  return (
    <MenuContent
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      width={width}
    >
      {children ||
        items.map((mi) => (
          <CustomMenuItem key={mi.id} onClick={() => goToUrl(mi.url)}>
            {mi.label}
          </CustomMenuItem>
        ))}
    </MenuContent>
  );
};

CustomMenu.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape(),
  children: PropTypes.node,
  width: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape()),
};

CustomMenu.defaultProps = {
  anchorEl: null,
  children: null,
  width: "",
  items: null,
};

export default CustomMenu;
