import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MediationEdit from "../MediationEdit";

const MeetingEdit = ({ formik, counterpart, handleInitValue, disabled }) => {
  const { meeting } = useSelector((state) => state.mediationMeetings);
  const params = [
    {
      name: "description",
      type: "ckeditor",
    },
    {
      name: "address",
      label: "Adresse de la réunion",
    },
  ];
  return (
    <MediationEdit
      params={params}
      formik={formik}
      item={meeting}
      handleInitValue={handleInitValue}
      counterpart={counterpart}
      disabled={disabled}
    />
  );
};

MeetingEdit.propTypes = {
  formik: PropTypes.shape().isRequired,
  counterpart: PropTypes.func.isRequired,
  handleInitValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MeetingEdit.defaultProps = {
  disabled: false,
};

export default MeetingEdit;
