import React from "react";
import PropTypes from "prop-types";
import { Content } from "./style";

const MediationContent = ({ paddingTop, children }) => (
  <Content className="content" paddingTop={paddingTop}>
    {children}
  </Content>
);

MediationContent.propTypes = {
  children: PropTypes.node.isRequired,
  paddingTop: PropTypes.bool,
};

MediationContent.defaultProps = {
  paddingTop: false,
};

export default MediationContent;
