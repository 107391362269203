/* eslint-disable default-param-last */
import { MEDIATION_TEAMS } from "../constants/actions";

const initialState = null;

export function mediationTeams(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_TEAMS.GET_ALL: {
      return action.payload;
    }
    case MEDIATION_TEAMS.DELETE: {
      const items = state.filter((mi) => mi.id !== action.payload.id);
      return items;
    }
    case MEDIATION_TEAMS.ADD: {
      return [...state, action.payload];
    }
    case MEDIATION_TEAMS.UPDATE: {
      const items = state;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      items[index] = action.payload;
      return items;
    }
    default:
      return state;
  }
}
