/* eslint-disable no-unused-vars */
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import MediationContent from "../MediationContent";
import {
  CardContainer,
  CardHeader,
  CustomTextField,
  CustomTitle,
} from "./style";
import CustomAvatarGroup from "../CustomAvatarGroup";
import { NB_MAX_AVATAR } from "../../constants/constants";

const CustomCard = (props) => {
  const {
    children,
    title,
    showMoreIcon,
    handleOnClickIcon,
    className,
    showEdit,
    labelTextField,
    handleChangeTitle,
    formik,
    counterpart,
    paddingTop,
    borders,
    shadow,
    users,
    showButtonMenu,
    labelButtonMenu,
    onClickButtonMenu,
  } = props;

  return (
    <CardContainer className={className} borders={borders ? 1 : 0}>
      <CardHeader shadow={shadow ? 1 : 0} className="card-header">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {showEdit && (
            <CustomTextField
              id="title"
              name="title"
              variant="standard"
              placeholder={labelTextField}
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={
                formik.touched.title &&
                formik.errors.title &&
                counterpart(formik.errors.title)
              }
              fullWidth
            />
          )}
          {!showEdit && title && (
            <CustomTitle width={showButtonMenu ? 1 : "auto"}>
              <Typography>{title}</Typography>
            </CustomTitle>
          )}
          {showButtonMenu && (
            <Button variant="contained" onClick={onClickButtonMenu}>
              {labelButtonMenu}
            </Button>
          )}
          {!showEdit && (
            <Box display="flex" alignItems="center">
              {users && (
                <CustomAvatarGroup avatarList={users} max={NB_MAX_AVATAR} />
              )}
              {showMoreIcon && (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls="long-menu"
                  // aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleOnClickIcon}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </CardHeader>
      <MediationContent paddingTop={paddingTop}>{children}</MediationContent>
    </CardContainer>
  );
};

CustomCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  showMoreIcon: PropTypes.bool,
  handleOnClickIcon: PropTypes.func,
  className: PropTypes.string,
  showEdit: PropTypes.bool,
  labelTextField: PropTypes.string,
  handleChangeTitle: PropTypes.func,
  formik: PropTypes.shape(),
  counterpart: PropTypes.func,
  paddingTop: PropTypes.bool,
  borders: PropTypes.bool,
  shadow: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.shape()),
  showButtonMenu: PropTypes.bool,
  labelButtonMenu: PropTypes.string,
  onClickButtonMenu: PropTypes.func,
};

CustomCard.defaultProps = {
  showMoreIcon: false,
  handleOnClickIcon: null,
  className: "",
  showEdit: false,
  labelTextField: "",
  handleChangeTitle: null,
  formik: null,
  counterpart: null,
  paddingTop: false,
  borders: false,
  shadow: false,
  users: null,
  showButtonMenu: false,
  labelButtonMenu: null,
  onClickButtonMenu: null,
};

export default CustomCard;
