import Card from "@mui/material/Card";
import styled from "styled-components";
import { theme } from "../../helpers/theme";

export const Name = styled.p`
  margin-bottom: 0 !important;
  margin-top: 0px;
  font-size: ${({ small }) => (small ? "0.9" : "1.1")}rem;

  i {
    color: #506690;
    margin-left: 4px;
    cursor: pointer;
    font-size: 90%;
    padding: 2px;
  }
`;

export const Description = styled.small`
  font-size: 88.2%;
  margin-top: 5px;
  font-style: italic;

  ${({ invitationState }) =>
    invitationState === "INVITED" ? `color: #506690;` : ""}
  ${({ invitationState }) =>
    invitationState === "REFUSED" ? `color: ${theme.red};` : ""}
  ${({ invitationState }) =>
    invitationState === "ACCEPTED" ? `color: ${theme.green};` : ""}

  i {
    cursor: pointer;
    font-size: 120%;
    margin-left: 4px;
    color: ${theme.red};
  }

  @media (max-width: 576px) {
    ${({ responsive }) => (responsive ? "display: none;" : "")}
  }
`;

export const Picture = styled.div`
  font-size: 1.33333rem;
  height: ${({ small }) => (small ? "2" : "4")}rem;
  width: ${({ small }) => (small ? "2" : "4")}rem;
  display: inline-block;
  font-size: 0.625rem;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 50%;
    vertical-align: middle;
  }
`;

export const UserItemContainer = styled.div`
  align-items: center !important;
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: nowrap;

  div {
    flex-shrink: 0;
    max-width: 100%;
  }
`;

export const PictureContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: auto;
`;

export const NameContainer = styled.div`
  flex: 1 0 0%;
  position: relative;
  text-overflow: ellipsis;
  margin-left: 15px;

  @media (max-width: 576px) {
    ${({ responsive }) => (responsive ? "display: none;" : "")}
  }
`;

export const CustomCard = styled(Card)({
  "& .MuiCardHeader-content": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "& h6": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
});
