import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import styled from "styled-components";

export const Name = styled.p`
  margin-bottom: 0;
  margin-top: 0px;
  font-size: 1rem;
  display: inline-block;
`;

export const Description = styled.small`
  color: #506690;
  font-size: 88.2%;
  font-style: italic;
  display: inline-block;
  margin-left: 8px;
`;

export const ContentDesc = styled(Box)({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  "& .MuiTypography-root": {
    textAlign: "left",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingRight: 10,
    marginLeft: "10px !important",
    cursor: "default",
  },
});

export const ContentOptions = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .MuiTypography-root": {
    marginLeft: "10px",
    marginRight: "10px",
  },
});

export const Picture = styled.div`
  font-size: 1.33333rem;
  height: 2rem;
  width: 2rem;
  display: inline-block;
  font-size: 0.625rem;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 50%;
    vertical-align: middle;
  }
`;

export const UserItemContainer = styled.div`
  align-items: center !important;
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  div {
    flex-shrink: 0;
    max-width: 100%;
  }
`;

export const PictureContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: auto;
  margin-right: 15px;
  margin-bottom: auto;
`;

export const NameContainer = styled.div`
  flex: 1 0 0%;
  position: relative;
`;

export const Comment = styled.div`
  margin-top: 5px;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
`;

export const PaiementContainer = styled.div``;

export const CustomCard = styled(Card)({
  boxShadow: "none!important",
  border: "1px solid rgb(229,234,242)",
  height: "100%",
  "&:hover": {
    background: "rgb(240,240,240,0.5)",
  },
  "& .MuiCardHeader-content": {
    textAlign: "end",
    "& .MuiCardHeader-title": {
      fontWeight: 600,
      fontSize: "1.1rem",
    },
  },
  "& .MuiCardContent-root": {
    paddingTop: "4px",
    paddingBottom: "4px !important",
    "& .MuiGrid-container": {
      alignItems: "center",
      "& .time": {
        fontWeight: 600,
        fontSize: "1.1rem",
      },
    },
  },
});

export const CustomBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    position: "relative",
    transform: "none",
    backgroundColor: "#377dff",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    color: "white",
  },
});
