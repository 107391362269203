/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
// import Image from "next/image";
// import Link from "next/link";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
// import { useRouter } from "next/router";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import Grow from "@mui/material/Grow";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import CustomContainer from "../../CustomContainer";
import { ROUTES } from "../../../constants/routes";
import {
  ContentLogo,
  ContentMenu,
  CustomAppBar,
  CustomMenuItem,
  CustomSelect,
  CustomToolbar,
} from "./style";
import { useCounterpart } from "../../../context/language.context";
import en from "../../../assets/flags/en.png";
import fr from "../../../assets/flags/fr.png";
import logoFr from "../../../assets/logo/logo-fr.svg";
import logoEn from "../../../assets/logo/logo-en.svg";
// import { updateLanguage } from "../../actions/session.actions";
import AvatarMenu from "../../AvatarMenu";
import { getCookie } from "../../../helpers/cookie";

const NavBar = ({ setMenuOpen, user }) => {
  const { handleSetLocale, counterpart, locale } = useCounterpart();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMd = useMediaQuery("(min-width: 900px)");

  const goToHome = () => {
    history.push(ROUTES.ROOT);
  };

  const handleChangeLang = (event) => {
    const { value } = event.target;
    handleSetLocale(value);
    if (user) {
      //   dispatch(updateLanguage({ language: value }));
    }
  };

  return (
    <CustomAppBar position="sticky">
      <CustomContainer paddingX={1} paddingY={0}>
        <CustomToolbar>
          <Grow in timeout={500}>
            <ContentLogo>
              <Box sx={{ cursor: "pointer" }} onClick={goToHome}>
                <img
                  src={locale === "fr" ? logoFr : logoEn}
                  alt="Jurismediation"
                  width={226}
                  height={42}
                />
              </Box>
            </ContentLogo>
          </Grow>
          {locale && (
            <CustomSelect
              labelId="simple-select-standard-label"
              id="simple-select-standard"
              value={locale}
              onChange={handleChangeLang}
              variant="outlined"
              sx={{ height: "15px", padding: "0px" }}
            >
              <CustomMenuItem value="fr">
                <img src={fr} alt="fr" width={25} />
              </CustomMenuItem>
              <CustomMenuItem value="en">
                <img src={en} alt="en" width={25} />
              </CustomMenuItem>
            </CustomSelect>
          )}
          {!isMd && (
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={setMenuOpen}
              sx={{
                border: "2.5px solid white",
                borderRadius: "4px",
              }}
            >
              <MenuIcon
                className="burger"
                sx={{
                  fontSize: "16px!important",
                }}
              />
            </IconButton>
          )}
          {isMd && user && <AvatarMenu user={user} />}
          {isMd && !user && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                history.replace(ROUTES.LOGIN);
              }}
            >
              {counterpart("menus.to-login")}
            </Button>
          )}
        </CustomToolbar>
      </CustomContainer>
    </CustomAppBar>
  );
};

NavBar.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
