import { combineReducers } from "redux";
import { mediation } from "./mediation.reducer";
import { connection } from "./connection.reducer";
import { mediationChat } from "./mediationChat.reducer";
import { mediationChatMessage } from "./mediationChatMessage.reducer";
import { mediationDates } from "./mediationDates.reducer";
import { mediationDocuments } from "./mediationDocuments.reducer";
import { mediationInvitations } from "./mediationInvitations.reducer";
import { mediationMeetings } from "./mediationMeetings.reducer";
import { mediationPayments } from "./mediationPayments.reducer";
import { mediationReports } from "./mediationReports.reducer";
import { mediationTeams } from "./mediationTeams.reducer";
import { mediationTimesheets } from "./mediationTimesheets.reducer";
import { mediationUsers } from "./mediationUsers.reducer";
import { session } from "./session.reducer";
import { snackbar } from "./snackbar.reducer";

const rootReducer = combineReducers({
  connection,
  mediation,
  mediationChat,
  mediationChatMessage,
  mediationDates,
  mediationDocuments,
  mediationInvitations,
  mediationMeetings,
  mediationPayments,
  mediationReports,
  mediationTeams,
  mediationTimesheets,
  mediationUsers,
  session,
  snackbar,
});

export default rootReducer;
