import styled from "styled-components";

export const Content = styled.div`
  padding: 0px 1rem;
  padding-top: 14px;
  overflow-y: auto;
  max-height: calc(100vh - 52px);

  padding-top: ${({ paddingTop }) => (!paddingTop ? "14" : "0")}px;
`;
