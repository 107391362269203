import { MEDIATION_MEETINGS } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationMeetingsService } from "../services/mediationMeetings.service";

export const getMediationMeetings = (parameters) =>
  action(
    () => mediationMeetingsService.getMediationMeetings(parameters),
    MEDIATION_MEETINGS.GET_ALL
  );

export const getMediationMeeting = (parameters) =>
  action(
    () => mediationMeetingsService.getMediationMeeting(parameters),
    MEDIATION_MEETINGS.GET
  );

export const addMediationMeeting = (parameters, history) =>
  action(
    () => mediationMeetingsService.addMediationMeeting(parameters, history),
    MEDIATION_MEETINGS.ADD,
    counterpart("message.add-success")
  );

export const updateMediationMeeting = (parameters) =>
  action(
    () => mediationMeetingsService.updateMediationMeeting(parameters),
    MEDIATION_MEETINGS.UPDATE,
    counterpart("message.edit-success")
  );

export const deleteMediationMeeting = (parameters) =>
  action(
    () => mediationMeetingsService.deleteMediationMeeting(parameters),
    MEDIATION_MEETINGS.DELETE,
    counterpart("message.delete-success")
  );

export const selectDate = (parameters) =>
  action(
    () => mediationMeetingsService.selectDate(parameters),
    MEDIATION_MEETINGS.SELECT_DATE,
    counterpart("message.edit-success")
  );

export const initMediationMeeting = () =>
  action(null, MEDIATION_MEETINGS.INIT, null);
