import { useSelector } from "react-redux";

export default function useMediationUserTerms() {
  const mediationUsers = useSelector((state) => state?.mediationUsers);

  if (!mediationUsers) return { muCountTerms: 0, muCountTotal: 0 };

  const mediationUsersFiltered = mediationUsers.filter(
    (mu) => !mu.deletionDate && !mu.user.deletionDate
  );

  const muCountTerms = mediationUsersFiltered.filter(
    (mu) => mu.cgAnswer
  ).length;

  return { muCountTerms, muCountTotal: mediationUsersFiltered.length };
}
