import { MEDIATION_INVITATIONS } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationInvitationsService } from "../services/mediationInvitations.service";

export const inviteUser = (parameters) =>
  action(
    () => mediationInvitationsService.inviteUser(parameters),
    MEDIATION_INVITATIONS.INVITE
  );

export const answerInvitation = (parameters) =>
  action(
    () => mediationInvitationsService.answerInvitation(parameters),
    MEDIATION_INVITATIONS.ANSWER,
    counterpart("message.success")
  );

export const getMediationInvitations = (parameters) =>
  action(
    () => mediationInvitationsService.getMediationInvitations(parameters),
    MEDIATION_INVITATIONS.GET_ALL
  );

export const removeInvitationUser = (parameters) =>
  action(
    () => mediationInvitationsService.removeInvitationUser(parameters),
    MEDIATION_INVITATIONS.REMOVE,
    counterpart("message.delete-success")
  );
