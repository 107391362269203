import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  CustomIconButton,
  CustomLinkChildren,
  MenuCount,
  MenuItem,
  MenuItems,
  TitleMenu,
} from "./style";
import { useCounterpart } from "../../context/language.context";

const MediationMenuItem = ({
  item,
  toggleDrawer,
  disabled,
  isCurrentUserMediationAdmin,
}) => {
  const history = useHistory();
  const {
    label,
    url,
    addIcon,
    childrens,
    description,
    hasChildren: itemHasChildren,
    isForAllUsers,
  } = item;
  const [visible, setVisible] = useState(true);
  const hasChildren = childrens && childrens.length > 0;
  const { counterpart } = useCounterpart();

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleGoToUrl = () => {
    // if (toggleDrawer) {
    //   toggleDrawer();
    // }
    history.push(url);
  };

  const handleIsActive = (value) => history.location.pathname.includes(value);
  return (
    <MenuItem>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TitleMenu>
          {hasChildren && (
            <CustomIconButton onClick={handleClick}>
              {visible ? <ExpandLess /> : <ExpandMore />}
            </CustomIconButton>
          )}
          <Typography
            component={Link}
            to={itemHasChildren ? "#" : url}
            onClick={(hasChildren && handleClick) || toggleDrawer}
            sx={{ cursor: itemHasChildren && !hasChildren && "default" }}
            title={label}
          >
            {label}
          </Typography>
          {description && <MenuCount as="span">({description})</MenuCount>}
          {!!childrens?.length && (
            <MenuCount as="span">({childrens.length})</MenuCount>
          )}
        </TitleMenu>
        {addIcon && (isCurrentUserMediationAdmin || isForAllUsers) && (
          <CustomIconButton onClick={handleGoToUrl} disabled={disabled}>
            <AddIcon />
          </CustomIconButton>
        )}
      </Box>
      {hasChildren && (
        <Collapse in={visible} timeout="auto" unmountOnExit>
          <MenuItems submenus={1}>
            {childrens.map((children) => (
              <MenuItem
                key={children.id}
                active={handleIsActive(children.id)}
                submenus={1}
              >
                <CustomLinkChildren
                  to={`${url}/${children.id}`}
                  title={
                    children.isGeneral
                      ? counterpart("chat.general")
                      : children.title
                  }
                  submenus={1}
                  unread={children?.isUnread ? 1 : 0}
                  onClick={toggleDrawer}
                >
                  {children.isGeneral
                    ? counterpart("chat.general")
                    : children.title}
                </CustomLinkChildren>
              </MenuItem>
            ))}
          </MenuItems>
        </Collapse>
      )}
    </MenuItem>
  );
};

MediationMenuItem.propTypes = {
  item: PropTypes.shape().isRequired,
  toggleDrawer: PropTypes.func,
  isCurrentUserMediationAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
};

MediationMenuItem.defaultProps = {
  toggleDrawer: null,
  disabled: false,
  isCurrentUserMediationAdmin: false,
};

export default MediationMenuItem;
