import { ENDPOINTS } from "../constants/endpoints";
import { ROUTES, SUBROUTES } from "../constants/routes";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationReportsService {
  async getMediationReports({ mediationId }) {
    const parameters = new URLSearchParams({
      mediationId,
    });
    return axios
      .get(`${ENDPOINTS.MEDIATION_REPORT}?${parameters}`)
      .then(handleResponse);
  }

  async getMediationReport({ id }) {
    return axios
      .get(`${ENDPOINTS.MEDIATION_REPORT}/${id}`)
      .then(handleResponse);
  }

  async addMediationReport({ mediationId, title, description }, history) {
    return axios
      .post(ENDPOINTS.MEDIATION_REPORT, {
        mediationId,
        title,
        description,
      })
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        history.push(
          `${ROUTES.MEDIATION}${mediationId}${SUBROUTES.REPORT}/${responseData.id}`
        );
        return response;
      });
  }

  async updateMediationReport({ mediationId, id, title, description }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_REPORT}/${id}`, {
        mediationId,
        title,
        description,
      })
      .then(handleResponse);
  }

  async deleteMediationReport({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_REPORT}/${id}`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }
}

export const mediationReportsService = new MediationReportsService();
