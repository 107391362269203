import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import ListIcon from "@mui/icons-material/List";
import PropTypes from "prop-types";
import Members from "../../pages/MediationPage/members";
import { Documents } from "../../pages/MediationPage/documents";
import { CustomTabList, CustomTabPanel } from "./style";
import { useCounterpart } from "../../context/language.context";
import MediationMenu from "../MediationMenu";

const ContentMenuTabs = ({ menuItems, toggleDrawer, isSmallScreen }) => {
  const [valueTab, setValueTab] = useState("1");
  const { counterpart } = useCounterpart();
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (menuItems) {
      setValueTab("1");
    } else {
      setValueTab("2");
    }
  }, []);

  return (
    <TabContext value={valueTab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <CustomTabList onChange={handleChange} aria-label="lab API tabs">
          {isSmallScreen && <Tab icon={<ListIcon />} value="1" />}
          <Tab
            icon={<GroupIcon />}
            label={!isSmallScreen && counterpart("mediation.members.title")}
            value="2"
          />
          <Tab
            icon={<ArticleIcon />}
            label={!isSmallScreen && counterpart("mediation.documents.title")}
            value="3"
          />
        </CustomTabList>
      </Box>
      {menuItems && (
        <CustomTabPanel
          value="1"
          sx={{ paddingTop: "2px !important", paddingBottom: "0px !important" }}
        >
          <MediationMenu
            items={menuItems}
            disabled={false}
            toggleDrawer={toggleDrawer}
            isCurrentUserMediationAdmin
          />
        </CustomTabPanel>
      )}
      <CustomTabPanel value="2">
        <Members />
      </CustomTabPanel>
      <CustomTabPanel value="3">
        <Documents />
      </CustomTabPanel>
    </TabContext>
  );
};

ContentMenuTabs.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape()),
  toggleDrawer: PropTypes.func,
  isSmallScreen: PropTypes.bool,
};

ContentMenuTabs.defaultProps = {
  menuItems: null,
  toggleDrawer: null,
  isSmallScreen: false,
};

export default ContentMenuTabs;
