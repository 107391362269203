import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "../../helpers/moment";
import { capitalizeFirstLetter } from "../../helpers/utils";
import CustomAvatarGroup from "../CustomAvatarGroup";
import {
  Content,
  ContentCheckIcon,
  ContentDate,
  CustomCard,
  CustomCardHeader,
  DateDayNumber,
  Title,
} from "./style";

const DateComponent = ({
  monthYear,
  mediationDateFiltered,
  checkDate,
  currentMediationUser,
  mediationUsers,
  mediationMeetingUserIds,
  setDateSelected,
  handleShowMenuDate,
  isCurrentUserMediationAdmin,
}) => {
  const getMediationUsers = (mediationDate) =>
    mediationDate.mediationDateAnswers.map((mda) =>
      mediationUsers.find(
        (mu) => mu.id === mda && mediationMeetingUserIds.includes(mda)
      )
    );

  const handleShowMenu = (event, item) => {
    setDateSelected(item);
    handleShowMenuDate(event);
  };

  const handleCheckDate = (event, mediationDate, isSelected) => {
    if (!event.target.closest("svg")) {
      checkDate(mediationDate, isSelected);
    }
  };

  return (
    <Accordion
      defaultExpanded
      sx={{ boxShadow: "none", margin: "0!important" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ minHeight: "32px!important" }}
      >
        <DateDayNumber>{capitalizeFirstLetter(monthYear)}</DateDayNumber>
      </AccordionSummary>
      <AccordionDetails>
        <Content container spacing={0}>
          {_.sortBy(mediationDateFiltered, (o) => o.date).map(
            (mediationDate) => {
              const date = moment(new Date(mediationDate.date));
              const isSelected = mediationDate.mediationDateAnswers.some(
                (mda) => mda === currentMediationUser.id
              );
              const avatarList = getMediationUsers(mediationDate);
              return (
                <Grid key={mediationDate.id} item sm={12} md={12} width={1}>
                  <CustomCard key={mediationDate.id} selected={isSelected}>
                    <CustomCardHeader
                      action={
                        isCurrentUserMediationAdmin && (
                          <IconButton
                            onClick={(event) =>
                              handleShowMenu(event, mediationDate)
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )
                      }
                      title={
                        <Title
                          onClick={(event) =>
                            handleCheckDate(event, mediationDate, isSelected)
                          }
                        >
                          <ContentCheckIcon selected={isSelected}>
                            <i className="fas fa-check" />
                          </ContentCheckIcon>
                          <ContentDate>
                            {date.format("ddd")} {date.format("DD")} -{" "}
                            {date.format("hh:mm")}
                          </ContentDate>
                          <CardActions>
                            <Box
                              width={1}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <CustomAvatarGroup
                                avatarList={avatarList}
                                max={5}
                              />
                            </Box>
                          </CardActions>
                        </Title>
                      }
                    />
                  </CustomCard>
                </Grid>
              );
            }
          )}
        </Content>
      </AccordionDetails>
    </Accordion>
  );
};

DateComponent.propTypes = {
  monthYear: PropTypes.string.isRequired,
  mediationDateFiltered: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  checkDate: PropTypes.func.isRequired,
  currentMediationUser: PropTypes.shape().isRequired,
  mediationUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mediationMeetingUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDateSelected: PropTypes.func.isRequired,
  handleShowMenuDate: PropTypes.func.isRequired,
  isCurrentUserMediationAdmin: PropTypes.bool,
};

DateComponent.defaultProps = {
  isCurrentUserMediationAdmin: false,
};

export default DateComponent;
