import React from "react";
import PropTypes from "prop-types";
import MediationMenuItem from "../MediationMenuItem";
import { MenuCard, MenuItems } from "./style";

const MediationMenu = ({
  items,
  toggleDrawer,
  isCurrentUserMediationAdmin,
  disabled,
}) => (
  <MenuCard>
    <MenuItems>
      {items.map((mi) => (
        <MediationMenuItem
          item={mi}
          key={mi.id}
          isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
          disabled={disabled}
          toggleDrawer={toggleDrawer}
        />
      ))}
    </MenuItems>
  </MenuCard>
);

MediationMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  toggleDrawer: PropTypes.func,
  isCurrentUserMediationAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
};

MediationMenu.defaultProps = {
  toggleDrawer: null,
  disabled: false,
  isCurrentUserMediationAdmin: false,
};

export default MediationMenu;
