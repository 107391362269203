import styled from "styled-components";

export const Name = styled.p`
  margin-bottom: 0;
  margin-top: 0px;
  font-size: 0.8rem;
  font-weight: 500;
  display: inline-block;
`;

export const Description = styled.small`
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.7rem;
  font-style: italic;
  display: inline-block;
  margin-left: 8px;
`;

export const UserItemContainer = styled.div`
  align-items: center !important;
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  div {
    flex-shrink: 0;
    max-width: 100%;
  }
`;

export const PictureContainer = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: auto;
  margin-right: 15px;
  margin-bottom: auto;
`;

export const NameContainer = styled.div`
  flex: 1 0 0%;
  position: relative;
`;

export const Chat = styled.div`
  margin-top: 5px;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  width: fit-content;
  font-size: 0.8rem;

  table {
    border-collapse: collapse;
  }

  table,
  td {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
`;
