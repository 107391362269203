/* eslint-disable default-param-last */
import { MEDIATION_CHAT_MESSAGE } from "../constants/actions";

const initialState = null;

export function mediationChatMessage(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_CHAT_MESSAGE.GET_ALL: {
      return action.payload;
    }
    case MEDIATION_CHAT_MESSAGE.ADD: {
      return [...state, action.payload];
    }
    case MEDIATION_CHAT_MESSAGE.INIT: {
      return initialState;
    }
    default:
      return state;
  }
}
