import { MEDIATION_REPORTS } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationReportsService } from "../services/mediationReports.service";

export const getMediationReports = (parameters) =>
  action(
    () => mediationReportsService.getMediationReports(parameters),
    MEDIATION_REPORTS.GET_ALL
  );

export const getMediationReport = (parameters) =>
  action(
    () => mediationReportsService.getMediationReport(parameters),
    MEDIATION_REPORTS.GET
  );

export const addMediationReport = (parameters, history) =>
  action(
    () => mediationReportsService.addMediationReport(parameters, history),
    MEDIATION_REPORTS.ADD,
    counterpart("message.add-success")
  );

export const updateMediationReport = (parameters) =>
  action(
    () => mediationReportsService.updateMediationReport(parameters),
    MEDIATION_REPORTS.UPDATE,
    counterpart("message.edit-success")
  );

export const deleteMediationReport = (parameters) =>
  action(
    () => mediationReportsService.deleteMediationReport(parameters),
    MEDIATION_REPORTS.DELETE,
    counterpart("message.delete-success")
  );

export const initMediationReport = () =>
  action(null, MEDIATION_REPORTS.INIT, null);
