/* eslint-disable default-param-last */
import { MEDIATION_MEETINGS } from "../constants/actions";

const initialState = {
  meetings: null,
  meeting: null,
};

export function mediationMeetings(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_MEETINGS.GET_ALL: {
      return {
        ...state,
        meetings: action.payload,
      };
    }
    case MEDIATION_MEETINGS.GET: {
      return {
        ...state,
        meeting: action.payload,
      };
    }
    case MEDIATION_MEETINGS.DELETE: {
      const items = state.meetings.filter((mi) => mi.id !== action.payload.id);
      return {
        ...state,
        meetings: items,
      };
    }
    case MEDIATION_MEETINGS.ADD: {
      return {
        ...state,
        meetings: [...state.meetings, action.payload],
      };
    }
    case MEDIATION_MEETINGS.UPDATE:
    case MEDIATION_MEETINGS.SELECT_DATE: {
      const items = state.meetings;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      items[index] = action.payload;
      return {
        ...state,
        meetings: items,
        meeting: action.payload,
      };
    }
    case MEDIATION_MEETINGS.INIT: {
      return {
        ...state,
        meeting: null,
      };
    }
    default:
      return state;
  }
}
