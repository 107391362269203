import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "../../helpers/moment";
import CustomAvatar from "../CustomAvatar";
import {
  Chat,
  Description,
  Name,
  NameContainer,
  PictureContainer,
  UserItemContainer,
} from "./style";

const ChatMessageItem = (props) => {
  const { chatMessage } = props;
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const mediationUser = mediationUsers.find(
    (mu) => mu.id === chatMessage.mediationUserId
  );
  const { user } = mediationUser;

  return (
    <UserItemContainer>
      <PictureContainer>
        <CustomAvatar user={user} />
      </PictureContainer>
      <NameContainer>
        <Name>
          {user.firstName} {user.lastName}
        </Name>
        <Description>
          {moment(new Date(chatMessage.creationDate)).format("hh:mm")}
        </Description>
        <Chat dangerouslySetInnerHTML={{ __html: chatMessage.message }} />
      </NameContainer>
    </UserItemContainer>
  );
};

ChatMessageItem.propTypes = {
  chatMessage: PropTypes.shape().isRequired,
};

export default ChatMessageItem;
