import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import IconButton from "@mui/material/IconButton";
import { extractOnlyIds, isMobile, sendConnection } from "../../helpers/utils";
import { ChatBarContainer, ChatButton, ContentEditor } from "./style";
import { addMediationChatMessage } from "../../actions/mediationChatMessage.actions";
import { addMediationChatGroup } from "../../actions/mediationChat.actions";
import { getCookie } from "../../helpers/cookie";
import { ROUTES } from "../../constants/routes";
import { editorConfigurationWithoutParagraph } from "../../helpers/ckEditorConfiguration";
import { useSession } from "../../context/session.context";

const ChatBar = ({ disabled, title, listUserSelected }) => {
  const { mediationId, mediationChatGroupId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { chatGroup } = useSelector((state) => state.mediationChat);
  const connection = useSelector((state) => state.connection);
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const { user } = useSession();
  const token = getCookie("token");
  const [value, setValue] = useState("");
  const [showToolbar, setShowToolbar] = useState(false);
  const form = useRef();

  const sendMessage = async (mediationChatGroup) => {
    try {
      if (connection) {
        const infoMediationUser = mediationUsers.find(
          (mu) => mu.user.id === user.id
        );
        await Promise.all([
          dispatch(
            addMediationChatMessage({
              id: mediationChatGroup.id,
              mediationId,
              message: value,
            })
          ),
        ]).then(async (result) => {
          await sendConnection(
            "SendMessage",
            {
              id: result[0].id,
              mediationGroupId: mediationChatGroup.id,
              mediationUserId: infoMediationUser?.id,
              message: value,
              token,
            },
            connection
          );
          await sendConnection(
            "UpdateChatGroup",
            {
              id: mediationChatGroup.id,
              title,
              mediationChatGroupUsers:
                mediationChatGroup.mediationChatGroupUsers,
              mediationId,
              isUnread: true,
            },
            connection
          );
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddChat = async (e) => {
    try {
      e.preventDefault();
      if (!mediationChatGroupId) {
        await Promise.all([
          dispatch(
            addMediationChatGroup({
              mediationId,
              title: "",
              mediationChatUserIds: extractOnlyIds(listUserSelected),
            })
          ),
        ]).then((result) => {
          sendMessage(result[0]);
          sendConnection(
            "AddChatGroup",
            {
              id: result[0].id,
              title,
              mediationId,
            },
            connection
          );
          history.replace(
            `${ROUTES.MEDIATION}${mediationId}/chat/${result[0].id}`
          );
        });
      } else {
        await sendMessage(chatGroup);
      }
    } catch (error) {
      console.log(error);
    }
    setValue("");
  };

  let input;
  useEffect(() => {
    if (input && !isMobile()) {
      input.focus();
    }
  }, [input]);

  return (
    <ChatBarContainer onSubmit={handleAddChat} ref={form}>
      <ContentEditor
        ref={(el) => {
          input = el;
        }}
        showToolbar={showToolbar}
      >
        <CKEditor
          editor={ClassicEditor}
          config={editorConfigurationWithoutParagraph}
          data={value}
          onChange={(event, editor) => {
            setValue(editor.getData());
          }}
        />
        <IconButton
          onClick={() => {
            setShowToolbar(!showToolbar);
          }}
          className="icon-toolbar"
        >
          <FormatColorTextIcon />
        </IconButton>
      </ContentEditor>
      <ChatButton onClick={handleAddChat} disabled={!value || disabled}>
        <i className="fas fa-paper-plane" />
      </ChatButton>
    </ChatBarContainer>
  );
};

ChatBar.propTypes = {
  listUserSelected: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ChatBar.defaultProps = {
  disabled: false,
};

export default ChatBar;
