import styled from "styled-components";
import Box from "@mui/material/Box";

export const ContentLoading = styled(Box)`
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
