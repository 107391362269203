import { MEDIATION_DOCUMENTS } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationDocumentsService } from "../services/mediationDocuments.service";

export const getMediationDocuments = (parameters) =>
  action(
    () => mediationDocumentsService.getMediationDocuments(parameters),
    MEDIATION_DOCUMENTS.GET_ALL
  );

export const folderUp = (parameters) => async (dispatch) => {
  await dispatch({
    type: MEDIATION_DOCUMENTS.UP_FOLDER,
    payload: parameters,
  });
};

export const folderDown = (parameters) => async (dispatch) => {
  await dispatch({
    type: MEDIATION_DOCUMENTS.DOWN_FOLDER,
    payload: parameters,
  });
};

export const addMediationFolder = (parameters) =>
  action(
    () => mediationDocumentsService.addMediationFolder(parameters),
    MEDIATION_DOCUMENTS.ADD_FOLDER,
    counterpart("message.success")
  );

export const addMediationFile = (parameters) =>
  action(
    () => mediationDocumentsService.addMediationFile(parameters),
    MEDIATION_DOCUMENTS.ADD_FILE,
    counterpart("message.success")
  );

export const getMediationFile = (parameters) =>
  action(
    () => mediationDocumentsService.getMediationFile(parameters),
    MEDIATION_DOCUMENTS.GET_FILE,
    counterpart("message.success")
  );

export const updateMediationFolder = (parameters) =>
  action(
    () => mediationDocumentsService.updateMediationFolder(parameters),
    MEDIATION_DOCUMENTS.UPDATE_FOLDER,
    counterpart("message.success")
  );

export const updateMediationFile = (parameters) =>
  action(
    () => mediationDocumentsService.updateMediationFile(parameters),
    MEDIATION_DOCUMENTS.UPDATE_FILE,
    counterpart("message.success")
  );

export const removeMediationFile = (parameters) =>
  action(
    () => mediationDocumentsService.deleteMediationFile(parameters),
    MEDIATION_DOCUMENTS.REMOVE_FILE,
    counterpart("message.success")
  );

export const removeMediationFolder = (parameters) =>
  action(
    () => mediationDocumentsService.deleteMediationFolder(parameters),
    MEDIATION_DOCUMENTS.REMOVE_FOLDER,
    counterpart("message.success")
  );
