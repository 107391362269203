/* eslint-disable no-unused-vars */
import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationChatService {
  async getMediationChatGroup({ id }) {
    return axios.get(`${ENDPOINTS.MEDIATION_CHAT}/${id}`).then(handleResponse);
  }

  async getMediationChatGroups({ mediationId }) {
    const parameters = new URLSearchParams({
      mediationId,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION_CHAT}?${parameters}`)
      .then(handleResponse);
  }

  async addMediationChatGroup({ mediationId, title, mediationChatUserIds }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_CHAT}`, {
        mediationId,
        title,
        mediationChatUserIds,
      })
      .then(handleResponse);
  }

  async updateMediationChatGroup({
    id,
    mediationId,
    title,
    mediationChatUserIds,
  }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_CHAT}/${id}`, {
        mediationId,
        title,
        mediationChatUserIds,
      })
      .then(handleResponse);
  }

  async leaveMediationChatGroup({ id, mediationChatGroupUsers }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_CHAT}/${id}/leave`)
      .then(handleResponse)
      .then(() => ({
        responseData: { id, mediationChatGroupUsers },
        statusText: "OK",
      }));
  }

  async addMediationChatMessage({ id, mediationId, message }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_CHAT}/${id}/message`, {
        mediationId,
        mediationChatGroupId: id,
        message,
      })
      .then(handleResponse);
  }
}

export const mediationChatService = new MediationChatService();
