import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

export const CardContainer = styled(Card)((props) => ({
  width: "100%",
  wordWrap: "break-word",
  backgroundClip: "border-box",
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  minWidth: 0,
  borderRadius: props.borders ? "4px" : "0px",
  border: props.borders ? "1px solid rgb(229,234,242)" : "0px",
  paddingBottom: props.borders ? "1.5rem" : "0px",
  boxShadow: props.borders ? "0 3px 6px 0 rgb(140 152 164 / 25%)" : "none",
}));

export const CardHeader = styled("div")((props) => ({
  boxShadow: props.shadow ? "0 3px 6px 0 rgb(140 152 164 / 25%)" : "none",
  padding: "0.75rem 1.25rem",
  border: "0px",
  "& div": {
    height: "35px",
  },
  "& p": {
    fontSize: "1rem",
    lineHeight: 1.45,
    fontWeight: 500,
  },
}));

export const CustomTextField = styled(TextField)({
  "& label": {
    top: "-9px",
  },
  "& .MuiInputLabel-shrink": {
    top: 0,
  },
});

export const CustomTitle = styled(Box)({
  height: "auto !important",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",

  "& p": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});
