/* eslint-disable default-param-last */
import { MEDIATION_USERS } from "../constants/actions";

const initialState = null;

export function mediationUsers(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_USERS.GET_ALL: {
      return action.payload;
    }
    case MEDIATION_USERS.REMOVE:
    case MEDIATION_USERS.UPDATE: {
      const items = state;
      const index = items.findIndex((mu) => mu.id === action.payload.id);
      items[index] = { ...items[index], ...action.payload };
      return items;
    }
    case MEDIATION_USERS.ACCEPT_CG: {
      const items = state;
      const index = items?.findIndex((mu) => mu.id === action.payload.id);
      items[index] = action.payload;
      return items;
    }
    case MEDIATION_USERS.INIT: {
      return initialState;
    }
    default:
      return state;
  }
}
