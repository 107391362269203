/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import Dialog from "../../../components/Dialog";
import { useCounterpart } from "../../../context/language.context";
import { mediationService } from "../../../services/mediation.service";
import { useSession } from "../../../context/session.context";
import { ROUTES } from "../../../constants/routes";

const validationSchema = yup.object({
  title: yup.string().trim().required("common.title-required"),
});

const MediationDialog = ({ open, handleClose, setMediations }) => {
  const { counterpart } = useCounterpart();
  const { user } = useSession();
  const history = useHistory();

  const handleCreateMediation = (values) => {
    mediationService.createMediation(values).then((res) => {
      history.push(`${ROUTES.ROOT}${res?.responseData?.id}`);
      setMediations({
        ...res.responseData,
        mediationUsers: [
          {
            user,
            deletionDate: null,
          },
        ],
      });
      handleClose();
    });
  };

  const formik = useFormik({
    initialValues: { title: "" },
    validationSchema,
    onSubmit: handleCreateMediation,
  });

  return (
    <Dialog
      title={counterpart("mediation.create")}
      open={open}
      handleClose={handleClose}
      width="md"
      acceptLabel={counterpart("common.create")}
      onAccept={formik.handleSubmit}
    >
      <Grid container spacing={2} mt={1}>
        <Grid item md={12}>
          <TextField
            id="title"
            name="title"
            label={counterpart("common.title")}
            variant="outlined"
            placeholder={counterpart("common.title")}
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={
              formik.touched.title &&
              formik.errors.title &&
              counterpart(formik.errors.title)
            }
            fullWidth
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

MediationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setMediations: PropTypes.func.isRequired,
};
export default MediationDialog;
