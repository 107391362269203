/* eslint-disable default-param-last */
import { MEDIATION_DOCUMENTS } from "../constants/actions";

const initialState = {
  isLoading: true,
  breadcrumb: [],
};

export function mediationDocuments(state = initialState, action) {
  switch (action.type) {
    case MEDIATION_DOCUMENTS.GET_ALL: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        breadcrumb: [],
      };
    }
    case MEDIATION_DOCUMENTS.DOWN_FOLDER: {
      const breadcrumb = [...state.breadcrumb, action.payload.id];
      return {
        ...state,
        breadcrumb,
      };
    }
    case MEDIATION_DOCUMENTS.UP_FOLDER: {
      const index = action.payload.id
        ? state.breadcrumb.indexOf(action.payload.id) + 1
        : 0;
      const breadcrumb = state.breadcrumb.slice(0, index);
      return {
        ...state,
        breadcrumb,
      };
    }
    case MEDIATION_DOCUMENTS.ADD_FOLDER: {
      return {
        ...state,
        folders: [...state.folders, action.payload],
      };
    }
    case MEDIATION_DOCUMENTS.REMOVE_FOLDER: {
      const items = state.folders.filter((mi) => mi.id !== action.payload.id);
      return {
        ...state,
        folders: items,
      };
    }
    case MEDIATION_DOCUMENTS.ADD_FILE: {
      return {
        ...state,
        files: [...state.files, action.payload],
      };
    }
    case MEDIATION_DOCUMENTS.REMOVE_FILE: {
      const items = state.files.filter((mi) => mi.id !== action.payload.id);
      return {
        ...state,
        files: items,
      };
    }
    case MEDIATION_DOCUMENTS.UPDATE_FOLDER: {
      const items = state.folders;
      const index = items.findIndex((mi) => mi.id === action.payload.id);
      items[index] = action.payload;
      return {
        ...state,
        folders: items,
      };
    }
    case MEDIATION_DOCUMENTS.UPDATE_FILE: {
      const items = state.files;
      const index = items.findIndex((mi) => mi.id === action.payload.id);
      items[index] = action.payload;
      return {
        ...state,
        files: items,
      };
    }
    default:
      return state;
  }
}
