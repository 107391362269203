import React from "react";
import Button from "@mui/material/Button";
import DialogMui from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { useCounterpart } from "../../context/language.context";
import Loading from "../Loading";

const Dialog = ({
  title,
  open,
  handleClose,
  children,
  acceptLabel,
  onAccept,
  width,
  isLoading,
  handleCancel,
  disabled,
}) => {
  const { counterpart } = useCounterpart();
  return (
    <DialogMui open={open} onClose={handleClose} fullWidth maxWidth={width}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button
          variant="contained"
          onClick={handleCancel || handleClose}
          sx={{ height: 36 }}
          disabled={isLoading}
        >
          {counterpart(
            onAccept && acceptLabel ? "common.cancel" : "common.close"
          )}
        </Button>
        {onAccept && acceptLabel && (
          <Button
            variant="contained"
            onClick={onAccept}
            sx={{ height: 36 }}
            disabled={disabled}
          >
            {isLoading ? <Loading /> : acceptLabel}
          </Button>
        )}
      </DialogActions>
    </DialogMui>
  );
};

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  acceptLabel: PropTypes.string,
  onAccept: PropTypes.func,
  width: PropTypes.string,
  isLoading: PropTypes.bool,
  handleCancel: PropTypes.func,
  disabled: PropTypes.bool,
};

Dialog.defaultProps = {
  acceptLabel: "",
  onAccept: null,
  width: null,
  isLoading: false,
  handleCancel: null,
  disabled: false,
};

export default Dialog;
