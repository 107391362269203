/* eslint-disable react/no-array-index-key */
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateMediation } from "../../actions/mediation.actions";
import { acceptMediationCg } from "../../actions/mediationUsers.actions";
import CGEdit from "../../components/CGEdit";
import CustomAvatarGroup from "../../components/CustomAvatarGroup";
import CustomCard from "../../components/CustomCard";
import CustomMenu from "../../components/CustomMenu";
import Dialog from "../../components/Dialog";
import { useCounterpart } from "../../context/language.context";
import moment from "../../helpers/moment";
import { isMediationAdmin } from "../../helpers/utils";
import { ConditionGenerale, MediationDescription, useStyles } from "./style";
import { useSession } from "../../context/session.context";

const conditionsGenerales = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in augue porttitor, tincidunt odio eu, vestibulum mauris. Nulla sed libero rhoncus, laoreet lorem vel, ullamcorper eros. Sed at felis lacus. Cras ut augue quis erat convallis congue. Vivamus a euismod ex. Quisque vitae sem pulvinar, laoreet tortor ut, finibus tellus. Duis id sem id turpis scelerisque accumsan. Integer laoreet dapibus diam a tincidunt. Vestibulum eget pretium dolor, ut sollicitudin purus. Curabitur in consectetur ex. Donec tempus ut odio ac rhoncus.",
  "Cras magna felis, viverra pellentesque libero eu, egestas aliquam dolor. Sed sit amet nunc pharetra, faucibus est non, imperdiet neque. Donec lobortis nisi at neque porta vestibulum. Integer tempus pharetra urna, eget auctor mi iaculis quis. Fusce condimentum tortor sed arcu aliquet scelerisque. Nunc aliquet tellus at leo faucibus gravida. Sed fermentum quam nec molestie placerat.",
  "Duis elementum sollicitudin felis, vitae elementum mi maximus ac. Etiam vitae quam maximus orci pellentesque egestas non sit amet dolor. Vestibulum in commodo risus. Fusce condimentum pellentesque enim, nec tincidunt augue fermentum eu. Praesent magna lorem, consectetur at massa vel, gravida commodo orci. Cras ullamcorper facilisis metus, a imperdiet mauris gravida ac. Etiam ut eros orci. Ut volutpat erat quis scelerisque mattis. Sed dui neque, pellentesque ut blandit a, feugiat id odio. Vivamus sollicitudin eleifend nibh sit amet maximus. Vestibulum sodales magna vitae ipsum vestibulum, vel porta mauris convallis. Nam erat enim, ornare eget turpis sed, tempor molestie justo. Etiam gravida laoreet posuere. Phasellus porta in libero ac rutrum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
  "Donec et pulvinar nisi, vel ultricies nibh. Praesent ex arcu, dignissim in diam sed, lobortis vulputate quam. Aliquam neque arcu, finibus accumsan lacus non, rutrum tempus sem. Proin eleifend convallis arcu. Quisque sit amet nunc ante. Ut in mauris ac velit egestas interdum. Vestibulum pharetra sit amet enim lacinia sagittis. In tristique accumsan lectus id blandit. Mauris egestas a massa at pharetra.",
  "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus ut nulla vel urna hendrerit cursus. Cras placerat dapibus ante finibus volutpat. Proin vel lacinia nisi. Nunc sit amet euismod nunc, sit amet mollis neque. Fusce egestas at nisl euismod luctus. Nam non ipsum orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec non facilisis nunc, et tincidunt est. Nulla iaculis maximus accumsan. In quis dolor condimentum, sodales magna at, blandit urna. Suspendisse fermentum non nisl vitae ullamcorper. Vivamus vel nulla vitae neque hendrerit interdum eu a nisi. Proin malesuada diam quis congue posuere.",
  "Ut id augue turpis. Aenean volutpat bibendum est, sed egestas ipsum maximus non. Curabitur placerat eros nec pulvinar pretium. Suspendisse potenti. Nunc eleifend egestas varius. Sed venenatis justo neque, vel ultrices ligula ultricies non. Praesent lobortis cursus justo, semper vehicula libero semper a. Vestibulum augue elit, vestibulum non turpis et, viverra rutrum nibh. Praesent arcu ligula, pulvinar vel mauris ut, elementum varius ex. Nulla rutrum tristique tortor, at tristique leo volutpat eu. Aliquam lobortis pulvinar vulputate. Aliquam lacinia ligula justo, vel varius metus aliquam ac. Pellentesque ullamcorper hendrerit sem, eu laoreet erat rutrum at. Donec nec nibh fringilla, facilisis velit id, molestie enim.",
];

export const CG = () => {
  const { counterpart } = useCounterpart();
  const { mediationId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useSession();
  const { mediation } = useSelector((state) => state.mediation);
  const mediationUsers = useSelector((state) =>
    state.mediationUsers.filter(
      (mu) => !mu.deletionDate && !mu.user.deletionDate
    )
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCGModalOpen, setIsCGModalOpen] = useState(false);
  const isMediationDisabled = Boolean(mediation.endDate);

  const currentMediationUser = useMemo(() => {
    if (user && mediationUsers) {
      return mediationUsers?.find(
        (mu) =>
          !mu.deletionDate && !mu.user.deletionDate && user?.id === mu.user.id
      );
    }
    return null;
  }, [user, mediationUsers]);

  const isCurrentUserMediationAdmin = useMemo(() => {
    if (currentMediationUser) {
      return isMediationAdmin(currentMediationUser);
    }
    return false;
  }, [currentMediationUser]);

  const initialValues = {
    specificTerms:
      mediation && mediation.specificTerms ? mediation.specificTerms : "",
  };

  const renderCGModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {counterpart("mediation.cg.confirm-accept")}
      </Typography>
    </Grid>
  );

  const toggleCGModal = () => {
    setAnchorEl(null);
    setIsCGModalOpen(!isCGModalOpen);
  };

  const waitingUsers = mediationUsers.filter(
    (mu) => !mu.deletionDate && !mu.cgAcceptationDate
  );

  const handleUpdateSpecificTerms = async (values) => {
    await dispatch(
      updateMediation({ mediation: { ...mediation, ...values }, mediationId })
    );
    setShowEdit(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: handleUpdateSpecificTerms,
  });

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleShowEdit = () => {
    setAnchorEl(null);
    setShowEdit(true);
  };

  const handleAcceptCg = async () => {
    if (currentMediationUser?.cgAcceptationDate) return;

    let geoloc = null;
    try {
      geoloc = await axios.get("https://geolocation-db.com/json/");
    } catch {
      geoloc = null;
    }

    await dispatch(
      acceptMediationCg({
        id: currentMediationUser.id,
        ip: geoloc?.IPv4,
      })
    );
    setIsDisabled(true);
    setIsCGModalOpen(false);
  };

  const handleInitValue = () => {
    formik.setFieldValue("specificTerms", mediation.specificTerms);
    setShowEdit(false);
  };

  return (
    <>
      <CustomCard
        title={counterpart("mediation.cg.title")}
        handleOnClickIcon={handleShowMenu}
        showMoreIcon={isCurrentUserMediationAdmin && !showEdit}
        className={classes.header}
        shadow
      >
        <Typography variant="subtitle1" fontWeight={600} gutterBottom>
          {counterpart("mediation.cg.generalTerms")}
        </Typography>
        {conditionsGenerales.map((condition, index) => (
          <ConditionGenerale key={index}>{condition}</ConditionGenerale>
        ))}
        {(showEdit || mediation.specificTerms) && (
          <Typography variant="subtitle1" fontWeight={600} gutterBottom>
            {counterpart("mediation.cg.specificTerms")}
          </Typography>
        )}
        {!showEdit && mediation.specificTerms && (
          <MediationDescription
            dangerouslySetInnerHTML={{ __html: mediation.specificTerms }}
          />
        )}
        {showEdit && (
          <CGEdit
            formik={formik}
            counterpart={counterpart}
            mediation={mediation}
            handleInitValue={handleInitValue}
          />
        )}
        {!isCurrentUserMediationAdmin && (
          <Button
            variant="contained"
            color="primary"
            onClick={toggleCGModal}
            disabled={
              !!currentMediationUser?.cgAcceptationDate ||
              isDisabled ||
              isMediationDisabled
            }
            sx={{ marginBottom: "1rem" }}
          >
            {currentMediationUser?.cgAcceptationDate || isDisabled
              ? counterpart("mediation.cg.cg-accepted", {
                  valueDate: moment(
                    new Date(currentMediationUser?.cgAcceptationDate)
                  ).format("DD/MM/YYYY"),
                })
              : counterpart("mediation.cg.cg-accept")}
          </Button>
        )}
        {isCurrentUserMediationAdmin && (
          <>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              {counterpart("mediation.cg.waiting-acceptations")}
            </Typography>
            {waitingUsers.length ? (
              <CustomAvatarGroup
                avatarList={waitingUsers}
                sx={{ marginBottom: "1rem" }}
              />
            ) : (
              counterpart("mediation.cg.no-waiting-acceptations")
            )}
          </>
        )}
      </CustomCard>
      <CustomMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        width="auto"
      >
        <MenuItem onClick={handleShowEdit} disabled={isMediationDisabled}>
          {counterpart("mediation.edit")}
        </MenuItem>
      </CustomMenu>
      <Dialog
        title={counterpart("mediation.cg.title")}
        open={isCGModalOpen}
        handleClose={toggleCGModal}
        acceptLabel={counterpart("mediation.cg.accept")}
        onAccept={handleAcceptCg}
        width="md"
      >
        {renderCGModal()}
      </Dialog>
    </>
  );
};
