import TabList from "@mui/lab/TabList";
import styled from "styled-components";
import TabPanel from "@mui/lab/TabPanel";

export const CustomTabList = styled(TabList)`
  height: 59px;
  border-left: 1px solid rgb(140 152 164 / 25%);
  box-shadow: 0 4px 4px 0 rgb(140 152 164 / 25%);
  button {
    padding-top: 3px;
    justify-content: normal;
  }
  .MuiTabs-flexContainer {
    justify-content: space-around;
  }

  svg {
    margin-top: 6px;
    margin-bottom: 0px !important;
  }
`;

export const CustomTabPanel = styled(TabPanel)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  border-left: 1px solid rgb(140 152 164 / 25%);
  height: 100%;
`;
