import { useSelector } from "react-redux";
import { useSession } from "../context/session.context";

export default function useCurrentMediationUser() {
  const { user } = useSession();
  const currentMediationUser = useSelector((state) =>
    state.mediationUsers?.find(
      (mu) =>
        !mu.deletionDate && !mu.user.deletionDate && user?.id === mu.user.id
    )
  );

  return currentMediationUser;
}
