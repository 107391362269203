import counterpart from "counterpart";
import { MEDIATION_CHAT } from "../constants/actions";
import { action, simpleAction } from "../helpers/actions";
import { mediationChatService } from "../services/mediationChat.service";

export const getMediationChatGroup = (parameters) =>
  action(
    () => mediationChatService.getMediationChatGroup(parameters),
    MEDIATION_CHAT.GET_GROUP
  );

export const getMediationChatGroups = (parameters) =>
  action(
    () => mediationChatService.getMediationChatGroups(parameters),
    MEDIATION_CHAT.GET_ALL_GROUPS
  );

export const addMediationChatGroup = (parameters) =>
  action(
    () => mediationChatService.addMediationChatGroup(parameters),
    MEDIATION_CHAT.ADD_GROUP,
    counterpart("message.add-success")
  );

export const updateMediationChatGroup = (parameters) =>
  action(
    () => mediationChatService.updateMediationChatGroup(parameters),
    MEDIATION_CHAT.UPDATE_GROUP,
    counterpart("message.edit-success")
  );

export const leaveMediationChatGroup = (parameters) =>
  action(
    () => mediationChatService.leaveMediationChatGroup(parameters),
    MEDIATION_CHAT.LEAVE
  );

export const addValueChatGroup = (value) =>
  simpleAction(value, MEDIATION_CHAT.ADD_GROUP);

export const updateValueChatGroup = (value) =>
  simpleAction(value, MEDIATION_CHAT.UPDATE_GROUP);

export const removeMediationChatGroup = (value) =>
  simpleAction(value, MEDIATION_CHAT.REMOVE_GROUP);

export const initMediationChatGroup = () => action(null, MEDIATION_CHAT.INIT);

export const updateReadChatGroup = (value) =>
  simpleAction(value, MEDIATION_CHAT.UPDATE_READ);
