import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

export const Content = styled(Grid)({
  width: "100%",
});

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentDate = styled.div`
  margin-right: 2rem;
`;

export const CustomCard = styled(Card)(() => ({
  "&.MuiPaper-root": {
    background: "none",
    boxShadow: "none",
    cursor: "pointer",
    height: "60px",
    padding: "0 8px",
    "&.MuiCardHeader-action": {
      margin: "auto",
      height: "60px",
    },
    "&:hover": {
      background: "rgb(240,240,240,0.5)",
    },
  },
}));

export const CustomCardHeader = styled(CardHeader)({
  "& .MuiTypography-root": {
    textTransform: "uppercase",
    fontSize: "0.85rem",
  },
  "& .MuiCardHeader-action": {
    margin: "auto",
  },
  padding: "0px !important",
  height: "60px",
});

export const CustomBadge = styled(IconButton)((props) => ({
  zIndex: 0,
  "& .MuiBadge-badge": {
    backgroundColor: props.selected ? "#2e7d32" : "#6c6c6e",
    color: "white",
  },
}));

export const DateDayNumber = styled(Box)({
  fontSize: "0.9rem",
  fontWeight: 800,
  textTransform: "uppercase",
});

export const CustomGrid = styled(Grid)({
  maxWidth: "22%",
  "@media (max-width: 576px)": {
    maxWidth: "100%",
  },
});

export const ContentCheckIcon = styled("span")((props) => ({
  "& i": {
    marginRight: "1rem",
    fontSize: "2rem",
    color: props.selected ? "#6a9f19" : "#000000",
    opacity: props.selected ? 1 : 0.15,
  },
}));
