import React from "react";
import PropTypes from "prop-types";
import CreateComponent from "../CreateComponent";
import { MediationDescription } from "./style";

const ReportDetail = (props) => {
  const { report, mediationUsers, showEdit } = props;
  const mediationUser = mediationUsers.find(
    (item) => item.id === report.mediationUserId
  );
  return (
    <>
      <CreateComponent
        user={mediationUser && mediationUser.user ? mediationUser.user : null}
        item={report}
      />
      {!showEdit && (
        <MediationDescription
          dangerouslySetInnerHTML={{ __html: report.description }}
        />
      )}
    </>
  );
};

ReportDetail.propTypes = {
  report: PropTypes.shape().isRequired,
  mediationUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showEdit: PropTypes.bool,
};

ReportDetail.defaultProps = {
  showEdit: false,
};

export default ReportDetail;
