/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Chip from "@mui/material/Chip";
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FilterListIcon from "@mui/icons-material/FilterList";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import CustomContainer from "../../components/CustomContainer";
import { mediationService } from "../../services/mediation.service";
import CustomAvatarGroup from "../../components/CustomAvatarGroup";
import { CustomTable } from "./style";
import { useCounterpart } from "../../context/language.context";
import MediationDialog from "./MediationDialog";
import { ROUTES } from "../../constants/routes";
import { ROLES } from "../../helpers/role";
import { useSession } from "../../context/session.context";

const ListMediationPage = () => {
  const [mediations, setMediations] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    name: "",
  });
  const [openDialog, setOpenDialog] = useState();
  const { counterpart } = useCounterpart();
  const history = useHistory();
  const { user } = useSession();

  const nbFilters = useMemo(() => {
    let value = 0;
    Object.keys(filters).forEach((item) => {
      if (filters[item] || filters[item]?.length > 0) {
        value += 1;
      }
    });
    return value;
  }, [filters]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(0);
  };

  const handleChange = (event) => {
    const filterValueClone = {
      ...filters,
      [event.target.name]:
        event.target.name === "isClosed"
          ? event.target.value === 2
            ? null
            : Boolean(event.target.value)
          : event.target.value,
    };
    setFilters({
      ...filterValueClone,
      [event.target.name]: event.target.value,
    });
    setIsLoading(true);
    setTimeout(() => {
      mediationService
        .getMediations({
          name: filterValueClone?.name || "",
          filter: filterValueClone,
          page,
          rowsPerPage,
        })
        .then((res) => {
          setMediations(res?.responseData?.hits);
          setPageCount(res?.responseData?.pageCount);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 800);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSetMediations = (mediationsValue) => {
    setMediations([mediationsValue, ...(mediations || [])]);
  };

  const handleDetail = (item) => {
    history.push(`${ROUTES.ROOT}${item.id}`);
  };

  useEffect(() => {
    mediationService
      .getMediations({ name: "", filtre: null, page, rowsPerPage })
      .then((res) => {
        setMediations(res?.responseData?.hits);
        setPageCount(res?.responseData?.pageCount);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, rowsPerPage]);

  return (
    <CustomContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Typography
            variant="h3"
            component="h1"
            color="text.primary"
            sx={{
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Médiations
          </Typography>
        </Grid>
        {user?.role !== ROLES.EXPERT && (
          <Grid item xs={12} mb={2}>
            <Button
              variant="contained"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              {counterpart("mediation.create")}
            </Button>
          </Grid>
        )}
        <Grid item xs={12} mb={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box display="flex" alignItems="center" gap="5px">
                <Badge badgeContent={nbFilters} color="success">
                  <FilterListIcon />
                </Badge>
                <Typography>Filtres</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Nom"
                    name="name"
                    value={filters.name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="multiple-checkbox-label">Status</InputLabel>
                    <Select
                      labelId="multiple-checkbox-label"
                      id="multiple-checkbox"
                      label="Status"
                      name="isClosed"
                      value={filters?.isClosed}
                      onChange={handleChange}
                    >
                      <MenuItem value={2}>Tous</MenuItem>
                      <MenuItem value={0}>Actif</MenuItem>
                      <MenuItem value={1}>Inactif</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Date début"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleChange}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Date fin"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleChange}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <CustomTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Participants</TableCell>
              <TableCell>Date de démarrage</TableCell>
              <TableCell>Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ position: "relative", minHeight: "100px" }}>
            {isLoading ? (
              <Box
                width={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
              >
                <CircularProgress widt />
              </Box>
            ) : (
              mediations?.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleDetail(item);
                  }}
                >
                  <TableCell>{item.title}</TableCell>
                  <TableCell>
                    <CustomAvatarGroup
                      max={5}
                      avatarList={item.mediationUsers.filter(
                        (mu) => !mu.deletionDate && !mu.user.deletionDate
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {moment(item.creationDate).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell>
                    {item?.endDate ? (
                      <Chip label="Inactif" color="error" />
                    ) : (
                      <Chip label="Actif" color="success" />
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </CustomTable>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={pageCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {openDialog && (
        <MediationDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          setMediations={handleSetMediations}
        />
      )}
    </CustomContainer>
  );
};

export default ListMediationPage;
