import { ENDPOINTS } from "../constants/endpoints";
import { ROUTES, SUBROUTES } from "../constants/routes";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationMeetingsService {
  async getMediationMeetings({ mediationId }) {
    const parameters = new URLSearchParams({
      mediationId,
    });

    return axios
      .get(`${ENDPOINTS.MEDIATION_MEETING}?${parameters}`)
      .then(handleResponse);
  }

  async getMediationMeeting({ id }) {
    return axios
      .get(`${ENDPOINTS.MEDIATION_MEETING}/${id}`)
      .then(handleResponse);
  }

  async addMediationMeeting(
    { mediationId, title, description, address },
    history
  ) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_MEETING}`, {
        mediationId,
        title,
        description,
        address,
      })
      .then(handleResponse)
      .then((response) => {
        const { responseData } = response;
        history.push(
          `${ROUTES.MEDIATION}${mediationId}${SUBROUTES.MEETINGS}/${responseData.id}`
        );
        return response;
      });
  }

  async updateMediationMeeting({
    mediationId,
    id,
    title,
    description,
    address,
    mediationMeetingUserIds,
  }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_MEETING}/${id}`, {
        mediationId,
        title,
        description,
        address,
        mediationUserIds: mediationMeetingUserIds,
      })
      .then(handleResponse);
  }

  async selectDate({ id, mediationDateId }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_MEETING}/${id}/selectDate`, {
        mediationDateId,
      })
      .then(handleResponse);
  }

  async deleteMediationMeeting({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_MEETING}/${id}`)
      .then(handleResponse)
      .then(() => ({ responseData: { id }, statusText: "OK" }));
  }
}

export const mediationMeetingsService = new MediationMeetingsService();
