import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { theme } from "../../helpers/theme";

const tableBorderRadius = "20px";

export const useStyles = makeStyles(() => ({
  customCard: {
    height: "100%",
    "& .content": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  customContent: {
    height: "100%",
    paddingBottom: "0px",
    marginBottom: "0px",
    "@media (max-width: 576px)": {
      paddingBottom: "0px",
    },
  },
  header: {
    "& .card-header": {
      "@media (max-width: 576px)": {
        paddingLeft: "50px",
      },
    },
  },
}));

export const SummaryContainer = styled.div`
  white-space: nowrap;
  background: ${theme.white};
  border: 1px solid ${theme.mainBorderColor};
  border-radius: 6px;
  padding: 25px;
  margin: 0px 0px 20px 0px;
  height: calc(100% - 30px);
  text-align: center;
  height: auto;
`;

export const ButtonContainer = styled.div`
  position: relative;
  float: right;
  margin-bottom: 20px;
  vertical-align: middle;
`;

export const ButtonIcon = styled.span`
  ${({ favorite }) => (favorite ? "color: red" : "")};
`;

export const NoteNumber = styled.span`
  font-weight: bold;
  margin-right: 10px;
  font-size: 20px;
`;

export const NotationsList = styled.span`
  line-height: 20px;
`;

export const MediationContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100vh;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const MediationMain = styled.div`
  width: 100%;
  min-width: 250px;

  @media (max-width: 576px) {
    width: auto;
    margin: 0px;
  }
`;

export const DateTableContainer = styled.div`
  overflow-x: auto;
`;

export const MediationContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  height: 100vh;
`;

export const DateTable = styled.table`
  border-radius: 1em;
  border: 0px;
  border-collapse: collapse;
  width: fit-content;

  td,
  th {
    background: #f0f0f0;
  }
`;

export const DateRow = styled.tr`
  border-bottom: 2px solid white;
`;

export const DateHeader = styled.th`
  ${({ empty }) => (empty ? "background: #FFFFFFFF!important" : "")};
  width: 50px;
  text-align: center;
  font-weight: 600;
  border: 3px solid white;
  padding: 10px 10px;
  ${({ borderLeft }) =>
    borderLeft ? `border-top-left-radius: ${tableBorderRadius};` : ""}
  ${({ borderRight }) =>
    borderRight ? `border-top-right-radius: ${tableBorderRadius};` : ""}
`;

export const DateUser = styled.td`
  text-align: left;
  font-weight: 600;
  width: 250px;
  padding: 0px 20px;
  font-size: 1em;
  border: 3px solid white;
  ${({ isOwner }) => (isOwner ? "background: #e5e5f0!important" : "")};
  ${({ participants }) => (participants ? "color: #6A7685" : "")};
  ${({ participants }) =>
    participants ? `border-top-left-radius: ${tableBorderRadius};` : ""}
  ${({ borderBottom }) =>
    borderBottom ? `border-bottom-left-radius: ${tableBorderRadius};` : ""}
  
  p {
    @media (max-width: 576px) {
      display: none;
    }
  }

  div {
    vertical-align: middle;
  }
`;

export const DateCell = styled.td`
  ${({ value, isOwner }) =>
    isOwner && !value ? "background: #e5e5f0!important" : ""};
  ${({ value }) => (value ? "background: #CDEAA1!important" : "")};
  ${({ value }) => (value ? "color: #6A9F19" : "")};
  width: 50px;
  min-width: 40px;
  height: 50px;
  text-align: center;
  padding: 0px 10px;
  border: 3px solid white;
  font-size: 1.35em;

  ${({ isOwner }) =>
    isOwner &&
    `
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  `}
`;

export const DateCount = styled.td`
  color: #2372e8;
  opacity: ${({ value }) => (value ? "1" : "0.8")};
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 0px 10px;
  font-weight: 600;
  border: 3px solid white;
  font-size: 1.35em;
`;

export const DateMonth = styled.div`
  font-size: 16px;
`;

export const DateDayNumber = styled.div`
  font-size: 26px;
  font-weight: 600;
`;

export const DateDayHour = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export const DateDayLabel = styled.div`
  color: #6a7685;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
`;

export const UserMediationList = styled.div`
  & > div {
    margin-bottom: 20px;
  }
`;

export const UserInvitationList = styled.div`
  & > div {
    margin-bottom: 20px;
  }
`;

export const ConditionGenerale = styled.p`
  margin: 0px 0 15px 0;
`;

export const NoData = styled.div`
  margin-bottom: 15px;
`;

export const Chat = styled.div`
  overflow-y: auto;
`;

export const MediationDescription = styled.div`
  margin-bottom: 20px;
  white-space: pre-line;
  table {
    border-collapse: collapse;
  }

  table,
  td {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
`;

export const FolderContainer = styled.div`
  display: flex;
  column-gap: 6px;
  flex-direction: column;
`;

export const CurrentFolder = styled.div`
  margin-bottom: 10px;
`;

export const DropzoneContent = styled.div`
  background: #f0f0f0;
  border: 1px dashed;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  svg {
    font-size: 3rem;
  }
`;

export const ContentButtonEdit = styled(Box)`
  position: absolute;
  z-index: 2;
  background: white;
  padding-bottom: 10px;
  width: 100%;
  top: 51px;
  padding-top: 10px;
  padding-left: 10px;
`;

export const EditorContent = styled(Grid)({
  "& .ck-editor": {
    width: "100%",
  },
});

export const CustomPagination = styled(Pagination)({
  paddingBottom: "14px",
  paddingTop: "14px",
  "& .MuiPagination-ul": {
    justifyContent: "center",
  },
});

export const CustomBox = styled(Box)({
  "@media (max-width: 576px)": {
    paddingBottom: "0px",
    minHeight: "calc(100vh - 356px)",
  },
});

export const Breadcrumb = styled(Typography)({
  cursor: "pointer",
  ":hover": {
    color: "#2372e8",
  },
});

export const CustomGrid = styled(Grid)({
  "@media (max-width: 576px)": {
    paddingBottom: "0px",
    minHeight: "calc(100vh - 356px)",
  },
});

export const CustomTextField = styled(TextField)({
  "& .Mui-disabled": {
    color: "currentColor !important",
    "-webkitTextFillColor": "currentColor !important",
  },
});

export const CustomSelect = styled(Select)({
  "& .Mui-disabled": {
    color: "black !important",
    "-webkitTextFillColor": "black !important",
  },
});

export const CustomCard = styled(Card)({
  boxShadow: "none!important",
  border: "1px solid rgb(229,234,242)",
  "&:hover": {
    background: "rgb(240,240,240,0.5)",
  },
  "& .MuiCardHeader-content": {
    textAlign: "end",
    "& .MuiCardHeader-title": {
      fontWeight: 600,
      fontSize: "1.1rem",
    },
  },
  "& .MuiCardContent-root": {
    "& .MuiGrid-container": {
      alignItems: "center",
      "& .time": {
        fontWeight: 600,
        fontSize: "1.1rem",
      },
    },
  },
});

export const ContentLoading = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const MenuButton = styled(IconButton)`
  position: absolute !important;
  top: 10px;
  left: 10px;
`;
