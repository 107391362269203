export const SUBROUTES = {
  REPORT: "/reports",
  REPORT_DETAIL: "/reports/:mediationReportId",
  MEMBERS: "/members",
  CHAT: "/chat",
  CHAT_DETAIL: "/chat/:mediationChatGroupId?",
  MEETINGS: "/meetings",
  MEETINGS_DETAIL: "/meetings/:mediationMeetingId?",
  PAYMENTS: "/payments",
  PAYMENTS_DETAIL: "/payments/:mediationPaymentId?",
  DOCUMENTS: "/documents",
  INVITE: "/invite",
  TIMESHEET: "/timesheet",
  TERMOFUSE: "/termsofuse",
  LOGS: "/logs",
};

export const ROUTES = {
  ROOT: "/",
  MEDIATION_ROOT: "/:mediationId",
  MEDIATION_INVITE: `/:mediationId${SUBROUTES.INVITE}`,
  MEDIATION_REPORT: `/:mediationId${SUBROUTES.REPORT}`,
  MEDIATION_REPORT_DETAIL: `/:mediationId${SUBROUTES.REPORT_DETAIL}`,
  MEDIATION_MEMBERS: `/:mediationId${SUBROUTES.MEMBERS}`,
  MEDIATION_CHAT: `/:mediationId${SUBROUTES.CHAT}`,
  MEDIATION_CHAT_DETAIL: `/:mediationId${SUBROUTES.CHAT_DETAIL}`,
  MEDIATION_DOCUMENTS: `/:mediationId${SUBROUTES.DOCUMENTS}`,
  MEDIATION_TIMESHEET: `/:mediationId${SUBROUTES.TIMESHEET}`,
  MEDIATION_MEETINGS: `/:mediationId${SUBROUTES.MEETINGS}`,
  MEDIATION_MEETINGS_DETAIL: `/:mediationId${SUBROUTES.MEETINGS_DETAIL}`,
  MEDIATION_PAYMENTS: `/:mediationId${SUBROUTES.PAYMENTS}`,
  MEDIATION_PAYMENTS_DETAIL: `/:mediationId${SUBROUTES.PAYMENTS_DETAIL}`,
  MEDIATION_TERMSOFUSE: `/:mediationId${SUBROUTES.TERMOFUSE}`,
  MEDIATION_LOGS: `/:mediationId${SUBROUTES.LOGS}`,
  NO_ACCESS_PAGE: "/error/403",
};
