import Box from "@mui/material/Box";
import styled from "styled-components";

export const ContentMessage = styled(Box)`
  text-align: center;
  margin-bottom: 20px;
  p {
    font-size: 0.8rem;
    color: grey;
    font-style: italic;
  }
`;
