/* eslint-disable default-param-last */
import { CONNECTION } from "../constants/actions";

const initialState = null;

export function connection(state = initialState, action) {
  switch (action.type) {
    case CONNECTION.ADD:
      return action.payload;
    default:
      return state;
  }
}
