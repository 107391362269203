import { ENDPOINTS } from "../constants/endpoints";
import axios from "../helpers/axios";
import { handleResponse } from "../helpers/services";

class MediationUsersService {
  async getMediationUsers({ mediationId, token = null }) {
    const parameters = new URLSearchParams({
      mediationId,
    });

    return axios
      .get(
        `${ENDPOINTS.MEDIATION_USER}?${parameters}${
          token ? `&token=${token}` : ""
        }`
      )
      .then(handleResponse);
  }

  async removeMediationUser({ id }) {
    return axios
      .delete(`${ENDPOINTS.MEDIATION_USER}/${id}`)
      .then(handleResponse);
  }

  async acceptMediationCg({ id, ip }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_USER}/${id}/cg`, { ip })
      .then(handleResponse);
  }

  async updateUserMediation({
    id,
    description,
    isMediationAdmin,
    mediationTeamId,
  }) {
    return axios
      .put(`${ENDPOINTS.MEDIATION_USER}/${id}`, {
        description,
        isMediationAdmin,
        mediationTeamId,
      })
      .then(handleResponse);
  }

  async leaveMediation({ mediationId }) {
    return axios
      .post(`${ENDPOINTS.MEDIATION_USER}/leave`, {
        mediationId,
      })
      .then(handleResponse);
  }
}

export const mediationUsersService = new MediationUsersService();
