import { MEDIATION_USERS } from "../constants/actions";
import { action, simpleAction } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationUsersService } from "../services/mediationUsers.service";

export const getMediationUsers = (parameters) =>
  action(
    () => mediationUsersService.getMediationUsers(parameters),
    MEDIATION_USERS.GET_ALL
  );

export const removeMediationUser = (parameters) =>
  action(
    () => mediationUsersService.removeMediationUser(parameters),
    MEDIATION_USERS.REMOVE,
    counterpart("message.success")
  );

export const acceptMediationCg = (parameters) =>
  action(
    () => mediationUsersService.acceptMediationCg(parameters),
    MEDIATION_USERS.ACCEPT_CG
  );

export const updateUserMediation = (parameters) =>
  action(
    () => mediationUsersService.updateUserMediation(parameters),
    MEDIATION_USERS.UPDATE,
    counterpart("message.edit-success")
  );

export const leaveMediation = (parameters) =>
  action(
    () => mediationUsersService.leaveMediation(parameters),
    MEDIATION_USERS.LEAVE,
    counterpart("message.add-success")
  );

export const updateValueUserMediation = (value) =>
  simpleAction(value, MEDIATION_USERS.UPDATE);

export const initMedationUsers = () => action(null, MEDIATION_USERS.INIT);
