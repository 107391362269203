import React, { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import CustomAvatar from "../CustomAvatar";
import { Crown, CustomBox, CustomListItem } from "./style";
import { useCounterpart } from "../../context/language.context";

const AssignMembersItem = (props) => {
  const {
    id,
    title,
    mediationUsersGrouped,
    isUserSelected,
    handleSetUserSelected,
    idCreator,
    isCreatorToSelect,
  } = props;
  const { counterpart } = useCounterpart();
  const [visible, setVisible] = useState(true);

  const handleClick = () => {
    setVisible(!visible);
  };
  return (
    <>
      <Card
        sx={{
          background: "rgb(240,240,240,0.5)",
          padding: "10px",
          paddingLeft: "20px",
          margin: "14px auto 8px auto",
          boxShadow: "none",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <CustomBox display="flex" alignItems="center">
          {visible ? <ExpandLess /> : <ExpandMore />}
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: 600,
            }}
          >
            {`${
              title === "null" || !title
                ? counterpart("mediation.members.invited")
                : title
            } (${mediationUsersGrouped && mediationUsersGrouped[id]?.length})`}
          </Typography>
        </CustomBox>
      </Card>
      <Collapse in={visible} timeout="auto" unmountOnExit>
        <List>
          {mediationUsersGrouped[id] &&
            mediationUsersGrouped[id]
              .filter((mu) => !mu.deletionDate)
              .map((i) => (
                <CustomListItem
                  key={i.id}
                  disablePadding
                  sx={{
                    backgroundColor: isUserSelected(i.id) && "#bfd1f1d6",
                    borderRadius: "5px",
                    marginBottom: 0.5,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  }}
                  onClick={
                    !isCreatorToSelect && idCreator === i.id
                      ? undefined
                      : () => handleSetUserSelected(i.id, i)
                  }
                >
                  <ListItemButton sx={{ padding: "5px 10px" }}>
                    <ListItemAvatar>
                      <CustomAvatar user={i.user} />
                    </ListItemAvatar>
                    <ListItemText>
                      <span>{`${i.user.firstName} ${i.user.lastName}`}</span>
                      {!isCreatorToSelect && idCreator === i.id && (
                        <Crown className="fas fa-crown" />
                      )}
                    </ListItemText>
                  </ListItemButton>
                </CustomListItem>
              ))}
        </List>
      </Collapse>
    </>
  );
};

AssignMembersItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  mediationUsersGrouped: PropTypes.shape().isRequired,
  isUserSelected: PropTypes.func.isRequired,
  handleSetUserSelected: PropTypes.func.isRequired,
  idCreator: PropTypes.string,
  isCreatorToSelect: PropTypes.bool,
};

AssignMembersItem.defaultProps = {
  title: "",
  idCreator: "",
  isCreatorToSelect: true,
};

export default AssignMembersItem;
