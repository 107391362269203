import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { ENABLE_REDUX_LOGGER } from "../config";
import rootReducer from "../reducers";

const loggerMiddleware = createLogger();

const middlewares = [thunkMiddleware];

if (ENABLE_REDUX_LOGGER) {
  middlewares.push(loggerMiddleware);
}

const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

// const enhancers = [middlewareEnhancer];
// const composedEnhancers = compose(...enhancers);

export const store = createStore(pReducer, middlewareEnhancer);
export const persistor = persistStore(store);
