import { MEDIATION } from "../constants/actions";
import { action } from "../helpers/actions";
import counterpart from "../helpers/counterpart";
import { mediationService } from "../services/mediation.service";

export const getMediations = (parameters) =>
  action(() => mediationService.getMediations(parameters), MEDIATION.GET_ALL);

export const getMediation = (parameters) =>
  action(() => mediationService.getMediation(parameters), MEDIATION.GET);

export const updateMediation = (parameters) =>
  action(
    () => mediationService.updateMediation(parameters),
    MEDIATION.UPDATE,
    counterpart("message.edit-success")
  );

export const createMediation = (parameters) =>
  action(
    () => mediationService.createMediation(parameters),
    MEDIATION.CREATE,
    counterpart("message.add-success")
  );

export const closeMediation = (parameters) =>
  action(
    () => mediationService.closeMediation(parameters),
    MEDIATION.CLOSE,
    counterpart("message.add-success")
  );
