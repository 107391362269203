import React from "react";
import Dialog from "../Dialog";

const ListDialog = (props) => {
  const { dialogs } = props;
  return dialogs.map((dialog) => (
    <Dialog
      key={dialog.title}
      title={dialog.title}
      open={dialog.isOpen}
      handleClose={dialog.handleClose}
      acceptLabel={dialog.acceptLabel}
      onAccept={dialog.handleAccept}
      isLoading={dialog.isLoading}
      width={dialog.width || "md"}
    >
      {dialog.children}
    </Dialog>
  ));
};

export default ListDialog;
