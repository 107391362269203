/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import clsx from "clsx";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import Compress from "compress.js";
import {
  addMediationFile,
  addMediationFolder,
  folderDown,
  folderUp,
  getMediationDocuments,
  getMediationFile,
  removeMediationFile,
  removeMediationFolder,
  updateMediationFile,
  updateMediationFolder,
} from "../../actions/mediationDocuments.actions";
import CreateComponent from "../../components/CreateComponent";
import CustomCard from "../../components/CustomCard";
import CustomMenu from "../../components/CustomMenu";
import Dialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import MediationDocumentItem from "../../components/MediationDocumentItem";
import { useCounterpart } from "../../context/language.context";
import { getFileIcon } from "../../helpers/file";
import {
  getExtensionFile,
  getNameFile,
  isMediationAdmin,
} from "../../helpers/utils";
import {
  Breadcrumb,
  CustomSelect,
  CustomTextField,
  DropzoneContent,
  FolderContainer,
  useStyles,
} from "./style";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";
import { useSession } from "../../context/session.context";

const validationSchema = yup.object({
  title: yup.string().trim().required("common.title-required"),
});

const validationSchemaFolder = yup.object({
  name: yup
    .string()
    .trim()
    .required("mediation.documents.name-folder-required"),
});

export const Documents = () => {
  const { counterpart } = useCounterpart();
  const { mediationId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { mediation } = useSelector((state) => state.mediation);
  const { user } = useSession();
  const mediationUsers = useSelector((state) => state.mediationUsers);
  const { isLoading, files, folders, breadcrumb } =
    useSelector((state) => state.mediationDocuments) || {};
  const isDocumentEmpty =
    (!files || files?.length === 0) && (!folders || folders?.length === 0);
  const currentFolderId =
    breadcrumb && breadcrumb.length ? breadcrumb[breadcrumb.length - 1] : null;
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDocument, setAnchorElDocument] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [size, setSize] = useState(null);
  const [documentSelected, setDocumentSelected] = useState(null);
  const [isMyDocument, setIsMyDocument] = useState(true);
  const isMediationDisabled = Boolean(mediation.endDate);
  const currentMediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(currentMediationUser);

  const initialValues = {
    title: "",
    format: "",
    extension: "",
    parentId: "",
  };

  const initialValuesFolder = {
    name: "",
  };

  const initialValuesDocument = {
    title: "",
    parentId: "",
  };

  const onAddFile = async (values) => {
    await dispatch(
      addMediationFile({
        mediationId,
        name: values.title,
        parentId: currentFolderId,
        file: values.format.includes("image")
          ? selectedFile
          : selectedFile.split(",")[1],
        size,
        extension: values.extension,
        format: values.format,
      })
    );
    setIsFileModalOpen(false);
  };

  const onAddFolder = async (values) => {
    await dispatch(
      addMediationFolder({
        mediationId,
        name: values.name,
        parentId: currentFolderId,
      })
    );
    setIsFolderModalOpen(false);
  };

  const updateDocument = async (values) => {
    if (documentSelected.isFile) {
      await dispatch(
        updateMediationFile({
          id: documentSelected.id,
          name: values.title,
          parentId: values.parentId === 0 ? null : values.parentId,
        })
      );
    } else {
      await dispatch(
        updateMediationFolder({
          id: documentSelected.id,
          name: values.title,
          parentId: values.parentId === 0 ? null : values.parentId,
        })
      );
    }
    setIsDocumentModalOpen(false);
  };

  const handleRemoveDocument = async () => {
    if (documentSelected.isFile) {
      await dispatch(removeMediationFile({ id: documentSelected.id }));
    } else {
      await dispatch(removeMediationFolder({ id: documentSelected.id }));
    }
    setIsRemoveModalOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onAddFile,
  });

  const formikFolder = useFormik({
    initialValues: initialValuesFolder,
    validationSchema: validationSchemaFolder,
    onSubmit: onAddFolder,
  });

  const formikDocument = useFormik({
    initialValues: initialValuesDocument,
    validationSchema,
    onSubmit: updateDocument,
  });

  const toggleFolderModal = () => {
    setIsFolderModalOpen(!isFolderModalOpen);
    setAnchorEl(null);
    formikFolder.setFieldValue("name", "");
  };

  const toggleFileModal = () => {
    setIsFileModalOpen(!isFileModalOpen);
    setAnchorEl(null);
    formik.setFieldValue("title", "");
    formik.setFieldValue("extension", "");
    formik.setFieldTouched("title", false);
    formik.setFieldValue("format", "");
    setSelectedFile(null);
  };

  const toggleDocumentModal = () => {
    setIsDocumentModalOpen(!isDocumentModalOpen);
    setAnchorElDocument(null);
    formikDocument.setFieldValue("title", documentSelected.name);
    formikDocument.setFieldValue("parentId", documentSelected.parentId);
    formikDocument.setFieldTouched("title", false);
  };

  const toggleRemoveModal = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
    setAnchorElDocument(null);
  };

  useEffect(async () => {
    dispatch(getMediationDocuments({ user, mediationId }));
  }, []);

  const onDownloadFile = async (id, name, extension, format) => {
    const result = await dispatch(getMediationFile({ id }));
    const link = document.createElement("a");
    link.href = `data:${format};base64,${result}`;
    link.setAttribute("download", `${name}${extension}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleShowMenuDocument = (event) => {
    setAnchorElDocument(event.currentTarget);
  };

  const renderFiles = () =>
    files &&
    files
      .filter((a) => a.parentId === currentFolderId)
      .map((file) => {
        const mediationUser = mediationUsers.find(
          (mu) => mu.id === file.mediationUserId
        );
        return (
          <Grid item width={1} key={file.id}>
            <MediationDocumentItem
              item={file}
              user={user}
              mediationUsers={mediationUsers}
              isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
              onClick={
                () =>
                  onDownloadFile(
                    file.id,
                    file.name,
                    file.extension,
                    file.format
                  ) // TODO declare callback
              }
              icon={
                <i
                  className={`far fa-file${getFileIcon(file)}`}
                  style={{ fontSize: "30px" }}
                />
              }
              mediationUser={mediationUser}
              handleShowMenuDocument={handleShowMenuDocument}
              documentSelected={documentSelected}
              setDocumentSelected={setDocumentSelected}
              isMyDocument={isMyDocument}
              setIsMyDocument={setIsMyDocument}
              dispatch={dispatch}
              isMenuOpen={Boolean(anchorElDocument)}
              isFile
            />
          </Grid>
        );
      });

  const onFolderDown = async (id) => {
    await dispatch(folderDown({ id }));
  };

  const renderFolders = () => {
    const folderFiltered = folders?.filter(
      (a) => a.parentId === currentFolderId
    );

    const filesFiltered = files?.filter((a) => a.parentId === currentFolderId);

    if (!folderFiltered?.length && !filesFiltered?.length && currentFolderId) {
      return (
        <Typography variant="body2" color="gray" width={1} textAlign="center">
          {counterpart("mediation.documents.folder-empty")}
        </Typography>
      );
    }

    return folderFiltered?.map((folder) => (
      <Grid item width={1} key={folder.id}>
        <MediationDocumentItem
          item={folder}
          user={user}
          mediationUsers={mediationUsers}
          onClick={() => onFolderDown(folder.id)}
          icon={<i className="fas fa-folder" />}
          handleShowMenuDocument={handleShowMenuDocument}
          documentSelected={documentSelected}
          setDocumentSelected={setDocumentSelected}
          isMyDocument={isMyDocument}
          setIsMyDocument={setIsMyDocument}
          dispatch={dispatch}
          isMenuOpen={Boolean(anchorElDocument)}
          isCurrentUserMediationAdmin={isCurrentUserMediationAdmin}
        />
      </Grid>
    ));
  };

  const onFolderBreadcrumb = async (id) => {
    await dispatch(folderUp({ id }));
  };

  const onFolderUp = async () => {
    const f = folders.find((a) => a.id === currentFolderId);
    if (f) {
      await dispatch(folderUp({ id: f.parentId }));
    }
  };

  const renderBreadcrumb = () => (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        padding: "10px 0",
        fontSize: "0.85rem",
      }}
    >
      <Breadcrumb
        key="1"
        color="inherit"
        onClick={() => onFolderBreadcrumb(null)}
        sx={{
          fontSize: "0.85rem",
        }}
      >
        {counterpart("mediation.documents.home")}
      </Breadcrumb>
      {breadcrumb &&
        breadcrumb.map((item, key) => {
          const folder = folders?.find((f) => f.id === item);
          return (
            folder && (
              <Breadcrumb
                onClick={() => onFolderBreadcrumb(item)}
                key={item}
                sx={{
                  fontSize: "0.85rem",
                }}
                color={
                  key === breadcrumb.length - 1 ? "text.primary" : "inherit"
                }
              >
                {folder.name}
              </Breadcrumb>
            )
          );
        })}
    </Breadcrumbs>
  );

  const renderFolderModal = () => (
    <Grid mt={1}>
      <TextField
        id="name"
        label={counterpart("mediation.documents.name-folder")}
        type="text"
        variant="outlined"
        placeholder="Nom"
        value={formikFolder.values.name}
        onChange={formikFolder.handleChange}
        error={formikFolder.touched.name && Boolean(formikFolder.touched.name)}
        helperText={
          formikFolder.touched.name &&
          formikFolder.errors.name &&
          counterpart(formikFolder.errors.name)
        }
        fullWidth
      />
    </Grid>
  );
  const renderFileModal = () => (
    <Grid container spacing={2} mt={1}>
      <Grid item md={12}>
        <Dropzone
          minSize={0}
          onDrop={async (acceptedFiles) => {
            if (acceptedFiles[0].type.includes("image")) {
              new Compress()
                .compress(acceptedFiles, {
                  size: 4,
                  quality: 0.95,
                  // maxWidth: 250,
                  // maxHeight: 250,
                  resize: true,
                })
                .then(async (data) => {
                  setSelectedFile(data[0].data);
                  setSize(acceptedFiles[0].size);
                  formik.setFieldValue(
                    "extension",
                    getExtensionFile(acceptedFiles[0].name)
                  );
                  formik.setFieldValue(
                    "title",
                    getNameFile(acceptedFiles[0].name)
                  );
                  formik.setFieldValue("format", acceptedFiles[0].type);
                });
            } else {
              const reader = new FileReader();
              reader.readAsDataURL(acceptedFiles[0]);
              reader.onloadend = () => {
                setSelectedFile(reader.result);
                setSize(acceptedFiles[0].size);
                formik.setFieldValue(
                  "extension",
                  getExtensionFile(acceptedFiles[0].name)
                );
                formik.setFieldValue(
                  "title",
                  getNameFile(acceptedFiles[0].name)
                );
                formik.setFieldValue("format", acceptedFiles[0].type);
              };
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <DropzoneContent {...getRootProps()}>
                <input {...getInputProps()} />
                {selectedFile ? (
                  <i
                    className={`far fa-file${getFileIcon({
                      format: formik.values.format,
                    })}`}
                    style={{ fontSize: "100px" }}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ color: "grey" }}>
                      {counterpart("mediation.documents.drag-and-drop")}
                    </Typography>
                    <CloudUploadIcon fontSize="5rem" />
                  </Box>
                )}
              </DropzoneContent>
            </section>
          )}
        </Dropzone>
      </Grid>
      <Grid item md={12}>
        <TextField
          id="title"
          name="title"
          label={counterpart("common.title")}
          variant="outlined"
          placeholder={counterpart("common.title")}
          value={formik.values.title}
          onChange={formik.handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
  const renderDocumentModal = () => {
    const mediationUser = mediationUsers.find(
      (mu) => mu.id === documentSelected.mediationUserId
    );
    return (
      <Grid container spacing={2} mt={1}>
        <Grid item md={12}>
          <CreateComponent user={mediationUser.user} item={documentSelected} />
        </Grid>
        <Grid item md={12}>
          <CustomTextField
            id="title"
            name="title"
            label={counterpart("common.title")}
            variant="outlined"
            placeholder={counterpart("common.title")}
            value={formikDocument.values.title}
            onChange={formikDocument.handleChange}
            disabled={!isMyDocument}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <InputLabel id="label-folder">
              {counterpart("mediation.documents.folder")}
            </InputLabel>
            <CustomSelect
              labelId="label-folder"
              id="folder"
              name="parentId"
              value={formikDocument.values.parentId || 0}
              label={counterpart("mediation.documents.folder")}
              onChange={formikDocument.handleChange}
              disabled={!isMyDocument}
            >
              <MenuItem value={0}>
                {counterpart("mediation.documents.no-folder")}
              </MenuItem>
              {folders.map((folder) => {
                if (
                  (folder.id !== documentSelected.id &&
                    !documentSelected.isFile) ||
                  documentSelected.isFile
                ) {
                  return (
                    <MenuItem key={folder.id} value={folder.id}>
                      {folder.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </CustomSelect>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const renderRemoveModal = () => (
    <Grid container>
      <Typography variant="subtitle1">
        {documentSelected.isFile
          ? counterpart("mediation.documents.confirm-delete-file")
          : counterpart("mediation.documents.confirm-delete-folder")}
        <strong>{documentSelected.name}</strong> ?
      </Typography>
    </Grid>
  );

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorElDocument(null);
  };

  return (
    <>
      <CustomCard
        title={`${(files?.length || 0) + (folders?.length || 0)} ${counterpart(
          "mediation.documents.title"
        )}`}
        handleOnClickIcon={handleShowMenu}
        showMoreIcon
        paddingTop
        className={clsx(isDocumentEmpty && classes.customCard, classes.header)}
      >
        {isLoading && <Loading />}
        {!isLoading &&
          (!isDocumentEmpty ? (
            <>
              {renderBreadcrumb()}
              <FolderContainer>
                {/* <FolderUp onClick={onFolderUp} isEnabled={!!currentFolderId} /> */}
                <Grid container spacing={0}>
                  {renderFolders()}
                </Grid>
              </FolderContainer>
              <Grid container spacing={0} marginTop={0}>
                {renderFiles()}
              </Grid>
            </>
          ) : (
            <Typography variant="body2" color="gray">
              {counterpart("mediation.documents.empty")}
            </Typography>
          ))}
      </CustomCard>
      {anchorEl && (
        <CustomMenu
          anchorEl={anchorEl}
          handleCloseMenu={handleCloseMenu}
          width="auto"
        >
          <MenuItem onClick={toggleFileModal} disabled={isMediationDisabled}>
            {counterpart("mediation.documents.add-file")}
          </MenuItem>
          <MenuItem onClick={toggleFolderModal} disabled={isMediationDisabled}>
            {counterpart("mediation.documents.add-folder")}
          </MenuItem>
        </CustomMenu>
      )}
      {anchorElDocument && (
        <CustomMenu
          anchorEl={anchorElDocument}
          handleCloseMenu={handleCloseMenu}
          width="auto"
        >
          <MenuItem
            onClick={toggleDocumentModal}
            disabled={isMediationDisabled}
          >
            {isMyDocument ? counterpart("common.edit") : "Informations"}
          </MenuItem>
          {isMyDocument && (
            <MenuItem
              onClick={toggleRemoveModal}
              disabled={isMediationDisabled}
            >
              {counterpart("common.delete")}
            </MenuItem>
          )}
        </CustomMenu>
      )}
      {isFolderModalOpen && (
        <Dialog
          title={counterpart("mediation.documents.add-folder")}
          open={isFolderModalOpen}
          handleClose={toggleFolderModal}
          acceptLabel={counterpart("mediation.documents.add-folder")}
          onAccept={formikFolder.handleSubmit}
          width="md"
        >
          {renderFolderModal()}
        </Dialog>
      )}
      {isFileModalOpen && (
        <Dialog
          title={counterpart("mediation.documents.add-file")}
          open={isFileModalOpen}
          handleClose={toggleFileModal}
          acceptLabel={counterpart("mediation.documents.add-file")}
          onAccept={formik.handleSubmit}
          width="md"
        >
          {renderFileModal()}
        </Dialog>
      )}
      {isDocumentModalOpen && (
        <Dialog
          title={
            !isMyDocument
              ? "Informations"
              : documentSelected.isFile
              ? counterpart("mediation.documents.edit-file")
              : counterpart("mediation.documents.edit-folder")
          }
          open={isDocumentModalOpen}
          handleClose={toggleDocumentModal}
          acceptLabel={isMyDocument && counterpart("common.save")}
          onAccept={isMyDocument && formikDocument.handleSubmit}
          width="md"
        >
          {renderDocumentModal()}
        </Dialog>
      )}
      {isRemoveModalOpen && (
        <Dialog
          title={
            documentSelected.isFile
              ? counterpart("mediation.documents.delete-file")
              : counterpart("mediation.documents.delete-folder")
          }
          open={isRemoveModalOpen}
          handleClose={toggleRemoveModal}
          acceptLabel={counterpart("common.delete")}
          onAccept={handleRemoveDocument}
          width="md"
        >
          {renderRemoveModal()}
        </Dialog>
      )}
    </>
  );
};
