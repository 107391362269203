import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import clsx from "clsx";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import {
  getMediationChatGroup,
  updateMediationChatGroup,
  initMediationChatGroup,
  updateReadChatGroup,
  leaveMediationChatGroup,
} from "../../actions/mediationChat.actions";
import ChatBar from "../../components/ChatBar";
import CustomCard from "../../components/CustomCard";
import { useCounterpart } from "../../context/language.context";
import { ContentButtonEdit, ContentLoading, useStyles } from "./style";
import AssignMembersChat from "../../components/AssignMembersChat";
import {
  sendConnection,
  extractChatUserIds,
  extractUsersInfo,
  getDefaultTitleChat,
  isMediationAdmin,
} from "../../helpers/utils";
import {
  addValueMediationChatMessage,
  getAllChatMessages,
  initMediationChatMessage,
} from "../../actions/mediationChatMessage.actions";
import ListChatMessages from "../../components/ListChatMessages";
import LoadingComponent from "../../components/LoadingComponent";
import { getCookie } from "../../helpers/cookie";
import ConfirmDialog from "../../components/ConfirmDialog";
import useCurrentMediationUser from "../../hooks/useCurrentMediationUser";

const validationSchema = yup.object({
  title: yup.string().required("common.title-required"),
});

export const ChatGroup = () => {
  const classes = useStyles();
  const { counterpart } = useCounterpart();
  const { mediationId, mediationChatGroupId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { chatGroup } = useSelector((state) => state.mediationChat);
  const { mediation } = useSelector((state) => state.mediation);
  const mediationUsers = useSelector((state) =>
    state.mediationUsers.filter(
      (mu) => !mu.deletionDate && !mu.user.deletionDate
    )
  );
  const prevMediationChatGroupId = useRef();
  const [title, setTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listUserSelected, setListUserSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isChatEmpty, setIsChatEmpty] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const connection = useSelector((state) => state.connection);
  const token = getCookie("token");
  const isMediationDisabled = Boolean(mediation.endDate);
  const mediationUser = useCurrentMediationUser();
  const isCurrentUserMediationAdmin = isMediationAdmin(mediationUser);

  const initialValues = {
    title: "",
  };

  const handleUpdateChat = async (values) => {
    try {
      await dispatch(
        updateMediationChatGroup({
          ...values,
          id: chatGroup.id,
          mediationChatUserIds: extractChatUserIds(
            chatGroup.mediationChatGroupUsers
          ),
          mediationId,
        })
      );
      sendConnection(
        "UpdateChatGroup",
        {
          id: chatGroup.id,
          title: values.title,
          mediationChatGroupUsers: chatGroup.mediationChatGroupUsers,
          mediationId,
        },
        connection
      );
      setShowEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleUpdateChat,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleConfirmModal = () => {
    setShowConfirm(!showConfirm);
    setAnchorEl(null);
  };

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setAnchorEl(null);
  };

  const handleShowEdit = () => {
    setShowEdit(true);
    setAnchorEl(null);
  };

  const handleLeaveChatGroup = async () => {
    try {
      const chatGroupClone = { ...chatGroup };
      const index = chatGroup.mediationChatGroupUsers.findIndex(
        (item) =>
          item.mediationUserId === mediationUser.id && !item.deletionDate
      );
      chatGroupClone.mediationChatGroupUsers[index].deletionDate = new Date();
      await dispatch(
        leaveMediationChatGroup({
          id: chatGroup.id,
          mediationChatGroupUsers: chatGroupClone.mediationChatGroupUsers,
        })
      );
      await sendConnection(
        "UpdateChatGroup",
        {
          id: chatGroup.id,
          title: chatGroup.title,
          mediationChatGroupUsers: chatGroupClone.mediationChatGroupUsers,
          mediationId,
        },
        connection
      );
      await dispatch(
        addValueMediationChatMessage({
          id: chatGroup.id,
          creationDate: new Date(),
          deletionDate: new Date(),
          mediationUserId: mediationUser.id,
          type: "notification-leave",
        })
      );
      await sendConnection(
        "SendMessage",
        {
          id: mediationUser.id,
          mediationGroupId: chatGroup.id,
          mediationUserId: mediationUser.id,
          type: "notification-leave",
          token,
        },
        connection
      );
      setShowConfirm(false);
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    try {
      if (prevMediationChatGroupId?.current) {
        await sendConnection(
          "LeaveChatGroup",
          prevMediationChatGroupId.current,
          connection
        );
      }
      if (mediationChatGroupId) {
        const mediationChatGroup = await dispatch(
          getMediationChatGroup({ id: mediationChatGroupId })
        );
        const allMessages = await dispatch(
          getAllChatMessages({
            id: mediationChatGroupId,
            mediationChatGroupUsers:
              mediationChatGroup?.mediationChatGroupUsers,
          })
        );
        await sendConnection("JoinChatGroup", mediationChatGroupId, connection);
        prevMediationChatGroupId.current = mediationChatGroupId;
        setIsChatEmpty(Boolean(!allMessages || allMessages?.length === 0));
        setShowEdit(false);
        setIsLoading(false);
      } else {
        await dispatch(initMediationChatMessage());
        await dispatch(initMediationChatGroup());
        setTitle(counterpart("mediation.comments.title"));
        setListUserSelected([]);
        setIsModalOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [mediationChatGroupId]);

  useEffect(async () => {
    if (chatGroup) {
      await dispatch(updateReadChatGroup({ id: chatGroup.id }));
      formik.setFieldValue("title", chatGroup.title);
      setListUserSelected(
        extractUsersInfo(
          chatGroup?.mediationChatGroupUsers || [],
          mediationUsers
        )
      );
      setTitle(
        chatGroup?.title ||
          getDefaultTitleChat(chatGroup.mediationChatGroupUsers, mediationUsers)
      );
    }
  }, [chatGroup]);

  return (
    <>
      {isLoading ? (
        <ContentLoading>
          <LoadingComponent />
        </ContentLoading>
      ) : (
        <>
          <CustomCard
            title={chatGroup?.isGeneral ? counterpart("chat.general") : title}
            className={clsx(isChatEmpty && classes.customCard, classes.header)}
            users={listUserSelected}
            handleOnClickIcon={handleShowMenu}
            showEdit={showEdit}
            formik={formik}
            counterpart={counterpart}
            showMoreIcon={!chatGroup?.isGeneral}
            shadow
          >
            {showEdit && (
              <ContentButtonEdit>
                <Button variant="contained" onClick={formik.handleSubmit}>
                  {counterpart("common.edit")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setShowEdit(false)}
                  sx={{ ml: 1 }}
                >
                  {counterpart("common.cancel")}
                </Button>
              </ContentButtonEdit>
            )}
            <ListChatMessages />
          </CustomCard>
          {Boolean(listUserSelected?.length) && (
            <ChatBar
              listUserSelected={listUserSelected}
              title={title}
              disabled={isMediationDisabled}
            />
          )}
        </>
      )}
      {Boolean(anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {Boolean(mediationChatGroupId) && (
            <MenuItem onClick={handleShowEdit} disabled={isMediationDisabled}>
              {counterpart("chat.edit-title")}
            </MenuItem>
          )}
          <MenuItem
            onClick={handleOpenModal}
            disabled={isMediationDisabled || !isCurrentUserMediationAdmin}
          >
            {counterpart("chat.add-participants")}
          </MenuItem>
          <MenuItem
            onClick={toggleConfirmModal}
            disabled={mediationUser.id === chatGroup.mediationUserId}
          >
            {counterpart("chat.leave-group")}
          </MenuItem>
        </Menu>
      )}
      {isModalOpen && (
        <AssignMembersChat
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          counterpart={counterpart}
          usersSelected={listUserSelected}
          setUsersSelected={setListUserSelected}
          setTitle={setTitle}
          history={history}
          isEdit={Boolean(mediationChatGroupId)}
          disabled={isMediationDisabled}
        />
      )}
      {showConfirm && (
        <ConfirmDialog
          title={counterpart("chat.leave-group")}
          confirmText={counterpart("chat.confirm-leave")}
          toggleRemoveModal={toggleConfirmModal}
          isOpen={showConfirm}
          handleRemove={handleLeaveChatGroup}
          acceptLabel={counterpart("chat.leave")}
        />
      )}
    </>
  );
};
