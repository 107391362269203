import { MEDIATION_CHAT_MESSAGE } from "../constants/actions";
import { action, simpleAction } from "../helpers/actions";
import { mediationChatMessageService } from "../services/mediationChatMessage.service";

export const getAllChatMessages = (parameters) =>
  action(
    () => mediationChatMessageService.getAllChatMessages(parameters),
    MEDIATION_CHAT_MESSAGE.GET_ALL
  );

export const addMediationChatMessage = (parameters) =>
  action(
    () => mediationChatMessageService.addMediationChatMessage(parameters),
    MEDIATION_CHAT_MESSAGE.ADD
  );

export const addValueMediationChatMessage = (values) =>
  simpleAction(values, MEDIATION_CHAT_MESSAGE.ADD);

export const initMediationChatMessage = () =>
  action(null, MEDIATION_CHAT_MESSAGE.INIT);
